import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { Carousel, MobileCarousel, CarouselProfileCard } from '../../components';

import css from './SectionUsers.module.css';

const SectionUsers = props => {
  const {
    rootClassName,
    className,
    users,
    usersLoaded,
    fetchUsersInProgress,
    fetchUsersError,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (users.length === 0) {
    return null;
  }

  // Declare when the carousel should be shown
  const showCarousel = fetchUsersInProgress || !fetchUsersError;

  return usersLoaded ? (
    <div className={classes}>
      <div className={css.titleWrapper}>
        <h1 className={css.title}>
          <FormattedMessage id="SectionUsers.title" />
        </h1>
        <p className={css.subTitle}>
          <FormattedMessage id="SectionUsers.subTitle" />
        </p>
      </div>
      <div className={css.desktopSlider}>
        <Carousel show={showCarousel} fullHeightChevron>
          {users.map((u, key) => {
            return <CarouselProfileCard key={key} data={u} />;
          })}
        </Carousel>
      </div>
      <div className={css.mobileSlider}>
        <MobileCarousel>
          {users.map((u, key) => {
            return <CarouselProfileCard key={key} data={u} />;
          })}
        </MobileCarousel>
      </div>
    </div>
  ) : null;
};

SectionUsers.defaultProps = { rootClassName: null, className: null, listings: null };

const { string, array, bool } = PropTypes;

SectionUsers.propTypes = {
  rootClassName: string,
  className: string,

  // Users
  users: array.isRequired,
  usersLoaded: bool.isRequired,
  fetchUsersInProgress: bool.isRequired,
  fetchUsersError: propTypes.error,
};

export default SectionUsers;
