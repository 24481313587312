import React from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, FieldTextInput, IconSearch } from '../../components';

import css from './TopbarPreferenceSearchForm.module.css';

const TopbarPreferenceSearchFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const { rootClassName, className, handleSubmit, formId, intl } = fieldRenderProps;

      const keywordPlaceholder = intl.formatMessage({
        id: 'TopbarPreferenceSearchForm.keywordPlaceholder',
      });

      const classes = classNames(rootClassName || css.root, className);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.inputWithIcon}>
            <IconSearch className={css.desktopIcon} />
            <FieldTextInput
              inputRootClass={css.desktopInput}
              type="text"
              name="keyword"
              id={formId ? `${formId}.keyword` : 'keyword'}
              placeholder={keywordPlaceholder}
            />
          </div>
        </Form>
      );
    }}
  />
);

TopbarPreferenceSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

TopbarPreferenceSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarPreferenceSearchForm = compose(injectIntl)(TopbarPreferenceSearchFormComponent);

TopbarPreferenceSearchForm.displayName = 'TopbarPreferenceSearchForm';

export default TopbarPreferenceSearchForm;
