import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';

import css from './SectionHero.module.css';

const SectionHero = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <NamedLink className={css.heroItem} name="WizardPreferencePage">
          <div className={css.heroItemContent}>
            <h3 className={css.heroItemTitle}>
              <FormattedMessage id="SectionHero.newListingButton" />
            </h3>
            <p className={css.heroItemSubTitle}>
              <FormattedMessage id="SectionHero.newListingButtonText" />
            </p>
          </div>
          <div className={css.heroShadow} />
        </NamedLink>
        <div className={css.rightItems}>
          <NamedLink className={classNames(css.heroItem, css.heroSecondItem)} name="BuySearchPage">
            <div className={css.heroItemContent}>
              <h3 className={css.heroItemTitle}>
                <FormattedMessage id="SectionHero.buyButton" />
              </h3>
              <p className={css.heroItemSubTitle}>
                <FormattedMessage id="SectionHero.buyButtonText" />
              </p>
            </div>
            <div className={css.heroShadow} />
          </NamedLink>
          <NamedLink className={classNames(css.heroItem, css.heroThirdItem)} name="RentSearchPage">
            <div className={css.heroItemContent}>
              <h3 className={css.heroItemTitle}>
                <FormattedMessage id="SectionHero.rentButton" />
              </h3>
              <p className={css.heroItemSubTitle}>
                <FormattedMessage id="SectionHero.rentButtonText" />
              </p>
            </div>
            <div className={css.heroShadow} />
          </NamedLink>
        </div>
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
