import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import routeConfiguration from '../../routeConfiguration';
import { compose } from 'redux';
import { createResourceLocatorString } from '../../util/routes';
import { withRouter } from 'react-router-dom';
import { IconSpinner, IconWishList } from '../../components';

import css from './AddToWishList.module.css';

const AddToWishListComponent = props => {
  const {
    rootClassName,
    className,
    listingId,
    currentUser,
    onAddToWishList,
    currentWishListListingId,
    addToWishListInProgress,
    addToWishListError,
    history,
  } = props;

  // Get wishlist listingIds from
  // currentUser publicData
  const wishListListingIds = currentUser?.attributes?.profile?.publicData?.wishListListingIds;

  // Check if users wishlist listingIds
  // include the current listing id
  const isAddedToWishList = wishListListingIds && wishListListingIds.includes(listingId);

  // Check if adding listing to wishlist
  // is already in progress
  const isAddToWishListInProgress =
    currentWishListListingId === listingId && addToWishListInProgress;

  const hasErrors = addToWishListError;

  if (hasErrors) {
    return null;
  }

  const wishListIconClasses = classNames(css.addToWishListIcon, {
    [css.activeWishListIcon]: isAddedToWishList,
  });
  const classes = classNames(rootClassName || css.root, className);

  const addToWishList = id => {
    // If user is autorized
    // add listingId to wishlist
    if (currentUser) {
      return onAddToWishList(id);
    }

    // If user isn't authorized
    // redirect to SignupPage
    history.push(createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}));
  };

  return (
    <div className={classes}>
      {isAddToWishListInProgress ? (
        <span className={css.addToWishListSpinnerContainer}>
          <IconSpinner className={css.addToWishListSpinnerIcon} />
        </span>
      ) : (
        <span className={css.addToWishListIconContainer} onClick={() => addToWishList(listingId)}>
          <IconWishList className={wishListIconClasses} isActive={isAddedToWishList} />
        </span>
      )}
    </div>
  );
};

const { object } = PropTypes;

AddToWishListComponent.propTypes = {
  // from withRouter
  history: object.isRequired,
};

const AddToWishList = compose(withRouter)(AddToWishListComponent);

export default AddToWishList;
