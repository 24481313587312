import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { withViewport } from '../../util/contextHelpers';
import { parse, stringify } from '../../util/urlHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { propTypes } from '../../util/types';
import {
  Button,
  LimitedAccessBanner,
  Logo,
  Modal,
  ModalMissingInformation,
  NamedLink,
  TopbarDesktop,
  TopbarMobileMenu,
  TopbarNotice,
  TopbarDropdownMenu,
  IconCart,
} from '../../components';
import { TopbarSearchForm, TopbarPreferenceMobileSearchForm } from '../../forms';
import Switch from 'react-switch';

import MenuIcon from './MenuIcon';
import SearchIcon from './SearchIcon';
import WishlistIcon from './WishlistIcon';
import css from './Topbar.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;
const SEARCH_PAGE = 'SearchPage';

// Check if window object is available
const hasWindow = typeof window !== 'undefined' ? true : false;

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const GenericError = props => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

const { bool } = PropTypes;

GenericError.propTypes = {
  show: bool.isRequired,
};

class TopbarComponent extends Component {
  constructor(props) {
    super(props);
    this.handleMobileMenuOpen = this.handleMobileMenuOpen.bind(this);
    this.handleMobileMenuClose = this.handleMobileMenuClose.bind(this);
    this.handleMobileSearchOpen = this.handleMobileSearchOpen.bind(this);
    this.handleMobileSearchClose = this.handleMobileSearchClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeywordSubmit = this.handleKeywordSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleMobileSearchToggle = this.handleMobileSearchToggle.bind(this);

    this.state = {
      prevScrollpos: hasWindow ? window.pageYOffset : null,
      isScrollAtTop: true,
      isRentSearchType: false,
    };
  }

  handleMobileMenuOpen() {
    redirectToURLWithModalState(this.props, 'mobilemenu');
  }

  handleMobileMenuClose() {
    redirectToURLWithoutModalState(this.props, 'mobilemenu');
  }

  handleMobileSearchOpen() {
    redirectToURLWithModalState(this.props, 'mobilesearch');
  }

  handleMobileSearchClose() {
    redirectToURLWithoutModalState(this.props, 'mobilesearch');
  }

  handleSubmit(values) {
    const { currentSearchParams, history } = this.props;
    const { search, selectedPlace } = values.location;
    const { origin, bounds } = selectedPlace;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...currentSearchParams,
      ...originMaybe,
      pub_preference: 'rent',
      address: search,
      bounds,
    };
    history.push(
      createResourceLocatorString('SearchPageWithParams', routeConfiguration(), {}, searchParams)
    );
  }

  handleKeywordSubmit(values) {
    const { currentSearchParams, history } = this.props;
    const { keyword } = values;

    const searchParams = {
      ...currentSearchParams,
      pub_preference: 'sell',
      keywords: keyword,
    };

    history.push(
      createResourceLocatorString('SearchPageWithParams', routeConfiguration(), {}, searchParams)
    );
  }

  handleMobileSearchToggle(checked) {
    const { history, currentSearchParams } = this.props;

    const bounds = currentSearchParams.bounds;
    const boundsMaybe = bounds ? { bounds } : {};

    const searchParams = {
      mobilesearch: 'open',
      pub_preference: checked ? 'rent' : 'sell',
      ...boundsMaybe,
    };

    if (checked) {
      history.push(
        createResourceLocatorString('SearchPageWithParams', routeConfiguration(), {}, searchParams)
      );
    } else {
      history.push(
        createResourceLocatorString('SearchPageWithParams', routeConfiguration(), {}, searchParams)
      );
    }
  }

  handleLogout() {
    const { onLogout, history } = this.props;
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration());

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (config.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  }

  detectScroll() {
    if (window.scrollY === 0) {
      this.setState({
        isScrollAtTop: true,
      });
    } else if (this.state.isScrollAtTop && window.scrollY > 0) {
      this.setState({
        isScrollAtTop: false,
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { isSearchPageRent } = props;

    return isSearchPageRent
      ? {
          isRentSearchType: !!isSearchPageRent,
        }
      : null;
  }

  componentDidMount() {
    document.addEventListener('scroll', () => {
      this.detectScroll();
    });
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.detectScroll());

    // Hack for fixing react warning
    this.setState = (state, callback) => {
      return;
    };
  }

  render() {
    const {
      className,
      rootClassName,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      isAuthenticated,
      authScopes,
      authInProgress,
      currentUser,
      currentUserHasListings,
      currentUserHasOrders,
      currentPage,
      notificationCount,
      viewport,
      intl,
      history,
      location,
      onManageDisableScrolling,
      onResendVerificationEmail,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      showGenericError,
      isSearchPageRent,
      removeNotice,
      currentSearchParams,
      onFetchPopularBrands,
      popularBrands,
      fetchPopularBrandsInProgress,
      fetchPopularBrandsError,
      currentUserCartListingIds,
      currentUserWishListListingIds,
      topbarNoticeVisible,
      onSetTopbarNoticeVisible,
    } = this.props;

    const { mobilemenu, mobilesearch, address, origin, bounds } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    const cartListingsCount = currentUserCartListingIds?.length;
    const notificationDot =
      notificationCount > 0 || cartListingsCount > 0 ? (
        <div className={css.notificationDot} />
      ) : null;

    const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
    const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';
    const isScrollAtTop = this.state.isScrollAtTop;

    const mobileMenu = (
      <TopbarMobileMenu
        isAuthenticated={isAuthenticated}
        currentUserHasListings={currentUserHasListings}
        currentUser={currentUser}
        cartListingsCount={cartListingsCount}
        onLogout={this.handleLogout}
        notificationCount={notificationCount}
        currentPage={currentPage}
        onFetchPopularBrands={onFetchPopularBrands}
        popularBrands={popularBrands}
        fetchPopularBrandsInProgress={fetchPopularBrandsInProgress}
        fetchPopularBrandsError={fetchPopularBrandsError}
      />
    );

    // Only render current search if full place object is available in the URL params
    const locationFieldsPresent = config.sortSearchByDistance
      ? address && origin && bounds
      : address && bounds;
    const initialSearchFormValues = {
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin, bounds },
          }
        : null,
    };

    const classes = classNames(rootClassName || css.root, className);

    return (
      <div className={classes}>
        <LimitedAccessBanner
          isAuthenticated={isAuthenticated}
          authScopes={authScopes}
          currentUser={currentUser}
          onLogout={this.handleLogout}
          currentPage={currentPage}
        />
        {!removeNotice ? (
          <TopbarNotice
            topbarNoticeVisible={topbarNoticeVisible}
            onSetTopbarNoticeVisible={onSetTopbarNoticeVisible}
          />
        ) : null}
        <div className={classNames(mobileRootClassName || css.container, mobileClassName)}>
          <div className={css.leftMobileContent}>
            <Button
              rootClassName={css.menu}
              onClick={this.handleMobileMenuOpen}
              title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
            >
              <MenuIcon className={css.menuIcon} />
              {notificationDot}
            </Button>
            <Button
              rootClassName={css.searchMenu}
              onClick={this.handleMobileSearchOpen}
              title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
            >
              <SearchIcon className={css.searchMenuIcon} />
            </Button>
          </div>
          <NamedLink
            className={css.home}
            name="LandingPage"
            title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
          >
            <Logo format="mobile" />
          </NamedLink>
          <div className={css.rightMobileContent}>
            <NamedLink className={css.wishListLink} name="WishListPage">
              <WishlistIcon className={css.wishListIcon} />
            </NamedLink>
            <NamedLink className={css.cartLink} name="CartPage">
              <IconCart className={css.cartIcon} />
            </NamedLink>
          </div>
        </div>
        <div className={css.desktop}>
          <TopbarDesktop
            className={desktopClassName}
            currentUserHasListings={currentUserHasListings}
            currentUser={currentUser}
            currentPage={currentPage}
            initialSearchFormValues={initialSearchFormValues}
            intl={intl}
            isAuthenticated={isAuthenticated}
            notificationCount={notificationCount}
            onLogout={this.handleLogout}
            onSearchSubmit={this.handleSubmit}
            onKeywordSearchSubmit={this.handleKeywordSubmit}
            isScrollAtTop={isScrollAtTop}
            isSearchPageRent={isSearchPageRent}
            currentSearchParams={currentSearchParams}
            history={history}
            currentUserCartListingIds={currentUserCartListingIds}
            currentUserWishListListingIds={currentUserWishListListingIds}
          />
          <TopbarDropdownMenu
            onFetchPopularBrands={onFetchPopularBrands}
            popularBrands={popularBrands}
            fetchPopularBrandsInProgress={fetchPopularBrandsInProgress}
            fetchPopularBrandsError={fetchPopularBrandsError}
            currentPage={currentPage}
            isScrollAtTop={isScrollAtTop}
            history={history}
          />
        </div>
        <Modal
          id="TopbarMobileMenu"
          isOpen={isMobileMenuOpen}
          onClose={this.handleMobileMenuClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {authInProgress ? null : mobileMenu}
        </Modal>
        <Modal
          id="TopbarMobileSearch"
          containerClassName={css.modalContainer}
          isOpen={isMobileSearchOpen}
          onClose={this.handleMobileSearchClose}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.searchContainer}>
            <div className={css.switchContainer}>
              <div
                className={classNames(css.switchOption, {
                  [css.switchOptionActive]: !this.state.isRentSearchType,
                })}
              >
                <FormattedMessage id="TopbarDesktop.switchBuy" />
              </div>
              <Switch
                className={css.switch}
                onChange={checked => {
                  if (currentPage === SEARCH_PAGE) {
                    this.handleMobileSearchToggle(checked);
                  } else {
                    this.setState({
                      isRentSearchType: checked,
                    });
                  }
                }}
                checked={this.state.isRentSearchType}
                onColor="#000000"
                offColor="#000000"
                onHandleColor="#fff"
                offHandleColor="#fff"
                handleDiameter={25}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                height={20}
                width={40}
              />
              <div
                className={classNames(css.switchOption, {
                  [css.switchOptionActive]: this.state.isRentSearchType,
                })}
              >
                <FormattedMessage id="TopbarDesktop.switchRent" />
              </div>
            </div>
            {this.state.isRentSearchType ? (
              <TopbarSearchForm
                onSubmit={this.handleSubmit}
                initialValues={initialSearchFormValues}
                isMobile
              />
            ) : (
              <TopbarPreferenceMobileSearchForm onSubmit={this.handleKeywordSubmit} />
            )}
          </div>
        </Modal>
        <ModalMissingInformation
          id="MissingInformationReminder"
          containerClassName={css.missingInformationModal}
          currentUser={currentUser}
          currentUserHasListings={currentUserHasListings}
          currentUserHasOrders={currentUserHasOrders}
          location={location}
          onManageDisableScrolling={onManageDisableScrolling}
          onResendVerificationEmail={onResendVerificationEmail}
          sendVerificationEmailInProgress={sendVerificationEmailInProgress}
          sendVerificationEmailError={sendVerificationEmailError}
        />

        <GenericError show={showGenericError} />
      </div>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
};

const { array, func, number, shape, string } = PropTypes;

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const Topbar = compose(withViewport, injectIntl)(TopbarComponent);

Topbar.displayName = 'Topbar';

export default Topbar;
