/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

import { editListingWizardCategories } from './util/listing-categories';
import { defaultSecondaryFilters } from './util/filters/secondary-filters';

// TopbarDropdownMenu images
import buyImage from './assets/topbarDropdownMenuImages/buyImage.jpg';
import rentImageFirst from './assets/topbarDropdownMenuImages/rentImage-1.jpg';
import rentImageSecond from './assets/topbarDropdownMenuImages/rentImage-2.jpg';
import brandsImageFirst from './assets/topbarDropdownMenuImages/brandsImage-1.jpg';
import brandsImageSecond from './assets/topbarDropdownMenuImages/brandsImage-2.jpg';

export const filters = [
  {
    id: 'preference',
    label: 'Preference',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_preference'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          key: 'sell',
          label: 'Buy',
        },
        {
          key: 'rent',
          label: 'Rent',
        },
      ],
    },
  },
  {
    id: 'sell-categories',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    preference: 'sell',
    config: {
      options: [
        {
          key: 'clothes',
          label: 'Clothes',
        },
        {
          key: 'bikes',
          label: 'Bikes',
        },
        {
          key: 'frames',
          label: 'Frames',
        },
        {
          key: 'accessories',
          label: 'Accessories',
        },
        {
          key: 'components',
          label: 'Components',
        },
      ],
    },
  },
  {
    id: 'rent-categories',
    label: 'Category',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    preference: 'rent',
    config: {
      options: [
        {
          key: 'bikes',
          label: 'Bikes',
          type: 'rent',
        },
        {
          key: 'bike-box',
          label: 'Bike box',
          type: 'rent',
        },
      ],
    },
  },
  // Desktop
  {
    id: 'stock',
    label: 'Show sold',
    type: 'StockFilter',
    group: 'primary',
    queryParamNames: ['stock'],
    config: {},
  },
  // Mobile
  {
    id: 'mobile-stock',
    label: 'Show sold',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['stock'],
    config: {
      options: [
        {
          key: '0',
          label: 'Yes',
        },
        {
          key: '1',
          label: 'No',
        },
      ],
    },
  },
  {
    id: 'dates',
    label: 'Dates',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  // NOTE: This are the default filters which we are showing only when
  // no category or sub-category is selected. If you want to add new
  // default filters you should do it directly from ./util/filters/secondary-filters.js
  ...defaultSecondaryFilters,
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

// Listing wizard logic can be changed from it's specific files
// which are ./util/listing-categories and ./util/default-listing-categories.js
export const editListingCategories = editListingWizardCategories;

// NON-FILTER RELEVANT CONFIGS
export const userIdsForLandingPage = [];

export const reviewIdsForLandingPage = ['6471bbce-fba7-48cb-8c77-64b837be9137'];

export const topbarDropdownMenu = [
  {
    key: 'buy',
    label: 'Buy',
    labelMsgId: 'MarketplaceConfig.buyLabel',
    page: 'BuySearchPage',
    config: {
      images: [
        {
          imagePage: 'BuySearchPage',
          imageSrc: buyImage,
        },
      ],
      options: [
        {
          key: 'bike',
          label: 'Bike',
          labelMsgId: 'MarketplaceConfig.bikeLabel',
          options: [
            {
              key: 'road',
              label: 'Road',
              labelMsgId: 'MarketplaceConfig.roadBikeLabel',
              search: '?pub_preference=sell&pub_category=bikes&pub_type=road',
            },
            {
              key: 'gravel',
              label: 'Gravel',
              labelMsgId: 'MarketplaceConfig.gravelBikeLabel',
              search: '?pub_preference=sell&pub_category=bikes&pub_type=gravel',
            },
            {
              key: 'mountain',
              label: 'Mountain',
              labelMsgId: 'MarketplaceConfig.mountainBikeLabel',
              search: '?pub_preference=sell&pub_category=bikes&pub_type=mountain',
            },
            {
              key: 'bmx',
              label: 'BMX',
              labelMsgId: 'MarketplaceConfig.bmxBikeLabel',
              search: '?pub_preference=sell&pub_category=bikes&pub_type=bmx',
            },
            {
              key: 'time-trial',
              label: 'Time trial',
              labelMsgId: 'MarketplaceConfig.timeTrialBikeLabel',
              search: '?pub_preference=sell&pub_category=bikes&pub_type=time-trial',
            },
            {
              key: 'cyclocross',
              label: 'Cyclocross',
              labelMsgId: 'MarketplaceConfig.cyclocrossBikeLabel',
              search: '?pub_preference=sell&pub_category=bikes&pub_type=cyclocross',
            },
            {
              key: 'track',
              label: 'Track',
              labelMsgId: 'MarketplaceConfig.trackBikeLabel',
              search: '?pub_preference=sell&pub_category=bikes&pub_type=track',
            },
            {
              key: 'folding',
              label: 'Folding',
              labelMsgId: 'MarketplaceConfig.foldingBikeLabel',
              search: '?pub_preference=sell&pub_category=bikes&pub_type=folding',
            },
            {
              key: 'other',
              label: 'Other',
              labelMsgId: 'MarketplaceConfig.otherBikeLabel',
              search: '?pub_preference=sell&pub_category=bikes&pub_type=other',
            },
          ],
        },
        {
          key: 'clothes',
          label: 'Clothes',
          labelMsgId: 'MarketplaceConfig.clothesLabel',
          options: [
            {
              key: 'unisex',
              label: 'Unisex',
              labelMsgId: 'MarketplaceConfig.unisexLabel',
              search: '?pub_preference=sell&pub_category=clothes&pub_gender=unisex',
            },
            {
              key: 'woman',
              label: 'Woman',
              labelMsgId: 'MarketplaceConfig.womanLabel',
              search: '?pub_preference=sell&pub_category=clothes&pub_gender=woman',
            },
            {
              key: 'man',
              label: 'Man',
              labelMsgId: 'MarketplaceConfig.manLabel',
              search: '?pub_preference=sell&pub_category=clothes&pub_gender=man',
            },
            {
              key: 'kid',
              label: 'Kid',
              labelMsgId: 'MarketplaceConfig.kidLabel',
              search: '?pub_preference=sell&pub_category=clothes&pub_gender=kid',
            },
          ],
        },
        {
          key: 'others',
          label: 'Others',
          labelMsgId: 'MarketplaceConfig.othersLabel',
          options: [
            {
              key: 'components',
              label: 'Components',
              labelMsgId: 'MarketplaceConfig.componentsLabel',
              search: '?pub_preference=sell&pub_category=components',
            },
            {
              key: 'accessories',
              label: 'Accessories',
              labelMsgId: 'MarketplaceConfig.accessoriesLabel',
              search: '?pub_preference=sell&pub_category=accessories',
            },
            {
              key: 'frames',
              label: 'Frames',
              labelMsgId: 'MarketplaceConfig.framesLabel',
              search: '?pub_preference=sell&pub_category=frames',
            },
          ],
        },
      ],
    },
  },
  {
    key: 'rent',
    label: 'Rent',
    labelMsgId: 'MarketplaceConfig.rentLabel',
    page: 'RentSearchPage',
    config: {
      images: [
        {
          imagePage: 'RentSearchPage',
          imageSrc: rentImageFirst,
        },
        {
          imagePage: 'RentSearchPage',
          imageSrc: rentImageSecond,
        },
      ],
      options: [
        {
          key: 'categories',
          label: 'Categories',
          labelMsgId: 'MarketplaceConfig.categoriesLabel',
          options: [
            {
              key: 'bike',
              label: 'Bike',
              labelMsgId: 'MarketplaceConfig.bikeLabel',
              search: '?pub_preference=rent&pub_category=bikes',
            },
            {
              key: 'bike-box',
              label: 'Bike box',
              labelMsgId: 'MarketplaceConfig.bikeBoxLabel',
              search: '?pub_preference=rent&pub_category=bike-box',
            },
          ],
        },
        {
          key: 'countries',
          label: 'Countries',
          labelMsgId: 'MarketplaceConfig.countriesLabel',
          options: [
            {
              key: 'uk',
              label: 'UK',
              labelMsgId: 'MarketplaceConfig.ukLabel',
              search:
                '?pub_preference=rent&address=United%20Kingdom&bounds=60.9093517989553%2C1.86276379960989%2C49.802416901086%2C-8.74974065661991',
            },
            {
              key: 'fr',
              label: 'France',
              labelMsgId: 'MarketplaceConfig.franceLabel',
              search:
                '?pub_preference=rent&address=France&bounds=51.148409399929%2C9.66015649291518%2C41.3108229%2C-5.2422724999291',
            },
            {
              key: 'en',
              label: 'Spain',
              labelMsgId: 'MarketplaceConfig.spainLabel',
              search:
                '?pub_preference=rent&address=Spain&bounds=43.8623514995921%2C4.42745558602024%2C27.549342501341%2C-18.26058679677',
            },
            {
              key: 'it',
              label: 'Italy',
              labelMsgId: 'MarketplaceConfig.italyLabel',
              search:
                '?pub_preference=rent&address=Italy&bounds=47.0921469954938%2C18.6197533608%2C35.4119242023051%2C6.6266022477703',
            },
          ],
        },
      ],
    },
  },
  {
    key: 'brands',
    label: 'Brands',
    labelMsgId: 'MarketplaceConfig.brandsLabel',
    config: {
      images: [
        {
          imagePage: 'SearchPage',
          imageSearch: '?pub_brand=rapha',
          imageSrc: brandsImageFirst,
        },
        {
          imagePage: 'SearchPage',
          imageSearch: '?pub_brand=bianchi',
          imageSrc: brandsImageSecond,
        },
      ],
      options: [
        {
          key: 'popular-brands',
          label: 'Popular brands',
          labelMsgId: 'MarketplaceConfig.popularBrands',
          // We fetch popular brands from API
          options: [],
        },
        {
          key: 'latest-brands',
          label: 'Selected brands',
          labelMsgId: 'MarketplaceConfig.selectedBrands',
          options: [
            { key: 'castelli', label: 'Castelli', search: '?pub_brand=castelli' },
            { key: 'fizik', label: 'Fizik', search: '?pub_brand=fizik' },
            { key: 'maap', label: 'MAAP', search: '?pub_brand=maap' },
            { key: 'poc', label: 'POC', search: '?pub_brand=poc' },
            { key: 'rapha', label: 'Rapha', search: '?pub_brand=rapha' },
          ],
        },
      ],
    },
  },
  {
    key: 'list-a-product',
    label: 'List a product',
    labelMsgId: 'MarketplaceConfig.listAProductLabel',
    page: 'NewListingPage',
  },
];

export const shippingMethods = [
  {
    key: 'dhl',
    label: 'DHL',
    text:
      'REVELO has partnered with DHL for shipping your item. We understand shipping fees can be higher than with other carriers, however DHL is reliable and the safest way to get your item delivered to your doorsteps! We are working hard with them to reduce shipping fees.',
  },
  {
    key: 'no-shipping',
    label: 'IN-PERSON COLLECTION',
    text: 'Only select that option if you have agreed in-person collection with the seller based on the item location from the listing.',
  },
];

export const cyclingClubs = [
  {
    key: '6am-cycling',
    label: '6AM Cycling',
  },
  {
    key: 'albion-racing',
    label: 'Albion Racing',
  },
  {
    key: 'chaingang-racing-team',
    label: 'CHAINGANG Racing Team',
  },
  {
    key: 'dulwich-paragon-cc',
    label: 'Dulwich Paragon CC',
  },
  {
    key: 'elitecycling-development-team',
    label: 'Elitecycling Development Team',
  },
  {
    key: 'ex-machina',
    label: 'Ex Machina',
  },
  {
    key: 'finsbury-park-cc',
    label: 'Finsbury Park CC',
  },
  {
    key: 'forge-london-cycling-club',
    label: 'Forge London Cycling Club',
  },
  {
    key: 'full-gas-racing-team',
    label: 'Full Gas Racing Team',
  },
  {
    key: 'gftl',
    label: 'GFTL',
  },
  {
    key: 'hub-velo',
    label: 'Hub Velo',
  },
  {
    key: 'islington-cycling-club',
    label: 'Islington Cycling Club',
  },
  {
    key: 'kingston-wheelers-cc',
    label: 'Kingston Wheelers CC',
  },
  {
    key: 'laka-pedal-mafia-rt',
    label: 'LAKA Pedal Mafia RT',
  },
  {
    key: 'ldn-riders',
    label: 'LDN RIDERS',
  },
  {
    key: 'lea-valley-cc',
    label: 'Lea Valley CC',
  },
  {
    key: 'le-col-race-team',
    label: 'Le Col Race Team',
  },
  {
    key: 'london-baroudeurs',
    label: 'London Baroudeurs',
  },
  {
    key: 'london-dynamo',
    label: 'London Dynamo',
  },
  {
    key: 'london-phoenix',
    label: 'London Phoenix',
  },
  {
    key: 'paceline-rt',
    label: 'Paceline RT',
  },
  {
    key: 'pedal-mafia-cycling-club',
    label: 'Pedal Mafia Cycling Club',
  },
  {
    key: 'peckham-cycle-club',
    label: 'Peckham Cycle Club',
  },
  {
    key: 'rapha-cc',
    label: 'Rapha CC',
  },
  {
    key: 'regents-park-rouleurs',
    label: 'Regents Park Rouleurs',
  },
  {
    key: 'richmond-park-rouleurs',
    label: 'Richmond Park Rouleurs',
  },
  {
    key: 'tekkerz-cc',
    label: 'Tekkerz CC',
  },
  {
    key: 'sigma-sports-cannondale-rt',
    label: 'Sigma Sports - Cannondale RT',
  },
  {
    key: 'speed-pursuit-cycling',
    label: 'Speed Pursuit Cycling',
  },
  {
    key: 'tbc-rt',
    label: 'TBC RT',
  },
  {
    key: 'trash-mile',
    label: 'Trash Mile',
  },
  {
    key: 'velociposse',
    label: 'Velociposse',
  },
  {
    key: 'vc-londres',
    label: 'VC Londres',
  },
  {
    key: 'wheelsuckers',
    label: 'Wheelsuckers',
  },
  {
    key: 'x-speed-united',
    label: 'X-Speed United',
  },
];
