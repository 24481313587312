import React, { Component } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  atleastTwoValuesInArray,
  atleastThreeValuesInArray,
  composeValidators,
} from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import { AddImages, Button, Form, ValidationError } from '../../components';
import config from '../../config';

import Tips from './Tips';
import css from './EditListingPhotosForm.module.css';

const ACCEPT_IMAGES = 'image/*';
const RENT_PREFERENCE = 'rent';
const CLOTHES_CATEGORY = 'clothes';

export class EditListingPhotosFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { imageUploadRequested: false };
    this.onImageUploadHandler = this.onImageUploadHandler.bind(this);
    this.submittedImages = [];
  }

  onImageUploadHandler(file) {
    if (file) {
      this.setState({ imageUploadRequested: true });
      this.props
        .onImageUpload({ id: `${file.name}_${Date.now()}`, file })
        .then(() => {
          this.setState({ imageUploadRequested: false });
        })
        .catch(() => {
          this.setState({ imageUploadRequested: false });
        });
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        onImageUploadHandler={this.onImageUploadHandler}
        imageUploadRequested={this.state.imageUploadRequested}
        initialValues={{ images: this.props.images }}
        render={formRenderProps => {
          const {
            form,
            className,
            fetchErrors,
            handleSubmit,
            images,
            imageUploadRequested,
            intl,
            invalid,
            onImageUploadHandler,
            onRemoveImage,
            disabled,
            ready,
            saveActionMsg,
            updated,
            updateInProgress,
            listingPreference,
            listingCategory,
          } = formRenderProps;

          const chooseImageText = (
            <span className={css.chooseImageText}>
              <span className={css.chooseImage}>
                <FormattedMessage id="EditListingPhotosForm.chooseImage" />
              </span>
              <span className={css.imageTypes}>
                <FormattedMessage id="EditListingPhotosForm.imageTypes" />
              </span>
            </span>
          );

          const imageRequiredMessage = intl.formatMessage({
            id: 'EditListingPhotosForm.imageRequired',
          });

          const { publishListingError, showListingsError, updateListingError, uploadImageError } =
            fetchErrors || {};
          const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

          let uploadImageFailed = null;

          if (uploadOverLimit) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
              </p>
            );
          } else if (uploadImageError) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
              </p>
            );
          }

          // Preferences and categories
          const isRentPreference = listingPreference === RENT_PREFERENCE;
          const isClothesCategory = listingCategory === CLOTHES_CATEGORY;

          // NOTE: These error messages are here since Photos panel is the last visible panel
          // before creating a new listing. If that order is changed, these should be changed too.
          // Create and show listing errors are shown above submit button
          const publishListingFailed = publishListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.publishListingFailed" />
            </p>
          ) : null;
          const showListingFailed = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.showListingFailed" />
            </p>
          ) : null;

          const submittedOnce = this.submittedImages.length > 0;
          // imgs can contain added images (with temp ids) and submitted images with uniq ids.
          const arrayOfImgIds = imgs =>
            imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
          const imageIdsFromProps = arrayOfImgIds(images);
          const imageIdsFromPreviousSubmit = arrayOfImgIds(this.submittedImages);
          const imageArrayHasSameImages = isEqual(imageIdsFromProps, imageIdsFromPreviousSubmit);
          const pristineSinceLastSubmit = submittedOnce && imageArrayHasSameImages;

          const submitReady = (updated && pristineSinceLastSubmit) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled =
            invalid || disabled || submitInProgress || imageUploadRequested || ready;

          const classes = classNames(css.root, className);

          // Validate showing extra fields by default
          const removeSecondImageField = images?.length > 1;
          const removeThirdImageField = images?.length > 0;

          const imageDefaultFields = [
            ...Array(
              isRentPreference
                ? config.defaultUploadImageFields.rent
                : config.defaultUploadImageFields.sell
            ).keys(),
          ];
          const showImageTextType = !isRentPreference && isClothesCategory;

          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedImages = images;
                handleSubmit(e);
              }}
            >
              {updateListingError ? (
                <p className={css.error}>
                  <FormattedMessage id="EditListingPhotosForm.updateFailed" />
                </p>
              ) : null}

              <Tips
                className={css.mobileTips}
                isRentPreference={isRentPreference}
                images={images}
              />
              <AddImages
                className={classNames(css.imagesField, {
                  [css.rentImagesField]: isRentPreference,
                })}
                images={images}
                thumbnailClassName={css.thumbnail}
                savedImageAltText={intl.formatMessage({
                  id: 'EditListingPhotosForm.savedImageAltText',
                })}
                onRemoveImage={onRemoveImage}
              >
                {imageDefaultFields.map(field => {
                  const currentFieldIndex = field;

                  if (isRentPreference) {
                    if (currentFieldIndex === 1 && removeThirdImageField) return null;
                  } else {
                    if (currentFieldIndex === 1 && removeSecondImageField) return null;
                    else if (currentFieldIndex === 2 && removeThirdImageField) return null;
                  }

                  return (
                    <Field
                      key={field}
                      id="addImage"
                      name="addImage"
                      accept={ACCEPT_IMAGES}
                      form={null}
                      type="file"
                      disabled={imageUploadRequested}
                    >
                      {fieldprops => {
                        const {
                          accept,
                          input,
                          imageTypeText,
                          disabled: fieldDisabled,
                        } = fieldprops;
                        const { name, type } = input;
                        const onChange = e => {
                          const file = e.target.files[0];
                          form.change(`addImage`, file);
                          form.blur(`addImage`);
                          onImageUploadHandler(file);
                        };
                        const inputProps = { accept, id: name, name, onChange, type };
                        return (
                          <div className={css.addImageWrapper}>
                            <div className={css.aspectRatioWrapper}>
                              {fieldDisabled ? null : (
                                <input {...inputProps} className={css.addImageInput} />
                              )}
                              <label htmlFor={name} className={css.addImage}>
                                {chooseImageText}
                              </label>
                            </div>
                          </div>
                        );
                      }}
                    </Field>
                  );
                })}

                <Field
                  component={props => {
                    const { input, meta } = props;
                    return (
                      <div className={css.imageRequiredWrapper}>
                        <input {...input} />
                        <ValidationError fieldMeta={meta} />
                      </div>
                    );
                  }}
                  name="images"
                  type="hidden"
                  validate={composeValidators(
                    isRentPreference
                      ? atleastTwoValuesInArray(imageRequiredMessage)
                      : atleastThreeValuesInArray(imageRequiredMessage)
                  )}
                />
              </AddImages>
              {uploadImageFailed}

              <Tips
                className={css.desktopTips}
                isRentPreference={isRentPreference}
                images={images}
              />
              {publishListingFailed}
              {showListingFailed}

              <div className={css.submitButtonWrapper}>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  {saveActionMsg}
                </Button>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingPhotosFormComponent.defaultProps = { fetchErrors: null, images: [] };

EditListingPhotosFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  images: array,
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default compose(injectIntl)(EditListingPhotosFormComponent);
