import React from 'react';
import PropTypes from 'prop-types';
import { NamedLink } from '../../components';
import { getListingSubCategoryLabelFromURL } from '../../util/data';
import classNames from 'classnames';
import config from '../../config';

import css from './WizardSelections.module.css';

const WizardSelections = props => {
  const { rootClassName, className, params, publicData, isPublished } = props;
  const classes = classNames(rootClassName || css.root, className);

  // Previous preference and categories selections
  const listingPreference = params?.preference || publicData?.preference;
  const listingCategory = params?.category || publicData?.category;
  const listingSubCategory = params?.subcategory || publicData?.subcategory;

  // Subcategory label
  const listingSubCategoryLabel = getListingSubCategoryLabelFromURL(
    config.custom.editListingCategories.options,
    listingPreference,
    listingCategory,
    listingSubCategory
  );

  return (
    <div className={classes}>
      <ul className={css.selections}>
        {listingPreference ? (
          <li className={css.selection}>
            {!isPublished ? (
              <NamedLink name="WizardPreferencePage">{listingPreference}</NamedLink>
            ) : (
              listingPreference
            )}
          </li>
        ) : null}
        {listingCategory ? (
          <li className={css.selection}>
            {!isPublished ? (
              <NamedLink name="WizardCategoryPage" params={{ preference: listingPreference }}>
                {listingCategory}
              </NamedLink>
            ) : (
              listingCategory
            )}
          </li>
        ) : null}
        {listingSubCategory ? (
          <li className={css.selection}>
            {!isPublished ? (
              <NamedLink
                name="WizardSubCategoryPage"
                params={{ preference: listingPreference, category: listingCategory }}
              >
                {listingSubCategoryLabel}
              </NamedLink>
            ) : (
              listingSubCategoryLabel
            )}
          </li>
        ) : null}
      </ul>
    </div>
  );
};

WizardSelections.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

WizardSelections.propTypes = {
  rootClassName: string,
  className: string,
};

export default WizardSelections;
