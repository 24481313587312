import React from 'react';
import css from './DiscountForm.module.css';

const IconSuccess = props => {
  return (
    <svg
      className={css.icon}
      height="22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      {...props}
    >
      <g
        className={css.successStrokeColor}
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="rotate(.077) scale(1.57143)"
      >
        <path d="M4 8l2.05 1.64a.48.48 0 00.4.1.5.5 0 00.34-.24L10 4" />
        <circle cx={7} cy={7} r={6.5} />
      </g>
    </svg>
  );
};

export default IconSuccess;
