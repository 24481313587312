import { addressValidations as addressValidationsAPI } from '../util/api';
import { storableError } from '../util/errors';

// ================ Action types ================ //

export const ADDRESS_VALIDATIONS_REQUEST = 'app/shipping/ADDRESS_VALIDATIONS_REQUEST';
export const ADDRESS_VALIDATIONS_SUCCESS = 'app/shipping/ADDRESS_VALIDATIONS_SUCCESS';
export const ADDRESS_VALIDATIONS_ERROR = 'app/shipping/ADDRESS_VALIDATIONS_ERROR';

// ================ Reducer ================ //

const initialState = {
  addressValidationInProgress: false,
  addressValidationError: null,
  addressValidated: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case ADDRESS_VALIDATIONS_REQUEST:
      return {
        ...state,
        addressValidationsError: null,
        addressValidationsInProgress: true,
      };
    case ADDRESS_VALIDATIONS_SUCCESS:
      return { ...state, addressValidationsInProgress: false, personAccountOpener: payload };
    case ADDRESS_VALIDATIONS_ERROR:
      console.error(payload);
      return { ...state, addressValidationsError: payload, addressValidationsInProgress: false };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const addressValidationsRequest = () => ({
  type: ADDRESS_VALIDATIONS_REQUEST,
});

export const addressValidationsSuccess = payload => ({
  type: ADDRESS_VALIDATIONS_SUCCESS,
  payload,
});

export const addressValidationsError = payload => ({
  type: ADDRESS_VALIDATIONS_ERROR,
  payload,
  error: true,
});

// ================ Thunks ================ //

export const addressValidations = params => dispatch => {
  dispatch(addressValidationsRequest());

  return addressValidationsAPI(params)
    .then(response => {
      console.log(response);
      dispatch(addressValidationsSuccess(response));
      return response;
    })
    .catch(e => dispatch(addressValidationsError(storableError(e))));
};
