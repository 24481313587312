import React, { useState } from 'react';
import { compose } from 'redux';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { withViewport } from '../../util/contextHelpers';
import ItemsCarousel from 'react-items-carousel';
import { IconChevron } from '../../components';

import css from './Carousel.module.css';

const MAX_MOBILE_SCREEN_WIDTH = 768;
const MAX_CHEVRON_SCREEN_WIDTH = 1280;

// This can be overwritten with
// main component props
const GUTTER_SPACE = 12;
const ACTIVE_POSITION = 'center';
const CHEVRON_WIDTH = 60;
const SLIDES_TO_SCROLL = 1;

const Chevron = props => {
  // Default position is right and in case
  // you need the chevron in left position
  // you can just call opposite from props
  const { opposite, chevronClassName, chevronIconClassName } = props;

  const chevronIconClass = chevronIconClassName || css.chevronIcon;
  const chevronClasses = chevronClassName || css.chevron;
  const classes = classNames(chevronClasses, {
    [css.chevronLeft]: opposite,
  });
  return (
    <span className={classes}>
      <IconChevron className={chevronIconClass} />
    </span>
  );
};

const CarouselComponent = props => {
  const {
    rootClassName,
    className,
    chevronClassName,
    chevronIconClassName,
    children,
    viewport,
    cards,
    cardsOnMobile,
    gutter,
    customChevron,
    fullHeightChevron,
    show,
  } = props;

  // Set and track active item state
  // with following hook
  const [active, setActive] = useState(0);

  // Check if the current viewport is
  // coming from mobile layout
  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  // Declare when the chevron should
  // be set aside
  const isChevronOutside = !customChevron && viewport.width > MAX_CHEVRON_SCREEN_WIDTH;

  // Set number of cards to show in both
  // mobile and desktop layout
  const desktopCards = cards ? cards : 4;
  const mobileCards = cardsOnMobile ? cardsOnMobile : 2;
  const numberOfCards = isMobileLayout ? mobileCards : desktopCards;

  const chevronRightClass = classNames(css.chevronRightContainer, {
    [css.chevronContainer]: !fullHeightChevron,
  });
  const chevronLeftClass = classNames(css.chevronLeftContainer, {
    [css.chevronContainer]: !fullHeightChevron,
  });

  const classes = classNames(rootClassName || css.root, className);

  return show ? (
    <ItemsCarousel
      classes={{
        wrapper: classes,
        rightChevronWrapper: chevronRightClass,
        leftChevronWrapper: chevronLeftClass,
      }}
      gutter={gutter ? gutter : GUTTER_SPACE}
      activePosition={ACTIVE_POSITION}
      chevronWidth={CHEVRON_WIDTH}
      numberOfCards={numberOfCards}
      slidesToScroll={SLIDES_TO_SCROLL}
      activeItemIndex={active}
      infiniteLoop={false}
      disableSwipe={false}
      outsideChevron={isChevronOutside}
      alwaysShowChevrons={false}
      showSlither={false}
      firstAndLastGutter={false}
      requestToChangeActive={value => setActive(value)}
      rightChevron={
        <Chevron chevronClassName={chevronClassName} chevronIconClassName={chevronIconClassName} />
      }
      leftChevron={
        <Chevron
          chevronClassName={chevronClassName}
          chevronIconClassName={chevronIconClassName}
          opposite
        />
      }
    >
      {children}
    </ItemsCarousel>
  ) : null;
};

CarouselComponent.defaultProps = {
  rootClassName: null,
  className: null,

  // Carousel settings
  gutter: null,
};

const { string, shape, number } = PropTypes;

CarouselComponent.propTypes = {
  rootClassName: string,
  className: string,

  // Carousel settings
  gutter: number,
  cards: number,

  // form withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const Carousel = compose(withViewport)(CarouselComponent);

export default Carousel;
