import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-uk',
    predictionPlace: {
      address: 'United Kingdom',
      bounds: new LatLngBounds(new LatLng(60.9093517989553, 1.86276379960989), new LatLng(49.802416901086, -8.74974065661991)),
    },
  },
  {
    id: 'default-france',
    predictionPlace: {
      address: 'France',
      bounds: new LatLngBounds(new LatLng(51.148409399929, 9.66015649291518), new LatLng(41.3108229, -5.24227249992918)),
    },
  },
  {
    id: 'default-spain',
    predictionPlace: {
      address: 'Spain',
      bounds: new LatLngBounds(new LatLng(45.12739937, 1.64912884), new LatLng(34.97828575, -9.69489235)),
    },
  },
  {
    id: 'default-italy',
    predictionPlace: {
      address: 'Italy',
      bounds: new LatLngBounds(new LatLng(47.0921469954938, 18.6197533608), new LatLng(35.4119242023051, 6.6266022477703)),
    },
  },
  {
    id: 'default-switzerland',
    predictionPlace: {
      address: 'Switzerland',
      bounds: new LatLngBounds(new LatLng(47.8084549866365, 10.4920638273824), new LatLng(45.8180770356515, 5.95599426104971)),
    },
  },
];
export default defaultLocations;
