import React from 'react';

const IconLongArrow = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={37}
      height={15}
      viewBox="0 0 36 15"
      {...props}
    >
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h25v14.992H0zm0 0" />
        </clipPath>
      </defs>
      <path
        d="M61.424 12.409h-60"
        transform="scale(.59016 .6)"
        fill="none"
        strokeWidth={2}
        stroke="#fff"
        strokeMiterlimit={10}
      />
      <g clipPath="url(#prefix__a)">
        <path
          d="M13.383.716L1.423 12.41l11.96 11.868"
          transform="scale(.59016 .6)"
          fill="none"
          strokeWidth={2}
          stroke="#fff"
          strokeMiterlimit={10}
        />
      </g>
    </svg>
  );
};

export default IconLongArrow;
