import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconDHL.module.css';

const IconDHL = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="29"
      viewBox="0 0 46.986 29.979"
    >
      <path fill="#ffcb01" d="M0 0h46.986v29.979H0z" />
      <g fill="#d80613">
        <path d="M8.731 11.413 7.276 13.39h7.93c.401 0 .396.151.2.418-.199.27-.532.737-.735 1.012-.103.139-.289.392.327.392h3.243l.961-1.306c.596-.809.052-2.492-2.079-2.492l-8.392-.001z" />
        <path d="m6.687 17.854 2.923-3.972h3.627c.401 0 .396.152.2.418l-.74 1.008c-.103.139-.289.392.327.392h4.858c-.403.554-1.715 2.154-4.067 2.154H6.687zm16.738-2.155-1.585 2.155h-4.181l1.585-2.155zm6.404-.488H19.604l2.796-3.798h4.179l-1.602 2.178h1.865l1.604-2.178h4.179zm-.359.488-1.585 2.155h-4.179l1.585-2.155zm-28.748.85H6.88l-.336.457H.722zm0-.85h6.784l-.337.457H.722zm0 1.7h5.533l-.335.455H.722zm45.543-.393h-6.136l.337-.457h5.799zm0 .848h-6.759l.334-.455h6.425zm-5.174-2.155h5.174v.458h-5.51zm-2.678-4.286-2.796 3.798h-4.429l2.798-3.798zm-7.583 4.286s-.305.418-.454.618c-.524.71-.061 1.536 1.652 1.536h6.712l1.585-2.154H30.83z" />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconDHL.defaultProps = {
  className: null,
  rootClassName: null,
};

IconDHL.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconDHL;
