import React, { Component } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import truncate from 'lodash/truncate';
import { InlineTextButton } from '../../components';

import css from './ExpandableText.module.css';

// Approximated collapsed size so that there are ~three lines of text
// in the desktop layout in the host section of the ListingPage.
const TEXT_COLLAPSED_LENGTH = 70;

class ExpandableText extends Component {
  constructor(props) {
    super(props);
    this.state = { expand: false };

    this.truncated = this.truncated.bind(this);
  }

  truncated(s) {
    const { textLength } = this.props;
    return truncate(s, {
      length: textLength ? textLength : TEXT_COLLAPSED_LENGTH,

      // Allow truncated text end only in specific characters. This will
      // make the truncated text shorter than the length if the original
      // text has to be shortened and the substring ends in a separator.
      //
      // This ensures that the final text doesn't get cut in the middle
      // of a word.
      separator: /\s|,|\.|:|;/,
      omission: '…',
    });
  }
  render() {
    const { expand } = this.state;
    const { className, text, onClick } = this.props;
    const truncatedBio = this.truncated(text);

    const handleShowMoreClick = () => {
      // You can pass your
      // own onClick function
      if (onClick) {
        onClick();
      } else {
        this.setState({ expand: true });
      }
    };
    const showMore = (
      <InlineTextButton rootClassName={css.showMore} onClick={handleShowMoreClick}>
        <FormattedMessage id="ExpandableText.readMore" />
      </InlineTextButton>
    );
    return (
      <p className={className}>
        {expand ? text : truncatedBio}
        {text !== truncatedBio && !expand ? showMore : null}
      </p>
    );
  }
}

ExpandableText.defaultProps = { className: null };

ExpandableText.propTypes = {
  className: string,
  text: string.isRequired,
};

export default ExpandableText;
