import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { ExternalLink } from '../../components';
import classNames from 'classnames';

import css from './SectionStatic.module.css';

const SectionWrapper = props => {
  const { classes, link, children } = props;

  if (link) {
    return (
      <ExternalLink className={classes} href={link}>
        {children}
      </ExternalLink>
    );
  }

  return <div className={classes}>{children}</div>;
};

const SectionStatic = props => {
  const { rootClassName, className, titleMsgId, textMsgId, imageLink, bgColor, link } = props;
  const classes = classNames(rootClassName || css.root, className);

  const gradient = 'linear-gradient(-45deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3))';
  const backgroundStyle = imageLink
    ? {
        backgroundImage: `${gradient}, url(${imageLink})`,
      }
    : { backgroundColor: bgColor };

  return (
    <SectionWrapper classes={classes} link={link}>
      <div className={css.contentContainer}>
        <div className={css.content} style={{ ...backgroundStyle }}>
          <div className={css.contentGrid}>
            <div className={css.leftContent}>
              <h1 className={css.itemTitle}>
                <FormattedMessage id={titleMsgId} />
              </h1>
            </div>
            <div className={css.rightContent}>
              <p className={css.itemText}>
                <FormattedMessage id={textMsgId} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};

SectionStatic.defaultProps = {
  rootClassName: null,
  className: null,
  titleMsgId: null,
  textMsgId: null,
  imageLink: null,
  link: null,
};

const { string } = PropTypes;

SectionStatic.propTypes = {
  rootClassName: string,
  className: string,
  titleMsgId: string,
  textMsgId: string,
  imageLink: string,
  link: string,
};

export default SectionStatic;
