import React from 'react';
import { string, func, oneOfType } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  AvatarMedium,
  NamedLink,
  ExternalLink,
  InlineTextButton,
  IconReviewStar,
  IconStravaProfile,
} from '../../components';
import { ensureUser, ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';

import css from './UserCard.module.css';

const UserCard = props => {
  const {
    rootClassName,
    className,
    user,
    currentUser,
    onContactUser,
    reviews,
    avgRating,
    disableContact,
  } = props;

  const userIsCurrentUser = user && user.type === 'currentUser';
  const ensuredUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const isCurrentUser =
    ensuredUser.id && ensuredCurrentUser.id && ensuredUser.id.uuid === ensuredCurrentUser.id.uuid;
  const { displayName, publicData } = ensuredUser.attributes.profile;
  const stravaProfile = publicData?.stravaProfile;

  const handleContactUserClick = () => {
    onContactUser(user);
  };

  const classes = classNames(rootClassName || css.root, className);
  const linkClasses = css.links;

  const separator = isCurrentUser ? null : <span className={css.linkSeparator}>•</span>;

  const contact = (
    <InlineTextButton
      rootClassName={css.contact}
      onClick={handleContactUserClick}
      enforcePagePreloadFor="SignupPage"
    >
      <FormattedMessage id="UserCard.contactUser" />
    </InlineTextButton>
  );

  const editProfileMobile = (
    <span className={css.editProfileMobile}>
      <span className={css.linkSeparator}>•</span>
      <NamedLink name="ProfileSettingsPage">
        <FormattedMessage id="ListingPage.editProfileLink" />
      </NamedLink>
    </span>
  );

  const links = ensuredUser.id ? (
    <p className={linkClasses}>
      <NamedLink className={css.link} name="ProfilePage" params={{ id: ensuredUser.id.uuid }}>
        <FormattedMessage id="UserCard.viewProfileLink" />
      </NamedLink>
      {!disableContact ? (
        <>
          {separator}
          {isCurrentUser ? editProfileMobile : contact}
        </>
      ) : null}
    </p>
  ) : null;

  const reviewsInfo =
    reviews.length > 0 ? (
      <div className={css.reviewsInfo}>
        <span>{avgRating}</span>
        <IconReviewStar className={css.reviewsStar} isFilled />
        <FormattedMessage id="UserCard.reviewsLength" values={{ length: reviews?.length }} />
      </div>
    ) : (
      <div className={css.reviewsInfo}>
        <IconReviewStar className={css.reviewsStar} />
        <FormattedMessage id="UserCard.noReviews" />
      </div>
    );

  return (
    <div className={classes}>
      <div className={css.content}>
        <AvatarMedium className={css.avatar} user={user} />
        <div className={css.info}>
          <div className={css.headingRow}>
            <h3 className={css.heading}>
              <FormattedMessage id="UserCard.heading" values={{ name: displayName }} />
            </h3>
          </div>
          {stravaProfile ? (
            <ExternalLink
              href={`https://www.strava.com/athletes/${stravaProfile}`}
              className={css.stravaProfileContainer}
            >
              <IconStravaProfile className={css.stravaIcon} />
              <span className={css.stravaUserName}>
                <FormattedMessage id="UserCard.stravaProfile" />
              </span>
            </ExternalLink>
          ) : null}
          {reviewsInfo}
          {links}
        </div>
      </div>
    </div>
  );
};

UserCard.defaultProps = {
  rootClassName: null,
  className: null,
  user: null,
  currentUser: null,
};

UserCard.propTypes = {
  rootClassName: string,
  className: string,
  user: oneOfType([propTypes.user, propTypes.currentUser]),
  currentUser: propTypes.currentUser,
  onContactUser: func.isRequired,
};

export default UserCard;
