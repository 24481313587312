import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { bool, func, node, oneOfType, shape, string, number } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import {
  ModalInMobile,
  Button,
  PrimaryButton,
  SecondaryButton,
  ExpandableText,
  ExternalLink,
} from '../../components';
import { DiscountForm } from '../../forms';

import css from './ProductPanel.module.css';

// Define min listing stock
const MIN_LISTING_STOCK = 0;

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const ProductPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    submitButtonContainerClassName,
    modalContainerClassName,
    currentUser,
    listing,
    isOwnListing,
    onOrderSubmit,
    title,
    subTitle,
    authorDisplayName,
    onManageDisableScrolling,
    history,
    location,
    intl,
    description,
    showContactUser,
    onContactUser,
    listingStock,
    removeDescription,
    currentUserCartListingIds,
    handleAddToCart,
    addToCartInProgress,
    // addToCartError,
    allowBookings,
    handleDiscountChange,
    discountCode,
    discountCodes,
    fetchDiscountCodesInProgress,
    fetchDiscountCodesError,
  } = props;

  const price = listing.attributes.price;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const isBook = !!parse(location.search).book;
  const currentUserId = currentUser?.id?.uuid;

  const soldListingError = (
    <p className={css.soldListingError}>
      <FormattedMessage id="ProductPanel.soldListingErrorMessage" />
    </p>
  );

  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'ProductPanel.subTitleClosedListing' })
    : null;

  // Check wheter the listing is already in
  // currentUser cart
  const isListingAlreadyInCart = currentUserCartListingIds?.includes(listing?.id?.uuid);

  const isBuyButtonDisabled =
    typeof listingStock === 'undefined' || listingStock === MIN_LISTING_STOCK;
  const isAddToCartButtonDisabled = isOwnListing || isBuyButtonDisabled || isListingAlreadyInCart;
  const showDiscountForm = currentUser && !isOwnListing && listingStock > MIN_LISTING_STOCK;

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={classNames(css.modalContainer, modalContainerClassName)}
        id="ProductPanelModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
          <div className={css.author}>
            <FormattedMessage id="ProductPanel.hostedBy" values={{ name: authorDisplayName }} />
          </div>
        </div>

        <div className={css.bookingHeading}>
          <h2 className={titleClasses}>{title}</h2>
          <div className={css.subTitleAndReport}>
            {subTitleText ? <div className={css.bookingHelp}>{subTitleText}</div> : null}
            {subTitleText ? <span className={css.separator}>•</span> : null}
            <ExternalLink
              href={`mailto:hello@revelo.cc?subject=Report ${title}&body=I want to report listing with id: ${listing.id.uuid}`}
              className={css.smallContactLink}
            >
              <FormattedMessage id="ListingPage.reportListing" />
            </ExternalLink>
          </div>
        </div>

        {!removeDescription && description ? (
          <div className={css.descriptionContainer}>
            <ExpandableText className={css.descriptionText} text={description} />
          </div>
        ) : null}

        {showDiscountForm ? (
          <div className={css.discountFormContainer}>
            <DiscountForm
              currentUserId={currentUserId}
              onSubmit={handleDiscountChange}
              discountCode={discountCode}
              discountCodes={discountCodes}
              discountInProgress={fetchDiscountCodesInProgress}
              discountError={fetchDiscountCodesError}
            />
          </div>
        ) : null}

        {allowBookings ? (
          <div className={classNames(css.submitButtonContainer, submitButtonContainerClassName)}>
            {isBuyButtonDisabled ? (
              soldListingError
            ) : (
              <p className={css.smallPrint}>
                <FormattedMessage
                  id={
                    isOwnListing
                      ? 'ProductPanel.ownListingForBuyPreference'
                      : 'ProductPanel.secureCheckoutInfo'
                  }
                />
              </p>
            )}

            <div className={css.bookingDatesSubmitButtonWrapper}>
              <PrimaryButton
                className={css.buyButton}
                onClick={onOrderSubmit}
                disabled={isOwnListing || isBuyButtonDisabled || isClosed}
              >
                {isClosed ? (
                  <FormattedMessage id="BookingPanel.closedListingButtonText" />
                ) : isBuyButtonDisabled ? (
                  <FormattedMessage id="ProductPanel.soldText" />
                ) : (
                  <FormattedMessage id="ProductPanel.buyNow" />
                )}
              </PrimaryButton>
              <div
                className={classNames(css.bottomButtonsWrapper, {
                  [css.bottomButtonWrapper]: !showContactUser,
                })}
              >
                <SecondaryButton
                  className={css.addToCartButton}
                  onClick={() => handleAddToCart(listing.id.uuid)}
                  inProgress={addToCartInProgress}
                  disabled={isAddToCartButtonDisabled}
                >
                  {isListingAlreadyInCart ? (
                    <FormattedMessage id="ProductPanel.alreadyInCartButtonText" />
                  ) : (
                    <FormattedMessage id="ProductPanel.addToCartButtonText" />
                  )}
                </SecondaryButton>
                {showContactUser ? (
                  <SecondaryButton
                    className={css.contactButton}
                    type="submit"
                    onClick={onContactUser}
                  >
                    <FormattedMessage id="ProductPanel.contactSeller" />
                  </SecondaryButton>
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          <div className={css.bookingsNotAllowedContainer}>
            <p className={css.bookingsNotAllowedMessage}>
              <FormattedMessage id="ProductPanel.bookingsNotAllowed" />
            </p>
          </div>
        )}
      </ModalInMobile>
      <div className={css.openBookingForm}>
        <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
        </div>
        {allowBookings ? (
          isClosed ? (
            <div
              className={classNames(css.mobileButtonsWrapper, {
                [css.mobileButtonsWrapperGrid]: showContactUser,
              })}
            >
              {showContactUser ? (
                <SecondaryButton
                  className={css.contactButton}
                  type="submit"
                  onClick={onContactUser}
                >
                  <FormattedMessage id="ProductPanel.contactSeller" />
                </SecondaryButton>
              ) : null}
              <div className={css.closedListingButton}>
                <FormattedMessage id="ProductPanel.closedListingButtonText" />
              </div>
            </div>
          ) : (
            <div
              className={classNames(css.mobileButtonsWrapper, {
                [css.mobileButtonsWrapperGrid]: showContactUser,
              })}
            >
              <SecondaryButton
                className={css.addToCartButton}
                onClick={() => handleAddToCart(listing.id.uuid)}
                inProgress={addToCartInProgress}
                disabled={isAddToCartButtonDisabled}
              >
                {isListingAlreadyInCart ? (
                  <FormattedMessage id="ProductPanel.inCartButtonText" />
                ) : (
                  <FormattedMessage id="ProductPanel.addToCartButtonText" />
                )}
              </SecondaryButton>
              <Button
                rootClassName={css.bookButton}
                onClick={onOrderSubmit}
                disabled={isBuyButtonDisabled || isClosed || isOwnListing}
              >
                {isBuyButtonDisabled ? (
                  <FormattedMessage id="ProductPanel.soldText" />
                ) : (
                  <FormattedMessage id="ProductPanel.buyNow" />
                )}
              </Button>
            </div>
          )
        ) : (
          <div className={css.bookingsNotAllowedContainer}>
            <p className={css.bookingsNotAllowedMessage}>
              <FormattedMessage id="ProductPanel.bookingsNotAllowedMobile" />
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

ProductPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  listingStock: 0,
};

ProductPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onOrderSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  listingStock: number.isRequired,
  handleAddToCart: func.isRequired,
  addToCartInProgress: bool.isRequired,
  addToCartError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(ProductPanel);
