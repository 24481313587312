/**
 * NOTE: This component is only used in LandingPage
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { Carousel, CarouselCard, MobileCarousel, MobileCarouselCard } from '../../components';

import css from './SectionListings.module.css';

const SectionListings = props => {
  const {
    rootClassName,
    className,
    listings,
    listingsLoaded,
    fetchListingsInProgress,
    fetchListingsError,
    currentUser,
    onAddToWishList,
    currentWishListListingId,
    addToWishListInProgress,
    addToWishListError,
    titleMsgId,
    textMsgId,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  // Declare when the carousel should be shown
  const showCarousel = fetchListingsInProgress || !fetchListingsError;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const cardProps = {
    currentUser,
    onAddToWishList,
    currentWishListListingId,
    addToWishListInProgress,
    addToWishListError,
  };

  return listingsLoaded ? (
    <div className={classes}>
      <div className={css.titleWrapper}>
        <h1 className={css.title}>
          <FormattedMessage id={titleMsgId} />
        </h1>
        <p className={css.subTitle}>
          <FormattedMessage id={textMsgId} />
        </p>
      </div>
      {!fetchListingsInProgress ? (
        <div className={css.desktopListings}>
          <Carousel show={showCarousel}>
            {listings?.map(l => {
              return (
                <CarouselCard
                  key={l.id.uuid}
                  listing={l}
                  renderSizes={cardRenderSizes}
                  {...cardProps}
                />
              );
            })}
          </Carousel>
        </div>
      ) : null}
      {!fetchListingsInProgress ? (
        <div className={css.mobileListings}>
          <MobileCarousel>
            {listings?.map(l => {
              return (
                <MobileCarouselCard
                  key={l.id.uuid}
                  listing={l}
                  renderSizes={cardRenderSizes}
                  {...cardProps}
                />
              );
            })}
          </MobileCarousel>
        </div>
      ) : null}
    </div>
  ) : null;
};

SectionListings.defaultProps = { rootClassName: null, className: null, listings: null };

const { string, array, bool } = PropTypes;

SectionListings.propTypes = {
  rootClassName: string,
  className: string,

  // Listings
  listings: array.isRequired,
  listingsLoaded: bool.isRequired,
  fetchListingsInProgress: bool.isRequired,
  fetchListingsError: propTypes.error,
};

export default SectionListings;
