import React from 'react';
import PropTypes from 'prop-types';
import { NamedLink, ExternalLink } from '../../components';
import classNames from 'classnames';
import config from '../../config';

import css from './TermsOfService.module.css';

const siteCompanyInformationPage = config.siteCompanyInformationPage;

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: March 25, 2023</p>

      <p>
        Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before
        using the wwww.revelo.cc website (the "Service") operated by <ExternalLink href={siteCompanyInformationPage}>VELO COLLECTIV LTD</ExternalLink> ("us",
        "we", or "our") registered in England and Wales under company number 13379709.
      </p>

      <p>
        Your access to and use of the Service is conditioned on your acceptance of and compliance
        with these Terms. These Terms apply to all visitors, users and others who access or use the
        Service. By accessing or using the Service you agree to be bound by these Terms. If you
        disagree with any part of the terms then you may not access the Service.
      </p>

      <h2>1. Purchases</h2>
      <p>
        You are encouraged to familiarise yourself with your rights contained within the Sale of
        Goods Act 1979, Unfair Contract Terms Act 1977 and the Unfair Terms in Consumer Contracts
        Regulations 1999.If you wish to purchase any product or service made available through the
        Service ("Shop"), you may be asked to supply certain information relevant to your Purchase
        including, without limitation, your credit card number, the expiration date of your credit
        card, your billing address, and your shipping information.You represent and warrant that:
        (i) you have the legal right to use any credit card(s) or other payment method(s) in
        connection with any Purchase; and that (ii) t he information you supply to us is true,
        correct and complete. You expressly agree that VELO COLLECTIV LTD is not responsible for any
        loss or damage arising from the submission of false or inaccurate information. By submitting
        such information, you grant us the right to provide the information to third parties for
        purposes of facilitating the completion of Purchases.We reserve the right to refuse or
        cancel your order at any time for certain reasons including but not limited to: product or
        service availability, errors in the description or price of the product or service, error in
        your order or other reasons. You expressly agree that VELO COLLECTIV LTD cannot accept any
        liability for loss or damage arising out of such cancellation.We reserve the right to refuse
        or cancel your order if fraud or an unauthorised or illegal transaction is suspected.
      </p>

      <h2>2. Sales</h2>
      <p>
        The seller of the item(s) hereby appoints, on an exclusive basis, VELO COLLECTIV LTD to
        provide it with the following services:
      </p>

      <ul>
        <li>
          Commission taken to customers, who are non professional is 30%. VELO COLLECTIV allows
          customers to upload items for sale or rent. VELO COLLECTIV proceed with an online check
          and ensure accuracy of the items. VELO COLLECTIV has the right to refuse items based on
          condition, brands or trends VELO COLLECTIV will decide for your items to participate in
          promotions or sales. If items are sold, the customer is to pack them and ship them to each
          buyer.
        </li>
        <li>
          Customer will receive payment of the amounts paid by each buyer for the items
          corresponding to the sale price s et on the platform, onto which the commission due to
          VELO COLLECTIV LTD will be deducted
        </li>
        <li>
          The Seller acknowledges that is has understood that, once the items are sold, the Seller
          may not retrieve them from VELO COLLECTIV LTD.
        </li>
      </ul>

      <h2>3. Products</h2>
      <p>
        VELO COLLECTIV LTD has the right to list the items whenever VELO COLLECTIV LTD decides it.
        VELO COLLECTIV will not get approval from the sellers on preferred prices. VELO COLLECTIV
        has the right to refuse an item should the brand, quality would not respect the standard
      </p>
      <p>
        All items sent to VELO COLLECTIV and not respecting overall guidelines will be sent to
        charity. (unremovable stains, poor garment quality, Brands not approved discussed prior with
        the seller)
      </p>
      <p>At all times from the receipt of the items, the seller cannot retrieve its items back.</p>
      <p>
        After 1 year of the items uploaded on VELO COLLECTIV Website, should the products still not
        be sold, VELO COLLECTIV LTD will reach out to sellers: they will have the option to
        retrieve, send to charity or gift VELO COLLECTIV with the items.
      </p>

      <h2>4. Availability, Errors and Inaccuracies</h2>
      <p>
        We are constantly updating our offerings of products and services on the Service. The
        products or services available on our Service may be mispriced, described inaccurately, or
        unavailable, and we may experience delays in updating information on the Service and in our
        advertising on other web sites. You expressly agree that any such offer of a product or
        service does not constitute a legal offer capable of attracting legal consequences.We cannot
        and do not guarantee the accuracy or completeness of any information, including prices,
        product images, specifications, availability, and services. We reserve the right to change
        or update information and to correct errors, inaccuracies, or omissions at any time without
        prior notice. Section "Availabilit y, Errors and Inaccuracies" is without prejudice to
        existing statutory rights.
      </p>

      <h2>5. Contests, Sweepstakes and Promotions</h2>
      <p>
        Any contests, sweepstakes or other promotions (collectively, "Promotions") made available
        through the Service may be governed by rules that are separate from these Terms. If you
        participate in any Promotions, please review the applicable rules as well as our Privacy
        Policy. If the rules for a Promotion conflict with these Terms and Conditions, the Promotion
        rules will apply. The terms and cond itions of any other "Promotions" are independent of
        this agreement.
      </p>

      <h2>6. Accounts</h2>
      <p>
        When you create an account with us, you must provide us information that is accurate,
        complete, and current at all times. Failure to do so constitutes a breach of the Terms,
        which may result in immediate termination of your account on our Service.You are responsible
        for safeguarding the password that you use to access the Service and for any activities or
        actions under your password, whether your password is with our Service or a t hird - party
        service.You agree not to disclose your password to any third party. You must notify us
        immediately upon becoming aware of any breach of security or unauthorized use of your
        account.
      </p>

      <h2>7. Intellectual Property</h2>
      <p>
        The Service and its original content, features and functionality are and will remain the
        exclusive property of VELO COLLECTIV LTD and its licensors. The Service is protected by
        copyright, trademark, and other laws of both the United Kingdom and foreign countries. Our
        trademarks and trade dress may not be used in connection with any product or service without
        the prior written consent of VELO COLLECTIV LTD.
      </p>

      <h2>8. Links To Other Web Sites</h2>
      <p>
        Our Service may contain links to third - party web sites or services that are not owned or
        controlled by VELO COLLECTIV LTD. VELO COLLECTIV LTD has no control over, and assumes no
        responsibility for, the content, privacy policies, or practices of any third party web sites
        or services. You further acknowledge and agree that VELO COLLECTIV LTD shall not be
        responsible or liable, directly or indirectly, for any damage or loss caused or alleged to
        be caused by or in connection with use of or reliance on any such content, goods or services
        available on or through any such web sites or services.We strongly advise you to read the
        terms and conditi ons and privacy policies of any third - party web sites or services that
        you visit.
      </p>

      <h2>9. Termination</h2>
      <p>
        We may terminate or suspend your account immediately, without prior notice or liability, for
        any reason whatsoever, including without limitation if you breach the Terms.Upon
        termination, yourright to use the Service will immediately cease. If you wish to terminate
        your account, you may simply discontinue using the Service. All provisions of the Terms
        which by their nature should survive termination shall survive te rmination, including,
        without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of
        liability.
      </p>

      <h2>10. Indeminifaction</h2>
      <p>
        You agree to defend, indemnify and hold harmless VELO COLLECTIV LTD and its licensee and
        licensors, and their employe es, contractors, agents, officers and directors, from and
        against any and all claims, damages, obligations, losses, liabilities, costs or debt, and
        expenses (including but not limited to attorney's fees), resulting from or arising out of a)
        your use and ac cess of the Service, by you or any person using your account and password,
        or b) a breach of these Terms.
      </p>

      <h2>11. Limitation Of Liability</h2>
      <p>
        In no event shall VELO COLLECTIV LTD, nor its directors, employees, partners, agents,
        suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or
        punitive damages, including without limitation, loss of profits, data, use, goodwill, or
        other intangible losses, resulting from (i) your access to or use of or inability to access
        or use the Service; (ii) a ny conduct or content of any third party on the Service; (iii)
        any content obtained from the Service; and (iv) unauthorized access, use or alteration of
        your transmissions or content, whether based on warranty, contract, tort (including
        negligence) or any other legal theory, whether or not we have been informed of the
        possibility of such damage, and even if a remedy set forth herein is found to have failed of
        its essential purpose.
      </p>

      <h2>12. Disclaimer</h2>
      <p>
        Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS
        AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or
        implied, including, but not limited to, implied warranties of merchanta bility, fitness for
        a particular purpose, non - infringement or course of performance. VELO COLLECTIV LTD its
        subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function
        uninterrupted, secure or available at any particular time o r location; b) any errors or
        defects will be corrected; c) the Service is free of viruses or other harmful components; or
        d) the results of using the Service will meet your requirements.
      </p>

      <h2>13. Exclusions</h2>
      <p>
        Without limiting the generality of the foregoing and notwi thstanding any other provision of
        these terms, under no circumstances will VELO COLLECTIV LTD ever be liable to you or any
        other person for any indirect, incidental, consequential, special, punitive or exemplary
        loss or damage arising from, connected with, or relating to your use of the Service, these
        Terms, the subject matter of these Terms, the termination of these Terms or otherwise,
        including but not limited to personal injury, loss of data, business, markets, savings,
        income, profits, use, production, rep utation or goodwill, anticipated or otherwise, or
        economic loss, under any theory of liability (whether in contract, tort, strict liability or
        any other theory or law or equity), regardless of any negligence or other fault or
        wrongdoing (including without limitation gross negligence and fundamental breach) by VELO
        COLLECTIV LTD or any person for whom VELO COLLECTIV LTD is responsible, and even if VELO
        COLLECTIV LTD has been advised of the possibility of such loss or damage being incurred.
      </p>

      <h2>14. Governing Law</h2>
      <p>
        These Terms shall be governed and construed in accordance with the laws of England and
        Wales, without regard to its conflict of law provisions.Our failure to enforce any right or
        provision of these Terms will not be considered a waiver of those rights. If any provision
        of these Terms is held to be invalid or unenforceable by a court, the remaining provisions
        of these Terms will remain in effect. These Terms constitute the entire agreement between us
        regarding our Service, and supersede and replace any prior agreements w e might have between
        us regarding the Service.
      </p>

      <h2>15. Changes</h2>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these Terms at any time.
        If a revision is material we will try to provide at least 30 days notice prior to any new
        terms taking effect. What constitutes a material change will be determined at our sole
        discretion.By continuing to access or use our Service after those revisions become
        effective, you agree to be bound by the revised terms. If you do not agree to the new terms,
        you must sto p using the service.
      </p>

      <h2>Privacy Policy and Cookies Policy</h2>
      <p>
        Please refer to our <NamedLink name="PrivacyPolicyPage">Privacy Policy</NamedLink> and Cookies Policy. You agree that they constitute part
        of these terms. You must read our Privacy Policy and Cookies Policy before you use the
        Service.
      </p>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
