import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';
import { findConfigForMenuItem } from '../../util/data';
import classNames from 'classnames';
import config from '../../config';

import Dropdown from './Dropdown';
import css from './TopbarDropdownMenu.module.css';

const BRAND_ITEM_KEY = 'brands';

const withBoxShadowPageNames = [
  'SearchPage',
  'ListingPage',
  'TransactionPage',
  'AuthenticationPage',
  'PasswordRecoveryPage',
  'PasswordResetPage',
  'CartPage',
];
const withBorderPageNames = [
  'EditListingPage',
  'InboxPage',
  'ProfilePage',
  'PrivacyPolicyPage',
  'TermsOfServicePage',
  'NotFoundPage',
  'WizardPreferencePage',
  'WizardCategoryPage',
  'WizardSubCategoryPage',
];

const checkActiveIndex = (currentKey, key) => currentKey === key;

const TopbarDropdownMenu = props => {
  const {
    rootClassName,
    className,
    topbarDropdownMenuConfig,
    isScrollAtTop,
    currentPage,
    onFetchPopularBrands,
    popularBrands,
    fetchPopularBrandsInProgress,
  } = props;

  const hasPopularBrands = popularBrands?.length > 0;

  // Keep the state of the menu
  const [isHovered, setIsHovered] = useState(false);
  const [activeKey, setActiveKey] = useState(null);

  const onMouseEnter = menuKey => {
    setIsHovered(true);
    setActiveKey(menuKey);

    if (menuKey === BRAND_ITEM_KEY && !hasPopularBrands) {
      onFetchPopularBrands();
    }
  };
  const onMouseLeave = () => {
    setIsHovered(false);
    setActiveKey(null);
  };

  const displayShadowOrBorder = false;
  const classes = classNames(rootClassName || css.root, className, {
    [css.rootWithScroll]: !isScrollAtTop,
    [css.rootWithShadow]: displayShadowOrBorder && withBoxShadowPageNames.includes(currentPage),
    [css.rootWithBorder]: displayShadowOrBorder && withBorderPageNames.includes(currentPage),
  });

  return (
    <div className={classes}>
      <div className={css.menuContent}>
        <ul className={css.menuList}>
          {topbarDropdownMenuConfig.map(item => {
            const isItemActive = checkActiveIndex(activeKey, item.key);
            const itemLinkPage = item?.page;

            const dropdownConfig = findConfigForMenuItem(item);
            const showDropdown =
              isHovered && dropdownConfig && checkActiveIndex(activeKey, item.key);
            return dropdownConfig ? (
              <li
                key={item.key}
                className={classNames(css.menuItem, { [css.menuItemActive]: isItemActive })}
                onMouseEnter={() => onMouseEnter(item.key)}
                onMouseLeave={onMouseLeave}
              >
                {itemLinkPage ? (
                  <NamedLink name={itemLinkPage} className={css.menuItemLabel}>
                    <FormattedMessage id={item.labelMsgId} />
                  </NamedLink>
                ) : (
                  <div className={css.menuItemLabel}>
                    <FormattedMessage id={item.labelMsgId} />
                  </div>
                )}
                {showDropdown ? (
                  <Dropdown
                    item={item}
                    dropdownConfig={dropdownConfig}
                    popularBrands={popularBrands}
                    fetchPopularBrandsInProgress={fetchPopularBrandsInProgress}
                  />
                ) : null}
              </li>
            ) : (
              <li key={item.key} className={css.menuLink}>
                <NamedLink className={css.menuItemLink} name={itemLinkPage}>
                  <FormattedMessage id={item.labelMsgId} />
                </NamedLink>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

TopbarDropdownMenu.defaultProps = {
  rootClassName: null,
  className: null,

  // config
  topbarDropdownMenuConfig: config.custom.topbarDropdownMenu,
};

const { string } = PropTypes;

TopbarDropdownMenu.propTypes = {
  rootClassName: string,
  className: string,
};

export default TopbarDropdownMenu;
