export const brandsListBox = [
  {
    key: 'other',
    label: 'Other',
  },
  {
    key: 'bike-box-alan',
    label: 'Bike Box Alan',
  },
  {
    key: 'bonza-bike-kit',
    label: 'Bonza Bike Kit',
  },
  {
    key: 'evoc',
    label: 'EVOC',
  },
  {
    key: 'lifeline',
    label: 'LifeLine',
  },
  {
    key: 'scicon',
    label: 'Scicon',
  },
  {
    key: 'shokbox',
    label: 'Shokbox',
  },
  {
    key: 'thule',
    label: 'Thule',
  },
  {
    key: 'topeak',
    label: 'Topeak',
  },
];
