import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './WizardHeader.module.css';

const WizardHeader = props => {
  const { rootClassName, className, headerHighlightedText } = props;
  const classes = classNames(rootClassName || css.root, className);

  const highlightedText = <span className={css.highlightedText}>{headerHighlightedText}</span>;
  return (
    <div className={classes}>
      <h1 className={css.title}>
        <FormattedMessage
          id="WizardHeader.title"
          values={{
            highlightedText,
          }}
        />
      </h1>
    </div>
  );
};

WizardHeader.defaultProps = {
  rootClassName: null,
  className: null,
};

WizardHeader.propTypes = {
  rootClassName: string,
  className: string,
};

export default WizardHeader;
