import React from 'react';
import css from './DiscountForm.module.css';

const IconSuccess = props => {
  return (
    <svg
      className={css.icon}
      height="22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
      {...props}
    >
      <g
        className={css.errorStrokeColor}
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="rotate(.077) scale(1.57143)"
      >
        <path d="M9.12 4.88L4.88 9.12M4.88 4.88l4.24 4.24" />
        <circle cx={7} cy={7} r={6.5} />
      </g>
    </svg>
  );
};

export default IconSuccess;
