import sliderImageOne from './images/sliderImage-1.jpg';
import sliderImageTwo from './images/sliderImage-2.jpg';
import sliderImageThree from './images/sliderImage-3.jpg';
import sliderImageFive from './images/sliderImage-5.jpg';

export default [
  {
    id: '1',
    titleMsgId: 'SectionHowItWorks.firstSliderTitle',
    textMsgId: 'SectionHowItWorks.firstSliderText',
    buttonText: 'Learn more',
    imageSrc: sliderImageOne,
  },
  {
    id: '2',
    titleMsgId: 'SectionHowItWorks.secondSliderTitle',
    textMsgId: 'SectionHowItWorks.secondSliderText',
    buttonText: 'Learn more',
    imageSrc: sliderImageTwo,
  },
  // {
  //   id: '3',
  //   titleMsgId: 'SectionHowItWorks.thirdSliderTitle',
  //   textMsgId: 'SectionHowItWorks.thirdSliderText',
  //   buttonText: 'Learn more',
  //   backgroundColor: 'rgb(17, 90, 54)',
  // },
  {
    id: '3',
    titleMsgId: 'SectionHowItWorks.forthSliderTitle',
    textMsgId: 'SectionHowItWorks.forthSliderText',
    buttonText: 'Learn more',
    //backgroundColor: 'rgb(17, 90, 54)',
    imageSrc: sliderImageThree,
  },
  // {
  //   id: '5',
  //   titleMsgId: 'SectionHowItWorks.fifthSliderTitle',
  //   textMsgId: 'SectionHowItWorks.fifthSliderText',
  //   buttonText: 'Learn more',
  //   imageSrc: sliderImageFive,
  // },
];
