import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import {
  InlineTextButton,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  Logo,
  Avatar,
  IconCart,
} from '../../components';
import { TopbarSearchForm, TopbarPreferenceSearchForm } from '../../forms';
import classNames from 'classnames';
import Switch from 'react-switch';

// Topbar icons
import IconInbox from './TopbarIcons/IconInbox';
import IconWishList from './TopbarIcons/IconWishList';
import IconProfile from './TopbarIcons/IconProfile';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    intl,
    className,
    currentPage,
    rootClassName,
    currentUser,
    currentUserHasListings,
    notificationCount,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    onKeywordSearchSubmit,
    initialSearchFormValues,
    isSearchPageRent,
    history,
    currentUserCartListingIds,
    currentUserWishListListingIds,
  } = props;
  const [mounted, setMounted] = useState(false);
  const [isRentSearchType, setSearchType] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    setSearchType(!!isSearchPageRent);
  }, [isSearchPageRent]);

  const handleSearchToggle = checked => {
    if (checked) {
      history.push(createResourceLocatorString('RentSearchPage', routeConfiguration(), {}, {}));
    } else {
      history.push(createResourceLocatorString('BuySearchPage', routeConfiguration(), {}, {}));
    }
  };

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const avatarDisplayName = currentUser?.attributes?.profile?.displayName;

  const wishListListingsCount = currentUserWishListListingIds?.length;
  const cartListingsCount = currentUserCartListingIds?.length;

  const hasNotifications = notificationCount > 0;
  const hasListingsInWishList = wishListListingsCount > 0;
  const hasListingsInCart = cartListingsCount > 0;

  const searchForRentType = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
    />
  );
  const searchForBuyType = <TopbarPreferenceSearchForm onSubmit={onKeywordSearchSubmit} />;

  const classes = classNames(rootClassName || css.root, className);

  const notificationDot = hasNotifications ? (
    <div className={css.notificationDot}>
      <span className={css.notificationCount}>{notificationCount}</span>
    </div>
  ) : null;
  const wishListListingsDot = hasListingsInWishList ? (
    <div className={css.notificationDot}>
      <span className={css.notificationCount}>{wishListListingsCount}</span>
    </div>
  ) : null;
  const cartDot = hasListingsInCart ? (
    <div className={css.notificationDot}>
      <span className={css.notificationCount}>{cartListingsCount}</span>
    </div>
  ) : null;

  // Links
  const inboxLink = (
    <NamedLink
      className={classNames(css.navigationLink, css.inboxLink, {
        [css.notificationLink]: hasNotifications,
      })}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
      title={intl.formatMessage({ id: 'TopbarDesktop.inbox' })}
    >
      <IconInbox className={css.navigationIcon} />
      {notificationDot}
    </NamedLink>
  );

  const wishListLink = (
    <NamedLink
      className={classNames(css.navigationLink, css.wishlistLink, {
        [css.notificationLink]: hasListingsInWishList,
      })}
      name="WishListPage"
      title={intl.formatMessage({ id: 'TopbarDesktop.wishlist' })}
    >
      <IconWishList className={css.navigationIcon} />
      {wishListListingsDot}
    </NamedLink>
  );

  const cartLink = (
    <NamedLink
      className={classNames(css.navigationLink, {
        [css.notificationLink]: hasListingsInCart,
      })}
      name="CartPage"
      title={intl.formatMessage({ id: 'TopbarDesktop.cart' })}
    >
      <IconCart className={css.navigationIcon} />
      {cartDot}
    </NamedLink>
  );

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const profileMenu = authenticatedOnClientSide ? (
    <Menu rootClassName={classNames(css.navigationLink, css.profileMenu)}>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <div className={css.avatarContainer}>
          <span className={css.avatarDisplayName}>
            <FormattedMessage id="TopbarDesktop.avatarDisplayName" values={{ avatarDisplayName }} />
          </span>
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
        </div>
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : (
    <NamedLink
      className={classNames(css.navigationLink, css.notAuthenticatedProfileLink)}
      name="LoginPage"
    >
      <IconProfile className={css.navigationIcon} />
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <div className={css.navigationWrapper}>
        <div className={css.searchBar}>
          <div className={css.switchContainer}>
            <div
              className={classNames(css.switchOption, {
                [css.switchOptionActive]: !isRentSearchType,
              })}
            >
              <FormattedMessage id="TopbarDesktop.switchBuy" />
            </div>

            <Switch
              className={css.switch}
              onChange={checked => {
                setSearchType(checked);

                if (currentPage === 'SearchPage') {
                  handleSearchToggle(checked);
                }
              }}
              checked={isRentSearchType}
              onColor="#000000"
              offColor="#000000"
              onHandleColor="#fff"
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              handleDiameter={25}
              height={20}
              width={42}
            />
            <div
              className={classNames(css.switchOption, {
                [css.switchOptionActive]: isRentSearchType,
              })}
            >
              <FormattedMessage id="TopbarDesktop.switchRent" />
            </div>
          </div>
          {isRentSearchType ? searchForRentType : searchForBuyType}
        </div>
        <div className={css.logoContainer}>
          <NamedLink name="LandingPage" title={intl.formatMessage({ id: 'Topbar.logoIcon' })}>
            <Logo className={css.logo} />
          </NamedLink>
        </div>
        <div className={css.navigationLinks}>
          {inboxLink}
          {wishListLink}
          {cartLink}
          {profileMenu}
        </div>
      </div>
    </nav>
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
