import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaStrava.module.css';

const IconSocialMediaStrava = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.9 8.8l2.5 4.5 2.4-4.5h-1.5l-.9 1.7-1-1.7z" fillRule="evenodd" />
      <path d="M7.2 2.5l3.1 6.3H4zm0 3.8l1.2 2.5H5.9z" fillRule="evenodd" />
    </svg>
  );
};

IconSocialMediaStrava.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaStrava.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaStrava;
