import React from 'react';
import { IconArrowHead } from '../../components';
import { findConfigForMenuItem } from '../../util/data';
import classNames from 'classnames';

import MenuAccordionSubItem from './MenuAccordionSubItem';
import css from './TopbarMobileMenu.module.css';

const MenuAccordionItem = props => {
  const {
    item,
    activeItemKey,
    setActiveItemKey,
    activeSubItemKey,
    setActiveSubItemKey,
    onFetchPopularBrands,
    popularBrands,
    fetchPopularBrandsInProgress,
  } = props;
  const subItems = findConfigForMenuItem(item);

  // Is active menu index same
  // as current menu index
  const isActiveItemKey = activeItemKey === item.key;

  // Toggle active menu item
  const toggleActiveItem = key => {
    if (isActiveItemKey) {
      setActiveItemKey(null);
    } else {
      setActiveItemKey(key);
      setActiveSubItemKey(null);
    }
  };

  const itemClasses = classNames(css.accordionItem, {
    [css.activeAccordionItem]: isActiveItemKey,
  });

  return (
    <div className={itemClasses}>
      <div className={css.accordionItemNav} onClick={() => toggleActiveItem(item.key)}>
        <span className={css.itemLabel}>{item.label}</span>
        <IconArrowHead className={css.arrow} direction="right" />
      </div>
      {isActiveItemKey &&
        subItems?.map(i => {
          if (i.key === 'bike') {
            return null;
          }
          return (
            <MenuAccordionSubItem
              key={i.key}
              item={i}
              activeSubItemKey={activeSubItemKey}
              setActiveSubItemKey={setActiveSubItemKey}
              onFetchPopularBrands={onFetchPopularBrands}
              popularBrands={popularBrands}
              fetchPopularBrandsInProgress={fetchPopularBrandsInProgress}
            />
          );
        })}
    </div>
  );
};

export default MenuAccordionItem;
