import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

import css from './IconList.module.css';

const IconList = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      height="12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 409.6 409.6"
    >
      <path d="M392.533 17.067H17.067C7.641 17.067 0 24.708 0 34.133S7.641 51.2 17.067 51.2h375.467c9.426 0 17.067-7.641 17.067-17.067s-7.642-17.066-17.068-17.066zM392.533 187.733H17.067C7.641 187.733 0 195.374 0 204.8s7.641 17.067 17.067 17.067h375.467c9.426 0 17.067-7.641 17.067-17.067s-7.642-17.067-17.068-17.067zM392.533 358.4H17.067C7.641 358.4 0 366.041 0 375.467s7.641 17.067 17.067 17.067h375.467c9.426 0 17.067-7.641 17.067-17.067s-7.642-17.067-17.068-17.067z" />
    </svg>
  );
};

IconList.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconList.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconList;
