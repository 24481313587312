import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconWarning.module.css';

const IconWarning = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      height="24"
      width="24"
    >
      <g transform="scale(2.28571)">
        <circle
          cx="7"
          cy="7"
          r="6.5"
          style={{
            fill: 'none',
            stroke: 'rgba(0,0,0,.8)',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '1',
          }}
        />
        <path
          style={{
            fill: 'none',
            stroke: 'rgba(0,0,0,.8)',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '1',
          }}
          d="M7 3.5v3"
        />
        <circle
          cx="7"
          cy="9.5"
          r="0.5"
          style={{
            fill: 'none',
            stroke: 'rgba(0,0,0,.8)',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeWidth: '1',
          }}
        />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconWarning.defaultProps = {
  className: null,
  rootClassName: null,
};

IconWarning.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconWarning;
