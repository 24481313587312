import React from 'react';
import PropTypes from 'prop-types';
import { IconReviewStar, Avatar } from '../../components';
import classNames from 'classnames';

import css from './ReviewCard.module.css';

// Set the default review state to public
const REVIEW_STATE = 'public';

const ReviewCard = props => {
  const { rootClassName, className, review } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (!review) {
    throw new Error("Review doesn't exist on the marketplace.");
  }

  // Review params
  const { state, content, rating } = review.attributes;

  // Listing params
  const listingTitle = review.listing?.attributes?.title;

  // Author
  const author = review.author;
  const authorDisplayName = review.author?.attributes?.profile?.displayName;

  // Check wheter review has been published
  const isReviewPublished = state === REVIEW_STATE;

  // Generate stars array
  const starReviewArray = [...Array(5).keys()];

  return isReviewPublished ? (
    <div className={classes}>
      <div className={css.reviewContent}>
        <div className={css.upperContent}>
          <div className={css.stars}>
            {starReviewArray.map((star, index) => {
              return star < rating ? (
                <div key={index} className={css.starContainer}>
                  <IconReviewStar className={css.star} isFilled />
                </div>
              ) : null;
            })}
          </div>
          <h4 className={css.listingTitle}>
            {listingTitle ? listingTitle : 'Gios Ijsboerke NOS Vintage Team Training Jacket'}
          </h4>
          <p className={css.content}>{content}</p>
        </div>
        <div className={css.author}>
          <Avatar className={css.avatar} user={author} />
          <h4 className={css.authorDisplayName}>{authorDisplayName}</h4>
        </div>
      </div>
    </div>
  ) : null;
};

ReviewCard.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

ReviewCard.propTypes = {
  rootClassName: string,
  className: string,
};

export default ReviewCard;
