import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingPhotosForm } from '../../forms';
import {
  ensureOwnListing,
  getListingCategoriesFromURL,
  getListingCategoryLabelFromURL,
  getListingSubCategoryLabelFromURL,
} from '../../util/data';
import { ListingLink, WizardSelections } from '../../components';
import config from '../../config';

import css from './EditListingPhotosPanel.module.css';

class EditListingPhotosPanel extends Component {
  render() {
    const {
      className,
      rootClassName,
      errors,
      disabled,
      ready,
      images,
      listing,
      onImageUpload,
      onUpdateImageOrder,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      onChange,
      onSubmit,
      onRemoveImage,
      params,
      isNewListingFlow,
      categories,
    } = this.props;

    const rootClass = rootClassName || css.root;
    const classes = classNames(rootClass, className);
    const currentListing = ensureOwnListing(listing);
    const { title, publicData } = currentListing.attributes;

    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingPhotosPanel.title"
        values={{ listingTitle: <ListingLink listing={listing} /> }}
      />
    ) : (
      <FormattedMessage id="EditListingPhotosPanel.createListingTitle" />
    );

    // Previous preference and categories selections
    const listingPreference = params?.preference || publicData?.preference;
    const listingCategory = params?.category || publicData?.category;
    const listingSubCategory = params?.subcategory || publicData?.subcategory;

    const currentPreferenceCategories = getListingCategoriesFromURL(categories, listingPreference);

    const categoryLabel = getListingCategoryLabelFromURL(
      currentPreferenceCategories,
      listingCategory
    );
    const subCategoryLabel = getListingSubCategoryLabelFromURL(
      categories,
      listingPreference,
      listingCategory,
      listingSubCategory
    );

    return (
      <div className={classes}>
        <WizardSelections params={params} publicData={publicData} isPublished={isPublished} />
        <h1 className={css.title}>{panelTitle}</h1>
        <EditListingPhotosForm
          className={css.form}
          disabled={disabled}
          ready={ready}
          fetchErrors={errors}
          initialValues={{ images }}
          images={images}
          onImageUpload={onImageUpload}
          onSubmit={values => {
            const { addImage, ...updateValues } = values;

            const defaultListingTitle = subCategoryLabel
              ? `${categoryLabel} ${subCategoryLabel}`
              : categoryLabel;
            const titleMaybe = isNewListingFlow && !title ? { title: defaultListingTitle } : {};

            const listingParams = {
              preference: listingPreference,
              category: listingCategory,
              subcategory: listingSubCategory,
            };

            const updatedValues = {
              ...updateValues,
              ...titleMaybe,
              publicData: {
                ...listingParams,
              },
            };
            onSubmit(updatedValues);
          }}
          onChange={onChange}
          onUpdateImageOrder={onUpdateImageOrder}
          onRemoveImage={onRemoveImage}
          saveActionMsg={submitButtonText}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          listingPreference={listingPreference}
          listingCategory={listingCategory}
        />
      </div>
    );
  }
}

EditListingPhotosPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  images: [],
  listing: null,
  categories: config.custom.editListingCategories.options,
};

EditListingPhotosPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  images: array,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default EditListingPhotosPanel;
