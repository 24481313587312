export const brandsList = [
  {
    key: 'other',
    label: 'Other',
  },
  {
    key: '100%',
    label: '100%',
  },
  {
    key: '2XU',
    label: '2XU',
  },
  {
    key: '3t',
    label: '3T',
  },
  {
    key: '4za',
    label: '4ZA',
  },
  {
    key: 'a2z',
    label: 'A2z',
  },
  {
    key: 'abus',
    label: 'Abus',
  },
  {
    key: 'absoluteblack',
    label: 'absoluteBLACK',
  },
  {
    key: 'acros',
    label: 'Acros',
  },
  {
    key: 'addbike',
    label: 'Addbike',
  },
  {
    key: 'adidas',
    label: 'Adidas',
  },
  {
    key: 'adie',
    label: 'Adie',
  },
  {
    key: 'aerozine',
    label: 'Aerozine',
  },
  {
    key: 'aftershokz',
    label: 'Aftershokz',
  },
  {
    key: 'afton',
    label: 'Afton',
  },
  {
    key: 'airshot',
    label: 'Airshot',
  },
  {
    key: 'alain-michel',
    label: 'Alain Michel',
  },
  {
    key: 'alba-optics',
    label: 'Alba Optics',
  },
  {
    key: 'albion',
    label: 'Albion',
  },
  {
    key: 'ale',
    label: 'Ale',
  },
  {
    key: 'alk13',
    label: 'Alk13',
  },
  {
    key: 'all-mountain style',
    label: 'All Mountain Style',
  },
  {
    key: 'alligator',
    label: 'Alligator',
  },
  {
    key: 'alpenheat',
    label: 'Alpenheat',
  },
  {
    key: 'alpha',
    label: 'Alpha',
  },
  {
    key: 'alpinestars',
    label: 'Alpinestars',
  },
  {
    key: 'altura',
    label: 'Altura',
  },
  {
    key: 'animal-bikes',
    label: 'Animal Bikes',
  },
  {
    key: 'answer-bmx',
    label: 'Answer Bmx',
  },
  {
    key: 'answer-products',
    label: 'Answer Products',
  },
  {
    key: 'apidura',
    label: 'Apidura',
  },
  {
    key: 'apurna',
    label: 'Apurna',
  },
  {
    key: 'aqua2go',
    label: 'Aqua2go',
  },
  {
    key: 'argon-18',
    label: 'Argon 18',
  },
  {
    key: 'ari',
    label: 'Ari',
  },
  {
    key: 'arisun',
    label: 'Arisun',
  },
  {
    key: 'ashima',
    label: 'Ashima',
  },
  {
    key: 'aso',
    label: 'Aso',
  },
  {
    key: 'ass-savers',
    label: 'Ass Savers',
  },
  {
    key: 'assos',
    label: 'Assos',
  },
  {
    key: 'asterion',
    label: 'Asterion',
  },
  {
    key: 'attaquer',
    label: 'Attaquer',
  },
  {
    key: 'autonomy',
    label: 'Autonomy',
  },
  {
    key: 'avian-bmx',
    label: 'Avian Bmx',
  },
  {
    key: 'avid',
    label: 'Avid',
  },
  {
    key: 'avs',
    label: 'Avs',
  },
  {
    key: 'babboe',
    label: 'Babboe',
  },
  {
    key: 'baouw!',
    label: 'Baouw!',
  },
  {
    key: 'baramind',
    label: 'Baramind',
  },
  {
    key: 'basil',
    label: 'Basil',
  },
  {
    key: 'basso',
    label: 'Basso',
  },
  {
    key: 'bbb',
    label: 'Bbb',
  },
  {
    key: 'bell',
    label: 'Bell',
  },
  {
    key: 'bergamont',
    label: 'Bergamont',
  },
  {
    key: 'bern',
    label: 'Bern',
  },
  {
    key: 'bh',
    label: 'Bh',
  },
  {
    key: 'bianchi',
    label: 'Bianchi',
  },
  {
    key: 'bicisupport',
    label: 'Bicisupport',
  },
  {
    key: 'bike-7',
    label: 'Bike 7',
  },
  {
    key: 'bike-box-alan',
    label: 'Bike Box Alan',
  },
  {
    key: 'bike-original',
    label: 'Bike Original',
  },
  {
    key: 'bikeshield',
    label: 'Bikeshield',
  },
  {
    key: 'bikeyoke',
    label: 'Bikeyoke',
  },
  {
    key: 'biologic',
    label: 'Biologic',
  },
  {
    key: 'bkool',
    label: 'Bkool',
  },
  {
    key: 'blb',
    label: 'BLB',
  },
  {
    key: 'black-bearing',
    label: 'Black Bearing',
  },
  {
    key: 'blackburn',
    label: 'Blackburn',
  },
  {
    key: 'bluegrass',
    label: 'Bluegrass',
  },
  {
    key: 'black-sheep-cycling',
    label: 'Black Sheep Cycling',
  },
  {
    key: 'bluetens',
    label: 'Bluetens',
  },
  {
    key: 'bmc',
    label: 'Bmc',
  },
  {
    key: 'bob',
    label: 'Bob',
  },
  {
    key: 'bobike',
    label: 'Bobike',
  },
  {
    key: 'boeshield-t9',
    label: 'Boeshield T9',
  },
  {
    key: 'bolle',
    label: 'Bolle',
  },
  {
    key: 'bombshell',
    label: 'Bombshell',
  },
  {
    key: 'bombtrack',
    label: 'Bombtrack',
  },
  {
    key: 'bontrager',
    label: 'Bontrager',
  },
  {
    key: 'bonza-bike-kit',
    label: 'Bonza Bike Kit',
  },
  {
    key: 'bos',
    label: 'Bos',
  },
  {
    key: 'bosch',
    label: 'Bosch',
  },
  {
    key: 'bouwmeester',
    label: 'Bouwmeester',
  },
  {
    key: 'box-components',
    label: 'Box Components',
  },
  {
    key: 'brake-authority',
    label: 'Brake Authority',
  },
  {
    key: 'braking',
    label: 'Braking',
  },
  {
    key: 'breezer',
    label: 'Breezer',
  },
  {
    key: 'brompton',
    label: 'Brompton',
  },
  {
    key: 'brooks',
    label: 'Brooks',
  },
  {
    key: 'bryton',
    label: 'Bryton',
  },
  {
    key: 'bsd',
    label: 'Bsd',
  },
  {
    key: 'btwin',
    label: 'Btwin',
  },
  {
    key: 'buds',
    label: 'Buds',
  },
  {
    key: 'buff',
    label: 'Buff',
  },
  {
    key: 'burgtec',
    label: 'Burgtec',
  },
  {
    key: 'bv-sport',
    label: 'Bv Sport',
  },
  {
    key: 'cadex',
    label: 'Cadex',
  },
  {
    key: 'cafe-du-cycliste',
    label: 'Cafe du Cycliste',
  },
  {
    key: 'camelbak',
    label: 'Camelbak',
  },
  {
    key: 'campagnolo',
    label: 'Campagnolo',
  },
  {
    key: 'cane-creek',
    label: 'Cane Creek',
  },
  {
    key: 'cannondale',
    label: 'Cannondale',
  },
  {
    key: 'canyon',
    label: 'Canyon',
  },
  {
    key: 'casco',
    label: 'Casco',
  },
  {
    key: 'castelli',
    label: 'Castelli',
  },
  {
    key: 'cateye',
    label: 'Cateye',
  },
  {
    key: 'catlike',
    label: 'Catlike',
  },
  {
    key: 'cbt-italia',
    label: 'Cbt Italia',
  },
  {
    key: 'ceramicspeed',
    label: 'Ceramicspeed',
  },
  {
    key: 'cervelo',
    label: 'Cervelo',
  },
  {
    key: 'challenge',
    label: 'Challenge',
  },
  {
    key: 'chase-bicycles',
    label: 'Chase Bicycles',
  },
  {
    key: 'chris-king',
    label: 'Chris King',
  },
  {
    key: 'chromag',
    label: 'Chromag',
  },
  {
    key: 'ciari',
    label: 'Ciari',
  },
  {
    key: 'cinelli',
    label: 'Cinelli',
  },
  {
    key: 'clarks',
    label: 'Clarks',
  },
  {
    key: 'clearprotect',
    label: 'Clearprotect',
  },
  {
    key: 'clif-bar',
    label: 'Clif Bar',
  },
  {
    key: 'close-the gap',
    label: 'Close The Gap',
  },
  {
    key: 'colnago',
    label: 'Colnago',
  },
  {
    key: 'columbus',
    label: 'Columbus',
  },
  {
    key: 'commencal',
    label: 'Commencal',
  },
  {
    key: 'compex',
    label: 'Compex',
  },
  {
    key: 'condor',
    label: 'Condor',
  },
  {
    key: 'contec',
    label: 'Contec',
  },
  {
    key: 'continental',
    label: 'Continental',
  },
  {
    key: 'conway',
    label: 'Conway',
  },
  {
    key: 'corima',
    label: 'Corima',
  },
  {
    key: 'cosmo',
    label: 'Cosmo',
  },
  {
    key: 'craft',
    label: 'Craft',
  },
  {
    key: 'crankbrothers',
    label: 'Crankbrothers',
  },
  {
    key: 'creme',
    label: 'Creme',
  },
  {
    key: 'croozer',
    label: 'Croozer',
  },
  {
    key: 'crosscall',
    label: 'Crosscall',
  },
  {
    key: 'crud',
    label: 'Crud',
  },
  {
    key: 'crupi',
    label: 'Crupi',
  },
  {
    key: 'crushcore',
    label: 'Crushcore',
  },
  {
    key: 'cube',
    label: 'Cube',
  },
  {
    key: 'cult',
    label: 'Cult',
  },
  {
    key: 'cycl',
    label: 'Cycl',
  },
  {
    key: 'cycleops',
    label: 'Cycleops',
  },
  {
    key: 'cyclingceramic',
    label: 'Cyclingceramic',
  },
  {
    key: 'cycliq',
    label: 'Cycliq',
  },
  {
    key: 'dahon',
    label: 'Dahon',
  },
  {
    key: 'dainese',
    label: 'Dainese',
  },
  {
    key: 'dakine',
    label: 'Dakine',
  },
  {
    key: 'dare-2b',
    label: 'Dare 2b',
  },
  {
    key: 'dartmoor',
    label: 'Dartmoor',
  },
  {
    key: 'deaneasy',
    label: 'Deaneasy',
  },
  {
    key: 'deda',
    label: 'Deda',
  },
  {
    key: 'dedacciai',
    label: 'Dedacciai',
  },
  {
    key: 'demolition',
    label: 'Demolition',
  },
  {
    key: 'deuter',
    label: 'Deuter',
  },
  {
    key: 'dexshell',
    label: 'Dexshell',
  },
  {
    key: 'diamant',
    label: 'Diamant',
  },
  {
    key: 'dji',
    label: 'Dji',
  },
  {
    key: 'dk-bicycles',
    label: 'Dk Bicycles',
  },
  {
    key: 'dm3',
    label: 'Dm3',
  },
  {
    key: 'dmr',
    label: 'Dmr',
  },
  {
    key: 'dmt',
    label: 'Dmt',
  },
  {
    key: 'dt-swiss',
    label: 'Dt Swiss',
  },
  {
    key: 'dugast',
    label: 'Dugast',
  },
  {
    key: 'duke',
    label: 'Duke',
  },
  {
    key: 'dvo',
    label: 'Dvo',
  },
  {
    key: 'e-bike-manufaktur',
    label: 'E-Bike Manufaktur',
  },
  {
    key: 'e-thirteen',
    label: 'E-Thirteen',
  },
  {
    key: 'early-rider',
    label: 'Early Rider',
  },
  {
    key: 'easton',
    label: 'Easton',
  },
  {
    key: 'easy-in',
    label: 'Easy In',
  },
  {
    key: 'easy-tubeless',
    label: 'Easy Tubeless',
  },
  {
    key: 'eclat',
    label: 'Eclat',
  },
  {
    key: 'eddy-merckx',
    label: 'Eddy Merckx',
  },
  {
    key: 'eecycleworks',
    label: 'Eecycleworks',
  },
  {
    key: 'effetto-mariposa',
    label: 'Effetto Mariposa',
  },
  {
    key: 'ekoi',
    label: 'Ekoi',
  },
  {
    key: 'electra',
    label: 'Electra',
  },
  {
    key: 'elevn-technologies',
    label: 'Elevn Technologies',
  },
  {
    key: 'elite',
    label: 'Elite',
  },
  {
    key: 'elvedes',
    label: 'Elvedes',
  },
  {
    key: 'endura',
    label: 'Endura',
  },
  {
    key: 'enduro-bearing',
    label: 'Enduro Bearing',
  },
  {
    key: 'enve',
    label: 'Enve',
  },
  {
    key: 'ergon',
    label: 'Ergon',
  },
  {
    key: 'ergotec',
    label: 'Ergotec',
  },
  {
    key: 'esi',
    label: 'Esi',
  },
  {
    key: 'etnies',
    label: 'Etnies',
  },
  {
    key: 'etxeondo',
    label: 'Etxeondo',
  },
  {
    key: 'evil',
    label: 'Evil',
  },
  {
    key: 'evoc',
    label: 'EVOC',
  },
  {
    key: 'exaform',
    label: 'Exaform',
  },
  {
    key: 'excess',
    label: 'Excess',
  },
  {
    key: 'exs',
    label: 'Exs',
  },
  {
    key: 'fabric',
    label: 'Fabric',
  },
  {
    key: 'fahrer',
    label: 'Fahrer',
  },
  {
    key: 'fast-suspension',
    label: 'Fast Suspension',
  },
  {
    key: 'favero-electronics',
    label: 'Favero Electronics',
  },
  {
    key: 'federal-bikes',
    label: 'Federal Bikes',
  },
  {
    key: 'feedback-sports',
    label: 'Feedback Sports',
  },
  {
    key: 'felt',
    label: 'Felt',
  },
  {
    key: 'ferei',
    label: 'Ferei',
  },
  {
    key: 'ferrus',
    label: 'Ferrus',
  },
  {
    key: 'fidlock',
    label: 'Fidlock',
  },
  {
    key: 'finish-line',
    label: 'Finish Line',
  },
  {
    key: 'first',
    label: 'First',
  },
  {
    key: 'fitbit',
    label: 'Fitbit',
  },
  {
    key: 'five-ten',
    label: 'Five Ten',
  },
  {
    key: 'fixie-inc.',
    label: 'Fixie Inc.',
  },
  {
    key: 'fizik',
    label: 'Fizik',
  },
  {
    key: 'flr',
    label: 'Flr',
  },
  {
    key: 'fly-racing',
    label: 'Fly Racing',
  },
  {
    key: 'fmb',
    label: 'Fmb',
  },
  {
    key: 'focus',
    label: 'Focus',
  },
  {
    key: 'formula',
    label: 'Formula',
  },
  {
    key: 'forward',
    label: 'Forward',
  },
  {
    key: 'foss',
    label: 'Foss',
  },
  {
    key: 'fox',
    label: 'Fox',
  },
  {
    key: 'fox-racing shox',
    label: 'Fox Racing Shox',
  },
  {
    key: 'foxshox',
    label: 'Foxshox',
  },
  {
    key: 'frog-bikes',
    label: 'Frog Bikes',
  },
  {
    key: 'fsa',
    label: 'Fsa',
  },
  {
    key: 'fuji',
    label: 'Fuji',
  },
  {
    key: 'fulcrum',
    label: 'Fulcrum',
  },
  {
    key: 'funn',
    label: 'Funn',
  },
  {
    key: 'fuse',
    label: 'Fuse',
  },
  {
    key: 'g-form',
    label: 'G-Form',
  },
  {
    key: 'gaerne',
    label: 'Gaerne',
  },
  {
    key: 'galfer',
    label: 'Galfer',
  },
  {
    key: 'gamut',
    label: 'Gamut',
  },
  {
    key: 'garmin',
    label: 'Garmin',
  },
  {
    key: 'gazelle',
    label: 'Gazelle',
  },
  {
    key: 'genesis',
    label: 'Genesis',
  },
  {
    key: 'genuine-innovations',
    label: 'Genuine Innovations',
  },
  {
    key: 'ghost',
    label: 'Ghost',
  },
  {
    key: 'giant',
    label: 'Giant',
  },
  {
    key: 'giro',
    label: 'Giro',
  },
  {
    key: 'gitane',
    label: 'Gitane',
  },
  {
    key: 'globber',
    label: 'Globber',
  },
  {
    key: 'goodyear',
    label: 'Goodyear',
  },
  {
    key: 'gopro',
    label: 'Gopro',
  },
  {
    key: 'gore-wear',
    label: 'Gore Wear',
  },
  {
    key: 'granite-design',
    label: 'Granite Design',
  },
  {
    key: 'gripgrab',
    label: 'Gripgrab',
  },
  {
    key: 'gs27',
    label: 'Gs27',
  },
  {
    key: 'gt-bicycles',
    label: 'Gt Bicycles',
  },
  {
    key: 'gusset',
    label: 'Gusset',
  },
  {
    key: 'haibike',
    label: 'Haibike',
  },
  {
    key: 'halo',
    label: 'Halo',
  },
  {
    key: 'hamax',
    label: 'Hamax',
  },
  {
    key: 'hayes',
    label: 'Hayes',
  },
  {
    key: 'high5',
    label: 'High5',
  },
  {
    key: 'hiplok',
    label: 'Hiplok',
  },
  {
    key: 'hope',
    label: 'Hope',
  },
  {
    key: 'hornit',
    label: 'Hornit',
  },
  {
    key: 'hovding',
    label: 'Hovding',
  },
  {
    key: 'hpa',
    label: 'Hpa',
  },
  {
    key: 'ht-components',
    label: 'Ht Components',
  },
  {
    key: 'huck-norris',
    label: 'Huck Norris',
  },
  {
    key: 'huub',
    label: 'HUUB',
  },
  {
    key: 'huret',
    label: 'Huret',
  },
  {
    key: 'hutchinson',
    label: 'Hutchinson',
  },
  {
    key: 'hxr-components',
    label: 'Hxr Components',
  },
  {
    key: 'hydrapak',
    label: 'Hydrapak',
  },
  {
    key: 'ice',
    label: 'Ice',
  },
  {
    key: 'ichu',
    label: 'Ichu',
  },
  {
    key: 'ikon',
    label: 'Ikon',
  },
  {
    key: 'insight',
    label: 'Insight',
  },
  {
    key: 'inspyre',
    label: 'Inspyre',
  },
  {
    key: 'intense',
    label: 'Intense',
  },
  {
    key: 'ion',
    label: 'Ion',
  },
  {
    key: 'ism',
    label: 'Ism',
  },
  {
    key: 'isostar',
    label: 'Isostar',
  },
  {
    key: 'itm',
    label: 'Itm',
  },
  {
    key: 'ixs',
    label: 'Ixs',
  },
  {
    key: 'jagwire',
    label: 'Jagwire',
  },
  {
    key: 'jbl',
    label: 'Jbl',
  },
  {
    key: 'jd-bug',
    label: 'Jd Bug',
  },
  {
    key: 'joystick',
    label: 'Joystick',
  },
  {
    key: 'juice-lubes',
    label: 'Juice Lubes',
  },
  {
    key: 'julbo',
    label: 'Julbo',
  },
  {
    key: 'k-edge',
    label: 'K-Edge',
  },
  {
    key: 'kali',
    label: 'Kali',
  },
  {
    key: 'kalkhoff',
    label: 'Kalkhoff',
  },
  {
    key: 'karcher',
    label: 'Karcher',
  },
  {
    key: 'kask',
    label: 'Kask',
  },
  {
    key: 'katusha',
    label: 'Katusha',
  },
  {
    key: 'kcnc',
    label: 'Kcnc',
  },
  {
    key: 'kenny',
    label: 'Kenny',
  },
  {
    key: 'khe',
    label: 'Khe',
  },
  {
    key: 'kinetic',
    label: 'Kinetic',
  },
  {
    key: 'kingstar',
    label: 'Kingstar',
  },
  {
    key: 'kink-bmx',
    label: 'Kink Bmx',
  },
  {
    key: 'klickfix',
    label: 'Klickfix',
  },
  {
    key: 'kmc',
    label: 'Kmc',
  },
  {
    key: 'knog',
    label: 'Knog',
  },
  {
    key: 'kokua',
    label: 'Kokua',
  },
  {
    key: 'kona',
    label: 'Kona',
  },
  {
    key: 'kore',
    label: 'Kore',
  },
  {
    key: 'kross',
    label: 'Kross',
  },
  {
    key: 'kryptonite',
    label: 'Kryptonite',
  },
  {
    key: 'ks-kind shock',
    label: 'Ks Kind Shock',
  },
  {
    key: 'ktm',
    label: 'Ktm',
  },
  {
    key: 'kuota',
    label: 'Kuota',
  },
  {
    key: 'laboratoire-bimont',
    label: 'Laboratoire Bimont',
  },
  {
    key: 'lake',
    label: 'Lake',
  },
  {
    key: 'la-passione',
    label: 'La Passione',
  },
  {
    key: 'lapierre',
    label: 'Lapierre',
  },
  {
    key: 'lauf',
    label: 'Lauf',
  },
  {
    key: 'lazer',
    label: 'Lazer',
  },
  {
    key: 'le-col',
    label: 'Le Col',
  },
  {
    key: 'le-coq sportif',
    label: 'Le Coq Sportif',
  },
  {
    key: 'leatt',
    label: 'Leatt',
  },
  {
    key: 'lezyne',
    label: 'Lezyne',
  },
  {
    key: 'lifeline',
    label: 'LifeLine',
  },
  {
    key: 'lifestraw',
    label: 'Lifestraw',
  },
  {
    key: 'lightskin',
    label: 'Lightskin',
  },
  {
    key: 'lightweight-meilenstein weiss',
    label: 'Lightweight Meilenstein Weiss',
  },
  {
    key: 'limar',
    label: 'Limar',
  },
  {
    key: 'liv',
    label: 'Liv',
  },
  {
    key: 'lizard-skins',
    label: 'Lizard Skins',
  },
  {
    key: 'loffler',
    label: 'Loffler',
  },
  {
    key: 'logo',
    label: 'Logo',
  },
  {
    key: 'look',
    label: 'Look',
  },
  {
    key: 'loose-riders',
    label: 'Loose Riders',
  },
  {
    key: 'louis-garneau vorttice',
    label: 'Louis Garneau Vorttice',
  },
  {
    key: 'maap',
    label: 'MAAP',
  },
  {
    key: 'mac-components',
    label: 'Mac Components',
  },
  {
    key: 'mac-ride',
    label: 'Mac Ride',
  },
  {
    key: 'magped',
    label: 'Magped',
  },
  {
    key: 'magura',
    label: 'Magura',
  },
  {
    key: 'maikun',
    label: 'Maikun',
  },
  {
    key: 'manitou',
    label: 'Manitou',
  },
  {
    key: 'marin-bikes',
    label: 'Marin Bikes',
  },
  {
    key: 'marsh-guard',
    label: 'Marsh Guard',
  },
  {
    key: 'marzocchi',
    label: 'Marzocchi',
  },
  {
    key: 'massi',
    label: 'Massi',
  },
  {
    key: 'maurten',
    label: 'Maurten',
  },
  {
    key: 'mavic',
    label: 'Mavic',
  },
  {
    key: 'maxalami',
    label: 'Maxalami',
  },
  {
    key: 'maxell',
    label: 'Maxell',
  },
  {
    key: 'maxxis',
    label: 'Maxxis',
  },
  {
    key: 'mb-wear',
    label: 'Mb Wear',
  },
  {
    key: 'mbk',
    label: 'Mbk',
  },
  {
    key: 'medicafarm',
    label: 'Medicafarm',
  },
  {
    key: 'melon-optics',
    label: 'Melon Optics',
  },
  {
    key: 'meltonic',
    label: 'Meltonic',
  },
  {
    key: 'merge-4',
    label: 'Merge 4',
  },
  {
    key: 'merida',
    label: 'Merida',
  },
  {
    key: 'met',
    label: 'MET',
  },
  {
    key: 'meybo',
    label: 'Meybo',
  },
  {
    key: 'mh-cover',
    label: 'Mh Cover',
  },
  {
    key: 'miche',
    label: 'Miche',
  },
  {
    key: 'michelin',
    label: 'Michelin',
  },
  {
    key: 'micro',
    label: 'Micro',
  },
  {
    key: 'milkit',
    label: 'Milkit',
  },
  {
    key: 'miranda',
    label: 'Miranda',
  },
  {
    key: 'mitas',
    label: 'Mitas',
  },
  {
    key: 'mobilis',
    label: 'Mobilis',
  },
  {
    key: 'mondraker',
    label: 'Mondraker',
  },
  {
    key: 'mongoose',
    label: 'Mongoose',
  },
  {
    key: "monkey's-sauce",
    label: "Monkey'S Sauce",
  },
  {
    key: 'morgaw',
    label: 'Morgaw',
  },
  {
    key: 'motion-ride',
    label: 'Motion Ride',
  },
  {
    key: 'moto',
    label: 'Moto',
  },
  {
    key: 'mottez',
    label: 'Mottez',
  },
  {
    key: 'moustache-bikes',
    label: 'Moustache Bikes',
  },
  {
    key: 'movea',
    label: 'Movea',
  },
  {
    key: 'mrp',
    label: 'Mrp',
  },
  {
    key: 'mrwolf',
    label: 'Mrwolf',
  },
  {
    key: 'msc-bikes',
    label: 'Msc Bikes',
  },
  {
    key: 'muc-off',
    label: 'Muc-Off',
  },
  {
    key: 'mucky-nutz',
    label: 'Mucky Nutz',
  },
  {
    key: 'mulebar',
    label: 'Mulebar',
  },
  {
    key: 'nakamura',
    label: 'Nakamura',
  },
  {
    key: 'nalini',
    label: 'Nalini',
  },
  {
    key: 'namedsport',
    label: 'Namedsport',
  },
  {
    key: 'neatt',
    label: 'Neatt',
  },
  {
    key: 'nike',
    label: 'Nike',
  },
  {
    key: 'nix',
    label: 'Nix',
  },
  {
    key: 'no-flats',
    label: 'No-Flats',
  },
  {
    key: 'norrona',
    label: 'Norrona',
  },
  {
    key: 'north-shore billet',
    label: 'North Shore Billet',
  },
  {
    key: 'northwave',
    label: 'Northwave',
  },
  {
    key: 'notubes',
    label: 'Notubes',
  },
  {
    key: 'novatec',
    label: 'Novatec',
  },
  {
    key: 'ns-bikes',
    label: 'Ns Bikes',
  },
  {
    key: 'nst',
    label: 'Nst',
  },
  {
    key: 'nukeproof',
    label: 'Nukeproof',
  },
  {
    key: 'nutrisens-sport',
    label: 'Nutrisens Sport',
  },
  {
    key: "o'neal",
    label: "O'Neal",
  },
  {
    key: 'o.symetric',
    label: 'O.Symetric',
  },
  {
    key: 'oakley',
    label: 'Oakley',
  },
  {
    key: 'odi',
    label: 'Odi',
  },
  {
    key: 'odlo',
    label: 'Odlo',
  },
  {
    key: 'odyssey',
    label: 'Odyssey',
  },
  {
    key: 'ohlins',
    label: 'Ohlins',
  },
  {
    key: 'one-up components',
    label: 'One Up Components',
  },
  {
    key: 'onoff',
    label: 'Onoff',
  },
  {
    key: 'onyx',
    label: 'Onyx',
  },
  {
    key: 'orange',
    label: 'Orange',
  },
  {
    key: 'orange-seal',
    label: 'Orange Seal',
  },
  {
    key: 'orbea',
    label: 'Orbea',
  },
  {
    key: 'ortler',
    label: 'Ortler',
  },
  {
    key: 'ortlieb',
    label: 'Ortlieb',
  },
  {
    key: 'osprey',
    label: 'Osprey',
  },
  {
    key: 'oury',
    label: 'Oury',
  },
  {
    key: 'overstim.s',
    label: 'Overstim.S',
  },
  {
    key: 'panaracer',
    label: 'Panaracer',
  },
  {
    key: 'panzer',
    label: 'Panzer',
  },
  {
    key: 'paravol',
    label: 'Paravol',
  },
  {
    key: 'park-tool',
    label: 'Park Tool',
  },
  {
    key: 'parts-8.3',
    label: 'Parts 8.3',
  },
  {
    key: 'pearl-izumi',
    label: 'Pearl Izumi',
  },
  {
    key: "peaty's",
    label: "Peaty'S",
  },
  {
    key: 'pedaled',
    label: 'Pedaled',
  },
  {
    key: 'peruzzo',
    label: 'Peruzzo',
  },
  {
    key: 'peugeot',
    label: 'Peugeot',
  },
  {
    key: 'pinarello',
    label: 'Pinarello',
  },
  {
    key: 'pinhead',
    label: 'Pinhead',
  },
  {
    key: 'pirelli',
    label: 'Pirelli',
  },
  {
    key: 'pivot',
    label: 'Pivot',
  },
  {
    key: 'poc',
    label: 'Poc',
  },
  {
    key: 'point65n',
    label: 'Point65n',
  },
  {
    key: 'polar',
    label: 'Polar',
  },
  {
    key: 'polisport',
    label: 'Polisport',
  },
  {
    key: 'polygon',
    label: 'Polygon',
  },
  {
    key: 'position-one',
    label: 'Position One',
  },
  {
    key: 'powerbar',
    label: 'Powerbar',
  },
  {
    key: 'praxis',
    label: 'Praxis',
  },
  {
    key: 'pride',
    label: 'Pride',
  },
  {
    key: 'prism',
    label: 'Prism',
  },
  {
    key: 'pro',
    label: 'Pro',
  },
  {
    key: 'probikeshop',
    label: 'Probikeshop',
  },
  {
    key: 'production-privee',
    label: 'Production Privee',
  },
  {
    key: 'profile-design',
    label: 'Profile Design',
  },
  {
    key: 'prologo',
    label: 'Prologo',
  },
  {
    key: 'promax',
    label: 'Promax',
  },
  {
    key: 'protaper',
    label: 'Protaper',
  },
  {
    key: 'puky',
    label: 'Puky',
  },
  {
    key: 'pure-fix cycles',
    label: 'Pure Fix Cycles',
  },
  {
    key: 'quarq',
    label: 'Quarq',
  },
  {
    key: 'r.s.p.',
    label: 'R.S.P.',
  },
  {
    key: 'rabeneick',
    label: 'Rabeneick',
  },
  {
    key: 'race-face',
    label: 'Race Face',
  },
  {
    key: 'raceone',
    label: 'Raceone',
  },
  {
    key: 'racer',
    label: 'Racer',
  },
  {
    key: 'racktime',
    label: 'Racktime',
  },
  {
    key: 'rad',
    label: 'Rad',
  },
  {
    key: 'radio-bikes',
    label: 'Radio Bikes',
  },
  {
    key: "rafa'l",
    label: "Rafa'L",
  },
  {
    key: 'raleigh',
    label: 'Raleigh',
  },
  {
    key: 'rant',
    label: 'Rant',
  },
  {
    key: 'rapha',
    label: 'Rapha',
  },
  {
    key: 'rebel-kidz',
    label: 'Rebel Kidz',
  },
  {
    key: 'red-bull spect eyewear',
    label: 'Red Bull Spect Eyewear',
  },
  {
    key: 'red-cycling products',
    label: 'Red Cycling Products',
  },
  {
    key: 'rennen',
    label: 'Rennen',
  },
  {
    key: 'renthal',
    label: 'Renthal',
  },
  {
    key: 'reparto-corse',
    label: 'Reparto Corse',
  },
  {
    key: 'repente',
    label: 'Repente',
  },
  {
    key: 'respro',
    label: 'Respro',
  },
  {
    key: 'restrap',
    label: 'Restrap',
  },
  {
    key: 'reverse-components',
    label: 'Reverse Components',
  },
  {
    key: 'revelo',
    label: 'REVELO',
  },
  {
    key: 'reynolds',
    label: 'Reynolds',
  },
  {
    key: 'rhythm',
    label: 'Rhythm',
  },
  {
    key: 'ride-alpha',
    label: 'Ride Alpha',
  },
  {
    key: 'ride-concepts',
    label: 'Ride Concepts',
  },
  {
    key: 'ridley',
    label: 'Ridley',
  },
  {
    key: 'ritchey',
    label: 'Ritchey',
  },
  {
    key: 'rocday',
    label: 'Rocday',
  },
  {
    key: 'rockbros',
    label: 'Rockbros',
  },
  {
    key: 'rockrider',
    label: 'Rockrider',
  },
  {
    key: 'rockshox',
    label: 'Rockshox',
  },
  {
    key: 'rocky-mountain',
    label: 'Rocky Mountain',
  },
  {
    key: 'rondo',
    label: 'Rondo',
  },
  {
    key: 'rose-bikes',
    label: 'Rose Bikes',
  },
  {
    key: 'roto',
    label: 'Roto',
  },
  {
    key: 'rotor',
    label: 'Rotor',
  },
  {
    key: 'roval',
    label: 'Roval',
  },
  {
    key: 'royalty',
    label: 'Royalty',
  },
  {
    key: 'rudy-project',
    label: 'Rudy Project',
  },
  {
    key: "s'cool",
    label: "S'Cool",
  },
  {
    key: 'sa-racing springs',
    label: 'Sa Racing Springs',
  },
  {
    key: 'sahmurai-sword',
    label: 'Sahmurai Sword',
  },
  {
    key: 'salt',
    label: 'Salt',
  },
  {
    key: 'saltplus',
    label: 'Saltplus',
  },
  {
    key: 'sammie',
    label: 'Sammie',
  },
  {
    key: 'santa-cruz',
    label: 'Santa Cruz',
  },
  {
    key: 'santini-sms',
    label: 'Santini Sms',
  },
  {
    key: 'saris',
    label: 'Saris',
  },
  {
    key: 'sb3',
    label: 'Sb3',
  },
  {
    key: 'schwalbe',
    label: 'Schwalbe',
  },
  {
    key: 'scicon',
    label: 'Scicon',
  },
  {
    key: 'scott',
    label: 'Scott',
  },
  {
    key: 'scrapper',
    label: 'Scrapper',
  },
  {
    key: 'sdg',
    label: 'Sdg',
  },
  {
    key: 'sea-sucker',
    label: 'Sea Sucker',
  },
  {
    key: 'selle-italia',
    label: 'Selle Italia',
  },
  {
    key: 'selle-royal',
    label: 'Selle Royal',
  },
  {
    key: 'selle-san marco',
    label: 'Selle San Marco',
  },
  {
    key: 'sendhit',
    label: 'Sendhit',
  },
  {
    key: 'serious',
    label: 'Serious',
  },
  {
    key: 'seven',
    label: 'Seven',
  },
  {
    key: 'shapeheart',
    label: 'Shapeheart',
  },
  {
    key: 'shimano',
    label: 'Shimano',
  },
  {
    key: 'shokbox',
    label: 'Shokbox',
  },
  {
    key: 'shot',
    label: 'Shot',
  },
  {
    key: 'shotgun',
    label: 'Shotgun',
  },
  {
    key: 'sidi',
    label: 'Sidi',
  },
  {
    key: 'sigma',
    label: 'Sigma',
  },
  {
    key: 'sigma-sports',
    label: 'Sigma Sports',
  },
  {
    key: 'silkolene',
    label: 'Silkolene',
  },
  {
    key: 'simplex',
    label: 'Simplex',
  },
  {
    key: 'sinz',
    label: 'Sinz',
  },
  {
    key: 'sis',
    label: 'Sis',
  },
  {
    key: 'sixpack',
    label: 'Sixpack',
  },
  {
    key: 'sixs',
    label: 'Sixs',
  },
  {
    key: 'sixsixone-661',
    label: 'Sixsixone 661',
  },
  {
    key: 'skean',
    label: 'Skean',
  },
  {
    key: 'skf',
    label: 'Skf',
  },
  {
    key: 'skins',
    label: 'Skins',
  },
  {
    key: 'sks-germany',
    label: 'Sks Germany',
  },
  {
    key: 'slicy',
    label: 'Slicy',
  },
  {
    key: 'smanie',
    label: 'Smanie',
  },
  {
    key: 'smith',
    label: 'Smith',
  },
  {
    key: 'smp',
    label: 'Smp',
  },
  {
    key: 'society',
    label: 'Society',
  },
  {
    key: 'sock-guy',
    label: 'Sock Guy',
  },
  {
    key: 'solestar',
    label: 'Solestar',
  },
  {
    key: 'spank',
    label: 'Spank',
  },
  {
    key: 'spanninga',
    label: 'Spanninga',
  },
  {
    key: 'spatzwear',
    label: 'Spatzwear',
  },
  {
    key: 'specialites-ta',
    label: 'Specialites Ta',
  },
  {
    key: 'specialized',
    label: 'Specialized',
  },
  {
    key: 'speed-co bicycles',
    label: 'Speed Co Bicycles',
  },
  {
    key: 'speedplay',
    label: 'Speedplay',
  },
  {
    key: 'spirigrips',
    label: 'Spirigrips',
  },
  {
    key: 'spiuk',
    label: 'Spiuk',
  },
  {
    key: 'sportful',
    label: 'Sportful',
  },
  {
    key: 'sprintech',
    label: 'Sprintech',
  },
  {
    key: 'spy',
    label: 'Spy',
  },
  {
    key: 'squirt-lube',
    label: 'Squirt Lube',
  },
  {
    key: 'sr-suntour',
    label: 'Sr Suntour',
  },
  {
    key: 'sram',
    label: 'Sram',
  },
  {
    key: 'srm',
    label: 'Srm',
  },
  {
    key: 'stages-cycling',
    label: 'Stages Cycling',
  },
  {
    key: "stay'activ",
    label: "Stay'Activ",
  },
  {
    key: 'stc-nutrition',
    label: 'Stc Nutrition',
  },
  {
    key: 'stronglight',
    label: 'Stronglight',
  },
  {
    key: 'subrosa',
    label: 'Subrosa',
  },
  {
    key: 'sun-ringlé',
    label: 'Sun Ringlé',
  },
  {
    key: 'sunday-bikes',
    label: 'Sunday Bikes',
  },
  {
    key: 'sunn',
    label: 'Sunn',
  },
  {
    key: 'sunnto',
    label: 'Sunnto',
  },
  {
    key: 'sunrace',
    label: 'Sunrace',
  },
  {
    key: 'suomy',
    label: 'Suomy',
  },
  {
    key: 'supacaz',
    label: 'Supacaz',
  },
  {
    key: 'super-b',
    label: 'Super B',
  },
  {
    key: 'supercross',
    label: 'Supercross',
  },
  {
    key: 'superfeet',
    label: 'Superfeet',
  },
  {
    key: 'supernova',
    label: 'Supernova',
  },
  {
    key: 'supertour',
    label: 'Supertour',
  },
  {
    key: 'suunto',
    label: 'Suunto',
  },
  {
    key: 'sweet-protection',
    label: 'Sweet Protection',
  },
  {
    key: 'swiss-stop',
    label: 'Swiss Stop',
  },
  {
    key: 'syncros',
    label: 'Syncros',
  },
  {
    key: 'syntace',
    label: 'Syntace',
  },
  {
    key: 'tacx',
    label: 'Tacx',
  },
  {
    key: 'tag-metals',
    label: 'Tag Metals',
  },
  {
    key: 'tangent',
    label: 'Tangent',
  },
  {
    key: 'tektro',
    label: 'Tektro',
  },
  {
    key: 'tern',
    label: 'Tern',
  },
  {
    key: 'the-beam',
    label: 'The Beam',
  },
  {
    key: 'the-shadow conspiracy',
    label: 'The Shadow Conspiracy',
  },
  {
    key: 'thermic',
    label: 'Thermic',
  },
  {
    key: 'thickslick',
    label: 'Thickslick',
  },
  {
    key: 'thomson',
    label: 'Thomson',
  },
  {
    key: 'thule',
    label: 'Thule',
  },
  {
    key: 'ti-springs.com',
    label: 'Ti-Springs.Com',
  },
  {
    key: 'tifosi',
    label: 'Tifosi',
  },
  {
    key: 'tigra-sport',
    label: 'Tigra Sport',
  },
  {
    key: 'time',
    label: 'Time',
  },
  {
    key: 'tioga',
    label: 'Tioga',
  },
  {
    key: 'tmr-designs',
    label: 'Tmr Designs',
  },
  {
    key: 'tnt-bicycles',
    label: 'Tnt Bicycles',
  },
  {
    key: 'topeak',
    label: 'Topeak',
  },
  {
    key: 'tornade',
    label: 'Tornade',
  },
  {
    key: 'torq',
    label: 'Torq',
  },
  {
    key: 'trail-gator',
    label: 'Trail Gator',
  },
  {
    key: 'transfil',
    label: 'Transfil',
  },
  {
    key: 'transition-bikes',
    label: 'Transition Bikes',
  },
  {
    key: 'trax',
    label: 'Trax',
  },
  {
    key: 'trek',
    label: 'Trek',
  },
  {
    key: 'triban',
    label: 'Triban',
  },
  {
    key: 'troy-lee designs',
    label: 'Troy Lee Designs',
  },
  {
    key: 'trp',
    label: 'Trp',
  },
  {
    key: 'truvativ',
    label: 'Truvativ',
  },
  {
    key: 'tsg',
    label: 'Tsg',
  },
  {
    key: 'tubolito',
    label: 'Tubolito',
  },
  {
    key: 'tubus',
    label: 'Tubus',
  },
  {
    key: 'tucano-urbano',
    label: 'Tucano Urbano',
  },
  {
    key: 'tufo',
    label: 'Tufo',
  },
  {
    key: 'tune',
    label: 'Tune',
  },
  {
    key: 'ulteam-race',
    label: 'Ulteam Race',
  },
  {
    key: 'unior',
    label: 'Unior',
  },
  {
    key: 'universal-colours',
    label: 'Universal Colours',
  },
  {
    key: 'urge',
    label: 'Urge',
  },
  {
    key: 'uswe',
    label: 'Uswe',
  },
  {
    key: 'uvex',
    label: 'Uvex',
  },
  {
    key: 'uyn',
    label: 'Uyn',
  },
  {
    key: 'vaast',
    label: 'Vaast',
  },
  {
    key: 'van-rysel',
    label: 'Van Rysel',
  },
  {
    key: 'vans',
    label: 'Vans',
  },
  {
    key: 'var',
    label: 'Var',
  },
  {
    key: 'vaude',
    label: 'Vaude',
  },
  {
    key: 'vdo',
    label: 'Vdo',
  },
  {
    key: 'vee-tire',
    label: 'Vee Tire',
  },
  {
    key: 'velco',
    label: 'Velco',
  },
  {
    key: 'veloflex',
    label: 'Veloflex',
  },
  {
    key: 'velotoze',
    label: 'Velotoze',
  },
  {
    key: 'velox',
    label: 'Velox',
  },
  {
    key: 'vermont',
    label: 'Vermont',
  },
  {
    key: 'viper',
    label: 'Viper',
  },
  {
    key: 'vision',
    label: 'Vision',
  },
  {
    key: 'vittoria',
    label: 'Vittoria',
  },
  {
    key: 'vitus',
    label: 'Vitus',
  },
  {
    key: 'vojo',
    label: 'Vojo',
  },
  {
    key: 'votec',
    label: 'Votec',
  },
  {
    key: 'vredestein',
    label: 'Vredestein',
  },
  {
    key: 'vulcanet',
    label: 'Vulcanet',
  },
  {
    key: 'wahoo',
    label: 'Wahoo',
  },
  {
    key: 'wd-40',
    label: 'Wd-40',
  },
  {
    key: 'wellgo',
    label: 'Wellgo',
  },
  {
    key: 'wera',
    label: 'Wera',
  },
  {
    key: 'wethepeople',
    label: 'Wethepeople',
  },
  {
    key: 'wheels-mfg',
    label: 'Wheels Mfg',
  },
  {
    key: 'wilier-triestina',
    label: 'Wilier Triestina',
  },
  {
    key: 'winora',
    label: 'Winora',
  },
  {
    key: 'wippermann',
    label: 'Wippermann',
  },
  {
    key: 'wolftooth',
    label: 'Wolftooth',
  },
  {
    key: 'wowow',
    label: 'Wowow',
  },
  {
    key: 'wtb',
    label: 'Wtb',
  },
  {
    key: 'x-bionic',
    label: 'X Bionic',
  },
  {
    key: 'x-fusion',
    label: 'X Fusion',
  },
  {
    key: 'x-peria',
    label: 'X Peria',
  },
  {
    key: 'xforce',
    label: 'Xforce',
  },
  {
    key: 'xlab',
    label: 'Xlab',
  },
  {
    key: 'xlc',
    label: 'Xlc',
  },
  {
    key: 'yaban',
    label: 'Yaban',
  },
  {
    key: 'yamaha',
    label: 'Yamaha',
  },
  {
    key: 'yess',
    label: 'Yess',
  },
  {
    key: 'yeti',
    label: 'Yeti',
  },
  {
    key: 'yt-industries',
    label: 'Yt Industries',
  },
  {
    key: 'ytwo',
    label: 'Ytwo',
  },
  {
    key: 'zefal',
    label: 'Zefal',
  },
  {
    key: 'zelvy',
    label: 'Zelvy',
  },
  {
    key: 'zero-rh+',
    label: 'Zero Rh+',
  },
  {
    key: 'zipp',
    label: 'Zipp',
  },
  {
    key: 'zycle',
    label: 'Zycle',
  },
];
