import React from 'react';
import { string } from 'prop-types';

const IconProfile = props => {
  const { className } = props;
  return (
    <svg
      className={className}
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      viewBox="0 0 404.347 404.347"
    >
      <path d="M202.178 209.972c23.96 0 47.357-14.538 65.881-40.936 16.348-23.296 26.107-52.099 26.107-77.048C294.167 41.266 252.9 0 202.178 0S110.19 41.266 110.19 91.988c0 24.949 9.759 53.752 26.107 77.048 18.524 26.398 41.921 40.936 65.881 40.936zm0-194.972c42.451 0 76.988 34.537 76.988 76.988 0 21.655-8.961 47.876-23.385 68.432-15.409 21.958-34.946 34.552-53.603 34.552-18.657 0-38.194-12.594-53.603-34.552-14.424-20.556-23.385-46.777-23.385-68.432C125.19 49.537 159.727 15 202.178 15z" />
      <path d="M336.974 251.115c-16.208-25.154-39.025-45.233-65.987-58.068a7.5 7.5 0 00-8.52 1.461c-18.071 18.021-38.92 27.545-60.293 27.545-21.376 0-42.222-9.524-60.282-27.544a7.498 7.498 0 00-8.521-1.463c-26.962 12.835-49.782 32.915-65.992 58.068-16.651 25.837-25.452 55.813-25.452 86.686v.13c0 2.116.894 4.134 2.461 5.556 43.276 39.247 99.312 60.861 157.785 60.861s114.508-21.614 157.785-60.861a7.502 7.502 0 002.461-5.556v-.13c.002-30.873-8.797-60.849-25.445-86.685zm-134.8 138.233c-53.621 0-105.055-19.417-145.212-54.743.583-26.855 8.514-52.847 23.026-75.365 13.767-21.361 32.788-38.686 55.227-50.357 20.045 18.453 43.094 28.17 66.959 28.17 23.861 0 46.914-9.719 66.969-28.171 22.437 11.67 41.458 28.996 55.222 50.357 14.509 22.517 22.438 48.508 23.021 75.365-40.157 35.326-91.591 54.744-145.212 54.744z" />
    </svg>
  );
};

IconProfile.propTypes = {
  className: string,
};

export default IconProfile;
