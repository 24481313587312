import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { arrayOf, array, bool, func, node, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import {
  ModalInMobile,
  Button,
  SecondaryButton,
  ExpandableText,
  ExternalLink,
} from '../../components';
import { BookingDatesForm, DiscountForm } from '../../forms';

import css from './BookingPanel.module.css';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const BookingPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    submitButtonContainerClassName,
    modalContainerClassName,
    bookingDatesClassName,
    currentUser,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    subTitle,
    authorDisplayName,
    onManageDisableScrolling,
    timeSlots,
    fetchTimeSlotsError,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    description,
    showContactUser,
    onContactUser,
    removeDescription,
    allowBookings,
    handleDiscountChange,
    discountCode,
    discountCodes,
    fetchDiscountCodesInProgress,
    fetchDiscountCodesError,
  } = props;

  const price = listing.attributes.price;

  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingDatesForm = hasListingState && !isClosed;
  const showClosedListingHelpText = listing.id && isClosed;
  const showDiscountForm = currentUser && !isOwnListing;
  const currentUserId = currentUser?.id?.uuid;

  const { formattedPrice, priceTitle } = priceData(price, intl);
  const isBook = !!parse(location.search).book;

  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'BookingPanel.subTitleClosedListing' })
    : null;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'BookingPanel.perNight'
    : isDaily
    ? 'BookingPanel.perDay'
    : 'BookingPanel.perUnit';

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.bookingTitle);

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={classNames(css.modalContainer, modalContainerClassName)}
        id="BookingDatesFormInModal"
        isModalOpenOnMobile={isBook}
        onClose={() => closeBookModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
          <div className={css.author}>
            <FormattedMessage id="BookingPanel.hostedBy" values={{ name: authorDisplayName }} />
          </div>
        </div>

        <div className={css.bookingHeading}>
          <h2 className={titleClasses}>{title}</h2>
          <div className={css.subTitleAndReport}>
            {subTitleText ? <div className={css.bookingHelp}>{subTitleText}</div> : null}
            {subTitleText ? <span className={css.separator}>•</span> : null}
            <ExternalLink
              href={`mailto:hello@revelo.cc?subject=Report ${title}&body=I want to report listing with id: ${listing.id.uuid}`}
              className={css.smallContactLink}
            >
              <FormattedMessage id="ListingPage.reportListing" />
            </ExternalLink>
          </div>
        </div>

        {!removeDescription && description ? (
          <div className={css.descriptionContainer}>
            <ExpandableText className={css.descriptionText} text={description} />
          </div>
        ) : null}

        {allowBookings ? (
          <>
            {showDiscountForm && (
              <div className={css.discountFormContainer}>
                <DiscountForm
                  currentUserId={currentUserId}
                  onSubmit={handleDiscountChange}
                  discountCode={discountCode}
                  discountCodes={discountCodes}
                  discountInProgress={fetchDiscountCodesInProgress}
                  discountError={fetchDiscountCodesError}
                />
              </div>
            )}
            {showBookingDatesForm && (
              <BookingDatesForm
                className={css.bookingForm}
                formId="BookingPanel"
                submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
                submitButtonContainerClassName={submitButtonContainerClassName}
                bookingDatesClassName={bookingDatesClassName}
                unitType={unitType}
                onSubmit={onSubmit}
                price={price}
                listingId={listing.id}
                isOwnListing={isOwnListing}
                timeSlots={timeSlots}
                fetchTimeSlotsError={fetchTimeSlotsError}
                onFetchTransactionLineItems={onFetchTransactionLineItems}
                lineItems={lineItems}
                fetchLineItemsInProgress={fetchLineItemsInProgress}
                fetchLineItemsError={fetchLineItemsError}
                showContactUser={showContactUser}
                onContactUser={onContactUser}
              />
            )}
          </>
        ) : (
          <div className={css.bookingsNotAllowedContainer}>
            <p className={css.bookingsNotAllowedMessage}>
              <FormattedMessage id="ProductPanel.bookingsNotAllowed" />
            </p>
          </div>
        )}
      </ModalInMobile>
      <div className={css.openBookingForm}>
        <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div>
        </div>
        {allowBookings ? (
          showBookingDatesForm && (
            <div
              className={classNames(css.mobileButtonsWrapper, {
                [css.mobileButtonsWrapperGrid]: showContactUser,
              })}
            >
              {showContactUser ? (
                <SecondaryButton
                  className={css.contactButton}
                  type="submit"
                  onClick={onContactUser}
                >
                  <FormattedMessage id="BookingPanel.contactSeller" />
                </SecondaryButton>
              ) : null}
              <Button
                rootClassName={css.bookButton}
                onClick={() => openBookModal(isOwnListing, isClosed, history, location)}
              >
                <FormattedMessage id="BookingPanel.ctaButtonMessageMobile" />
              </Button>
            </div>
          )
        ) : isClosed ? (
          <div
            className={classNames(css.mobileButtonsWrapper, {
              [css.mobileButtonsWrapperGrid]: showContactUser,
            })}
          >
            {showContactUser ? (
              <SecondaryButton className={css.contactButton} type="submit" onClick={onContactUser}>
                <FormattedMessage id="BookingPanel.contactSeller" />
              </SecondaryButton>
            ) : null}
            <div className={css.closedListingButton}>
              <FormattedMessage id="BookingPanel.closedListingButtonText" />
            </div>
          </div>
        ) : (
          <div className={css.bookingsNotAllowedContainer}>
            <p className={css.bookingsNotAllowedMessage}>
              <FormattedMessage id="ProductPanel.bookingsNotAllowedMobile" />
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(BookingPanel);
