import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { Button } from '../../components';
import classNames from 'classnames';
import config from '../../config';

import css from './SectionStrava.module.css';

const SectionStrava = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  const { siteStravaPage } = config;
  const goToStrava = () => {
    if (typeof window !== 'undefined' || typeof window !== undefined) {
      window.open(siteStravaPage, '_blank');
    }
  };

  return (
    <div className={classes}>
      <div className={css.titleWrapper}>
        <h1 className={css.title}>
          <FormattedMessage id="SectionStrava.title" />
        </h1>
        <p className={css.subTitle}>
          <FormattedMessage id="SectionStrava.subTitle" />
        </p>
        <Button className={css.learnMoreButton} onClick={goToStrava}>
          <FormattedMessage id="SectionStrava.learnMore" />
        </Button>
      </div>
      <div
        className={css.stravaFrame}
        dangerouslySetInnerHTML={{
          __html:
            "<iframe allowtransparency frameborder='0' height='160' scrolling='no' src='https://www.strava.com/clubs/1102981/latest-rides/e76248e60c6fc743c38770795f106c0a3feaa704?show_rides=false' width='300'></iframe>",
        }}
      />
    </div>
  );
};

SectionStrava.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionStrava.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionStrava;
