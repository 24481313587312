import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage, AddToWishList } from '../../components';

import css from './CarouselCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;
const RENT_PREFERENCE = 'rent';

// Define min listing stock
const MIN_LISTING_STOCK = 0;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'CarouselCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'CarouselCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const CarouselCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    currentUser,
    onAddToWishList,
    currentWishListListingId,
    addToWishListInProgress,
    addToWishListError,
  } = props;

  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const { quantity: listingStock } = currentListing?.currentStock?.attributes || {};
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const isListingSold = listingStock === MIN_LISTING_STOCK;
  const { formattedPrice, priceTitle } = priceData(price, intl);

  // Listing preference
  const listingPreference = publicData?.preference;
  const isRentPreference = listingPreference === RENT_PREFERENCE;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {isListingSold ? (
        <NamedLink className={css.soldListingOverlay} name="ListingPage" params={{ id, slug }} />
      ) : null}
      <NamedLink name="ListingPage" params={{ id, slug }} className={css.threeToTwoWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </NamedLink>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <NamedLink className={css.title} name="ListingPage" params={{ id, slug }}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </NamedLink>
          {!isListingSold ? (
            <AddToWishList
              listingId={id}
              currentUser={currentUser}
              onAddToWishList={onAddToWishList}
              currentWishListListingId={currentWishListListingId}
              addToWishListInProgress={addToWishListInProgress}
              addToWishListError={addToWishListError}
            />
          ) : null}
        </div>
        <div className={css.price}>
          <div className={css.priceValue} title={priceTitle}>
            {isRentPreference ? (
              <FormattedMessage id="CarouselCard.rentPrice" values={{ price: formattedPrice }} />
            ) : (
              formattedPrice
            )}
          </div>
        </div>
      </div>

      <div className={css.badgeContainer}>
        {isListingSold ? (
          <div className={classNames(css.badge, css.soldBadge)}>
            <div className={css.badgeText}>
              <FormattedMessage id="ListingCard.soldBadge" />
            </div>
          </div>
        ) : null}
        {!isListingSold ? (
          <div className={css.badge}>
            <div className={css.badgeText}>
              {isRentPreference ? (
                <FormattedMessage id="ListingCard.rentBadge" />
              ) : (
                <FormattedMessage id="ListingCard.buyBadge" />
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

CarouselCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

CarouselCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(CarouselCardComponent);
