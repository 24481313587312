import isArray from 'lodash/isArray';
import reduce from 'lodash/reduce';
import Decimal from 'decimal.js';
import { sanitizeEntity } from './sanitize';
import { types as sdkTypes } from './sdkLoader';
import { LINE_ITEM_SHIPPING_FEE } from '../util/types';

const { Money } = sdkTypes;
const DEFAULT_LANGUAGE = 'en';

/**
 * Combine the given relationships objects
 *
 * See: http://jsonapi.org/format/#document-resource-object-relationships
 */
export const combinedRelationships = (oldRels, newRels) => {
  if (!oldRels && !newRels) {
    // Special case to avoid adding an empty relationships object when
    // none of the resource objects had any relationships.
    return null;
  }
  return { ...oldRels, ...newRels };
};

/**
 * Combine the given resource objects
 *
 * See: http://jsonapi.org/format/#document-resource-objects
 */
export const combinedResourceObjects = (oldRes, newRes) => {
  const { id, type } = oldRes;
  if (newRes.id.uuid !== id.uuid || newRes.type !== type) {
    throw new Error('Cannot merge resource objects with different ids or types');
  }
  const attributes = newRes.attributes || oldRes.attributes;
  const attributesOld = oldRes.attributes || {};
  const attributesNew = newRes.attributes || {};
  // Allow (potentially) sparse attributes to update only relevant fields
  const attrs = attributes ? { attributes: { ...attributesOld, ...attributesNew } } : null;
  const relationships = combinedRelationships(oldRes.relationships, newRes.relationships);
  const rels = relationships ? { relationships } : null;
  return { id, type, ...attrs, ...rels };
};

/**
 * Combine the resource objects form the given api response to the
 * existing entities.
 */
export const updatedEntities = (oldEntities, apiResponse) => {
  const { data, included = [] } = apiResponse;
  const objects = (Array.isArray(data) ? data : [data]).concat(included);

  const newEntities = objects.reduce((entities, curr) => {
    const { id, type } = curr;

    // Some entities (e.g. listing and user) might include extended data,
    // you should check if src/util/sanitize.js needs to be updated.
    const current = sanitizeEntity(curr);

    entities[type] = entities[type] || {};
    const entity = entities[type][id.uuid];
    entities[type][id.uuid] = entity ? combinedResourceObjects({ ...entity }, current) : current;

    return entities;
  }, oldEntities);

  return newEntities;
};

/**
 * Denormalise the entities with the resources from the entities object
 *
 * This function calculates the dernormalised tree structure from the
 * normalised entities object with all the relationships joined in.
 *
 * @param {Object} entities entities object in the SDK Redux store
 * @param {Array<{ id, type }} resources array of objects
 * with id and type
 * @param {Boolean} throwIfNotFound wheather to skip a resource that
 * is not found (false), or to throw an Error (true)
 *
 * @return {Array} the given resource objects denormalised that were
 * found in the entities
 */
export const denormalisedEntities = (entities, resources, throwIfNotFound = true) => {
  const denormalised = resources.map(res => {
    const { id, type } = res;
    const entityFound = entities[type] && id && entities[type][id.uuid];
    if (!entityFound) {
      if (throwIfNotFound) {
        throw new Error(`Entity with type "${type}" and id "${id ? id.uuid : id}" not found`);
      }
      return null;
    }
    const entity = entities[type][id.uuid];
    const { relationships, ...entityData } = entity;

    if (relationships) {
      // Recursively join in all the relationship entities
      return reduce(
        relationships,
        (ent, relRef, relName) => {
          // A relationship reference can be either a single object or
          // an array of objects. We want to keep that form in the final
          // result.
          const hasMultipleRefs = Array.isArray(relRef.data);
          const multipleRefsEmpty = hasMultipleRefs && relRef.data.length === 0;
          if (!relRef.data || multipleRefsEmpty) {
            ent[relName] = hasMultipleRefs ? [] : null;
          } else {
            const refs = hasMultipleRefs ? relRef.data : [relRef.data];

            // If a relationship is not found, an Error should be thrown
            const rels = denormalisedEntities(entities, refs, true);

            ent[relName] = hasMultipleRefs ? rels : rels[0];
          }
          return ent;
        },
        entityData
      );
    }
    return entityData;
  });
  return denormalised.filter(e => !!e);
};

/**
 * Denormalise the data from the given SDK response
 *
 * @param {Object} sdkResponse response object from an SDK call
 *
 * @return {Array} entities in the response with relationships
 * denormalised from the included data
 */
export const denormalisedResponseEntities = sdkResponse => {
  const apiResponse = sdkResponse.data;
  const data = apiResponse.data;
  const resources = Array.isArray(data) ? data : [data];

  if (!data || resources.length === 0) {
    return [];
  }

  const entities = updatedEntities({}, apiResponse);
  return denormalisedEntities(entities, resources);
};

/**
 * Denormalize JSON object.
 * NOTE: Currently, this only handles denormalization of image references
 *
 * @param {JSON} data from Asset API (e.g. page asset)
 * @param {JSON} included array of asset references (currently only images supported)
 * @returns deep copy of data with images denormalized into it.
 */
const denormalizeJsonData = (data, included) => {
  let copy;

  // Handle strings, numbers, booleans, null
  if (data === null || typeof data !== 'object') {
    return data;
  }

  // At this point the data has typeof 'object' (aka Array or Object)
  // Array is the more specific case (of Object)
  if (data instanceof Array) {
    copy = data.map(datum => denormalizeJsonData(datum, included));
    return copy;
  }

  // Generic Objects
  if (data instanceof Object) {
    copy = {};
    Object.entries(data).forEach(([key, value]) => {
      // Handle denormalization of image reference
      const hasImageRefAsValue =
        typeof value == 'object' &&
        value._ref &&
        value._ref?.type === 'imageAsset' &&
        value._ref?.id;
      // If there is no image included,
      // the _ref might contain parameters for image resolver (Asset Delivery API resolves image URLs on the fly)
      const hasUnresolvedImageRef =
        typeof value == 'object' && value._ref && value._ref?.resolver === 'image';

      if (hasImageRefAsValue) {
        const foundRef = included.find(inc => inc.id === value._ref?.id);
        copy[key] = foundRef;
      } else if (hasUnresolvedImageRef) {
        // Don't add faulty image ref
        // Note: At the time of writing, assets can expose resolver configs,
        //       which we don't want to deal with.
      } else {
        copy[key] = denormalizeJsonData(value, included);
      }
    });
    return copy;
  }

  throw new Error("Unable to traverse data! It's not JSON.");
};

/**
 * Denormalize asset json from Asset API.
 * @param {JSON} assetJson in format: { data, included }
 * @returns deep copy of asset data with images denormalized into it.
 */
export const denormalizeAssetData = assetJson => {
  const { data, included } = assetJson || {};
  return denormalizeJsonData(data, included);
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} transaction entity object, which is to be ensured against null values
 */
export const ensureTransaction = (transaction, booking = null, listing = null, provider = null) => {
  const empty = {
    id: null,
    type: 'transaction',
    attributes: {},
    booking,
    listing,
    provider,
  };
  return { ...empty, ...transaction };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} booking entity object, which is to be ensured against null values
 */
export const ensureBooking = booking => {
  const empty = { id: null, type: 'booking', attributes: {} };
  return { ...empty, ...booking };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureListing = listing => {
  const empty = {
    id: null,
    type: 'listing',
    attributes: { publicData: {} },
    images: [],
  };
  return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} listing entity object, which is to be ensured against null values
 */
export const ensureOwnListing = listing => {
  const empty = {
    id: null,
    type: 'ownListing',
    attributes: { publicData: {} },
    images: [],
  };
  return { ...empty, ...listing };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} user entity object, which is to be ensured against null values
 */
export const ensureUser = user => {
  const empty = { id: null, type: 'user', attributes: { profile: {} } };
  return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} current user entity object, which is to be ensured against null values
 */
export const ensureCurrentUser = user => {
  const empty = { id: null, type: 'currentUser', attributes: { profile: {} }, profileImage: {} };
  return { ...empty, ...user };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} time slot entity object, which is to be ensured against null values
 */
export const ensureTimeSlot = timeSlot => {
  const empty = { id: null, type: 'timeSlot', attributes: {} };
  return { ...empty, ...timeSlot };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureDayAvailabilityPlan = availabilityPlan => {
  const empty = { type: 'availability-plan/day', entries: [] };
  return { ...empty, ...availabilityPlan };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} availability exception entity object, which is to be ensured against null values
 */
export const ensureAvailabilityException = availabilityException => {
  const empty = { id: null, type: 'availabilityException', attributes: {} };
  return { ...empty, ...availabilityException };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensureStripeCustomer = stripeCustomer => {
  const empty = { id: null, type: 'stripeCustomer', attributes: {} };
  return { ...empty, ...stripeCustomer };
};

/**
 * Create shell objects to ensure that attributes etc. exists.
 *
 * @param {Object} stripeCustomer entity from API, which is to be ensured against null values
 */
export const ensurePaymentMethodCard = stripePaymentMethod => {
  const empty = {
    id: null,
    type: 'stripePaymentMethod',
    attributes: { type: 'stripe-payment-method/card', card: {} },
  };
  const cardPaymentMethod = { ...empty, ...stripePaymentMethod };

  if (cardPaymentMethod.attributes.type !== 'stripe-payment-method/card') {
    throw new Error(`'ensurePaymentMethodCard' got payment method with wrong type.
      'stripe-payment-method/card' was expected, received ${cardPaymentMethod.attributes.type}`);
  }

  return cardPaymentMethod;
};

/**
 * Get the display name of the given user as string. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned or deleted users, a translated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayNameAsString = (user, defaultUserDisplayName) => {
  const hasAttributes = user && user.attributes;
  const hasProfile = hasAttributes && user.attributes.profile;
  const hasDisplayName = hasProfile && user.attributes.profile.displayName;

  if (hasDisplayName) {
    return user.attributes.profile.displayName;
  } else {
    return defaultUserDisplayName || '';
  }
};

/**
 * DEPRECATED: Use userDisplayNameAsString function or UserDisplayName component instead
 *
 * @param {propTypes.user} user
 * @param {String} bannedUserDisplayName
 *
 * @return {String} display name that can be rendered in the UI
 */
export const userDisplayName = (user, bannedUserDisplayName) => {
  console.warn(
    `Function userDisplayName is deprecated!
User function userDisplayNameAsString or component UserDisplayName instead.`
  );

  return userDisplayNameAsString(user, bannedUserDisplayName);
};

/**
 * Get the abbreviated name of the given user. This function handles
 * missing data (e.g. when the user object is still being downloaded),
 * fully loaded users, as well as banned users.
 *
 * For banned  or deleted users, a default abbreviated name should be provided.
 *
 * @param {propTypes.user} user
 * @param {String} defaultUserAbbreviatedName
 *
 * @return {String} abbreviated name that can be rendered in the UI
 * (e.g. in Avatar initials)
 */
export const userAbbreviatedName = (user, defaultUserAbbreviatedName) => {
  const hasAttributes = user && user.attributes;
  const hasProfile = hasAttributes && user.attributes.profile;
  const hasDisplayName = hasProfile && user.attributes.profile.abbreviatedName;

  if (hasDisplayName) {
    return user.attributes.profile.abbreviatedName;
  } else {
    return defaultUserAbbreviatedName || '';
  }
};

/**
 * A customizer function to be used with the
 * mergeWith function from lodash.
 *
 * Works like merge in every way exept that on case of
 * an array the old value is completely overridden with
 * the new value.
 *
 * @param {Object} objValue Value of current field, denoted by key
 * @param {Object} srcValue New value
 * @param {String} key Key of the field currently being merged
 * @param {Object} object Target object that is receiving values from source
 * @param {Object} source Source object that is merged into object param
 * @param {Object} stack Tracks merged values
 *
 * @return {Object} New value for objValue if the original is an array,
 * otherwise undefined is returned, which results in mergeWith using the
 * standard merging function
 */
export const overrideArrays = (objValue, srcValue, key, object, source, stack) => {
  if (isArray(objValue)) {
    return srcValue;
  }
};

/**
 * Humanizes a line item code. Strips the "line-item/" namespace
 * definition from the beginnign, replaces dashes with spaces and
 * capitalizes the first character.
 *
 * @param {string} code a line item code
 *
 * @return {string} returns the line item code humanized
 */
export const humanizeLineItemCode = code => {
  if (!/^line-item\/.+/.test(code)) {
    throw new Error(`Invalid line item code: ${code}`);
  }
  const lowercase = code.replace(/^line-item\//, '').replace(/-/g, ' ');

  return lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
};

/**
 * Gets the current marketplace language from localStorage
 */
export const getCurrentLanguage = () => {
  const currentLanguage =
    typeof localStorage === 'object' && localStorage.getItem('locale')
      ? localStorage.getItem('locale')
      : DEFAULT_LANGUAGE;

  return currentLanguage ? currentLanguage : null;
};

/**
 * Gets the categories based on Preference
 *  *
 * @param {object} preferences listing preferences
 * @param {string} preference listing preference
 *
 * @return {string} returns options from selected preference
 */
export const getListingCategoriesFromURL = (preferences, preference) => {
  const fetchPreferences = preferences.find(p => p.key === preference);

  return fetchPreferences ? fetchPreferences.options : null;
};

/**
 * Gets the sub categories based on Category
 *  *
 * @param {object} categories listing categories
 * @param {string} category listing category
 *
 * @return {string} returns options from selected category
 */
export const getListingSubCategoriesFromURL = (categories, category) => {
  const fetchSubCategories = categories.find(s => s.key === category);

  return fetchSubCategories ? fetchSubCategories.options : null;
};

/**
 * Gets the category label from the Category
 */
export const getListingCategoryLabelFromURL = (categories, category) => {
  const fetchCategories = categories.find(c => c.key === category);

  return fetchCategories ? fetchCategories.label : null;
};

/**
 * Gets the sub category label from the Preference and Category
 */
export const getListingSubCategoryLabelFromURL = (
  listingCategories,
  listingPreference,
  listingCategory,
  listingSubCategory
) => {
  const findPreference = listingCategories?.find(preference => preference.key === listingPreference)
    ?.options;
  const findCategory = findPreference?.find(category => category.key === listingCategory)?.options;
  const findSubCategory = findCategory?.find(subcategory => subcategory.key === listingSubCategory);

  return findSubCategory ? findSubCategory.label : null;
};

/**
 * Gets the listing fields from the listing params URL
 */
export const getListingFieldsFromURL = (config, params) => {
  const listingPreference = params.preference;
  const listingCategory = params.category;
  const listingSubCategory = params.subcategory;

  const listingCategories = getListingCategoriesFromURL(config.options, listingPreference);
  const listingSubCategories = getListingSubCategoriesFromURL(listingCategories, listingCategory);

  const listingFieldsFromCategory = listingCategories?.find(
    category => category.key === listingCategory
  );
  const listingFieldsFromSubCategory = listingSubCategories?.find(
    subcategory => subcategory.key === listingSubCategory
  );

  const listingFields = listingFieldsFromCategory?.fields || listingFieldsFromSubCategory?.fields;

  return listingFields ? listingFields : null;
};

/**
 * Gets the listing fields for filters
 */
export const getFiltersFromListingFields = (config, params) => {
  const listingPreference = params.preference;
  const listingCategory = params.category;
  const listingSubCategory = params.subcategory;

  const listingCategories = getListingCategoriesFromURL(config.options, listingPreference);
  const listingSubCategories = getListingSubCategoriesFromURL(listingCategories, listingCategory);

  // We need to find the matching category fields
  const fieldsFromCategory = listingCategories?.find(category => category.key === listingCategory);
  const allListingFieldsFromCategory = fieldsFromCategory?.options
    ?.flatMap(option => option.fields)
    ?.filter((v, i, a) => a.findIndex(t => t.key === v.key) === i);

  // Listing fields
  const listingFieldsFromCategory = allListingFieldsFromCategory
    ? {
        fields: allListingFieldsFromCategory?.filter(field => field.required),
      }
    : fieldsFromCategory;
  const listingFieldsFromSubCategory = listingSubCategories?.find(
    subcategory => subcategory.key === listingSubCategory
  );

  const listingFields = listingFieldsFromSubCategory
    ? listingFieldsFromSubCategory?.fields
    : listingFieldsFromCategory?.fields;

  return listingFields ? listingFields : null;
};

/**
 * Sort listing fields so that required appears first
 */
export const sortFieldsByRequired = fields => {
  const sortedArray = fields
    ? [...fields?.filter(field => field?.required), ...fields?.filter(field => !field?.required)]
    : null;

  return sortedArray;
};

/**
 * Moves an array element to different position
 */
export const arrayMove = (arr, fromIndex, toIndex) => {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);

  return arr;
};

export const generateListingBreadcrumbs = (editListingCategories, params) => {
  const { preference, category, subcategory, title } = params || {};

  const listingPreference = editListingCategories.options.find(p => p.key === preference);
  const listingCategory = getListingCategoryLabelFromURL(listingPreference.options, category);
  const listingSubCategory = getListingSubCategoryLabelFromURL(
    editListingCategories.options,
    preference,
    category,
    subcategory
  );

  return listingSubCategory
    ? [
        {
          key: 'preference',
          label: listingPreference?.label,
          search: `?pub_preference=${preference}`,
        },
        {
          key: 'category',
          label: listingCategory,
          search: `?pub_preference=${preference}&pub_category=${category}`,
        },
        {
          key: 'subcategory',
          label: listingSubCategory,
          search: `?pub_preference=${preference}&pub_category=${category}&pub_subcategory=${subcategory}`,
        },
        { key: 'title', label: title },
      ]
    : [
        {
          key: 'preference',
          label: listingPreference?.label,
          search: `?pub_preference=${preference}`,
        },
        {
          key: 'category',
          label: listingCategory,
          search: `?pub_preference=${preference}&category=${category}`,
        },
        { key: 'title', label: title },
      ];
};

export const applyFeaturesUnit = (field, feature) => {
  if (!feature) {
    return null;
  }

  const shouldAddCMUnit = field === 'length' || field === 'height' || field === 'width';
  const shouldAddKGUnit = field === 'claimed-weight' || field === 'weight';

  if (shouldAddCMUnit) {
    return `${feature} cm`;
  } else if (shouldAddKGUnit) {
    return `${feature} kg`;
  }

  return feature;
};

export const displayActivityPreference = (preference, isOrder) => {
  const RENT_PREFERENCE = 'rent';
  const SELL_PREFERENCE = 'sell';

  if (isOrder) {
    if (preference === SELL_PREFERENCE) {
      return 'purchase';
    }
  } else {
    if (preference === SELL_PREFERENCE) {
      return 'sale';
    } else if (preference === RENT_PREFERENCE) {
      return 'let';
    }
  }

  if (preference === SELL_PREFERENCE) {
    return isOrder ? 'purchase' : 'sale';
  }

  return preference;
};

export const findConfigForMenuItem = item => {
  if (!item?.config?.options) {
    return null;
  }

  return item.config.options;
};

export const findConfigImageForMenuItem = item => {
  if (!item?.config?.images) {
    return null;
  }

  return item.config.images;
};

export const sortByFrequency = array => {
  var frequency = {};

  array.forEach(function(value) {
    frequency[value] = 0;
  });

  var uniques = array.filter(function(value) {
    return ++frequency[value] == 1;
  });

  return uniques.sort(function(a, b) {
    const y = frequency[b] - frequency[a];
    return y;
  });
};

export const denormaliseCartPurchaseListingTitles = (listings, listingTitle) => {
  if (!listings || listings.length < 2) {
    return listingTitle;
  }

  const denormalisedListings = listings.map(l => l.title);
  const joinedTitles =
    listings.length === 2
      ? denormalisedListings?.join(' and ')
      : denormalisedListings?.slice(0, 3)?.join(', ');
  const finalTitle = listings.length > 3 ? `${joinedTitles}... (${listings.length})` : joinedTitles;

  return finalTitle;
};

export const findDiscountCode = (discountCodes, discountCodeId, currentUser) => {
  const toLowerCase = string => string?.toLowerCase();

  const currentUserId = currentUser.id?.uuid;
  const currentDiscountCode = discountCodes?.find(
    c =>
      toLowerCase(c.id) === toLowerCase(discountCodeId) &&
      !c.blacklistedIds?.includes(currentUserId)
  );

  return currentDiscountCode;
};

export const isDiscountCodeValid = code => code && code.id && code.percentage;

export const prepareShippingAddress = shippingAddress => {
  const {
    addressLine1,
    postalCode,
    city,
    country,
    state,
    contactName,
    email,
    phone,
  } = shippingAddress;

  const street2Maybe = shippingAddress.addressLine2
    ? {
        street2: shippingAddress.addressLine2,
      }
    : {};
  const stateMaybe = shippingAddress.state
    ? {
        state,
      }
    : {};

  return {
    contact_name: contactName,
    city,
    country,
    postal_code: postalCode,
    street1: addressLine1,
    type: 'business',
    phone,
    email,
    ...street2Maybe,
    ...stateMaybe,
  };
};

export const denormaliseShippingAddress = shippingData => {
  const shippingInfo = shippingData.rates.reduce(function(res, obj) {
    return obj.total_charge.amount < res.total_charge.amount ? obj : res;
  });

  return shippingInfo;
};

export const generateShippingLineItem = price => {
  const { amount, currency } = price;
  return {
    code: LINE_ITEM_SHIPPING_FEE,
    includeFor: ['customer'],
    quantity: new Decimal(1),
    unitPrice: new Money(amount * 100, currency),
    lineTotal: new Money(amount * 100, currency),
    reversal: false,
  };
};

export const generateShippingWeight = category => {
  if (category === 'bikes') {
    return {
      weight: {
        value: 10,
        unit: 'kg',
      },
      dimension: {
        width: 40,
        height: 60,
        depth: 60,
        unit: 'cm',
      },
    };
  }

  return {
    weight: {
      value: 3,
      unit: 'kg',
    },
    dimension: {
      width: 20,
      height: 40,
      depth: 40,
      unit: 'cm',
    },
  };
};

export const generateShippingItem = listing => {
  const getCountryISO3 = require('country-iso-2-to-3');

  const { title, price, publicData } = listing.attributes;
  const originCountry = getCountryISO3(
    listing.author.attributes.publicData?.shippingFrom?.country || 'GB'
  );
  const weight = publicData?.shippingWeight['weight'];
  return {
    description: title,
    quantity: 1,
    origin_country: originCountry,
    price: {
      amount: price.amount / 100,
      currency: 'GBP',
    },
    weight,
  };
};
