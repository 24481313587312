import React from 'react';
import {
  FieldTextInput,
  CustomSelectField,
  FieldCurrencyInput,
  FieldCheckbox,
  FieldCheckboxGroup,
} from '../../components';
import { required } from '../../util/validators';
import config from '../../config';

import css from './ListingField.module.css';

const TEXT_TYPE = 'text';
const NUMBER_TYPE = 'number';
const SELECT_TYPE = 'select';
const CURRENCY_TYPE = 'currency';
const CHECKBOX_TYPE = 'checkbox';
const CHECKBOX_GROUP_TYPE = 'checkbox-group';

const ListingField = props => {
  const {
    intl,
    id,
    name,
    labelMsgId,
    placeholderMsgId,
    type,
    values,
    required: isFieldRequired,
  } = props;

  const label = labelMsgId ? intl.formatMessage({ id: labelMsgId }) : null;
  const placeholder = placeholderMsgId ? intl.formatMessage({ id: placeholderMsgId }) : null;
  const requiredMessage = isFieldRequired
    ? required(
        intl.formatMessage({
          id: 'ListingField.requiredMessage',
        })
      )
    : null;

  switch (type) {
    case TEXT_TYPE:
    case NUMBER_TYPE:
      return (
        <FieldTextInput
          id={id}
          name={name}
          type={type}
          label={label}
          placeholder={placeholder}
          validate={requiredMessage}
        />
      );

    case SELECT_TYPE:
      return (
        <CustomSelectField
          name={name}
          id={id}
          label={label}
          placeholder={placeholder}
          validate={requiredMessage}
          options={values}
        />
      );

    case CURRENCY_TYPE:
      return (
        <FieldCurrencyInput
          id={id}
          name={name}
          label={label}
          placeholder={placeholder}
          currencyConfig={config.currencyConfig}
          validate={requiredMessage}
        />
      );

    case CHECKBOX_TYPE:
      return (
        <div className={css.checkbox}>
          {label ? <label className={css.checkboxLabel}>{label}</label> : null}
          <FieldCheckbox id={id} name={name} value={name} validate={requiredMessage} />
        </div>
      );

    case CHECKBOX_GROUP_TYPE:
      return <FieldCheckboxGroup id={id} name={name} label={label} options={values} />;

    default:
      return null;
  }
};

export default ListingField;
