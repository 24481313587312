import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import { Form, FieldTextInput } from '../../components';
import { propTypes } from '../../util/types';
import classNames from 'classnames';

import IconSuccess from './IconSuccess';
import IconError from './IconError';
import css from './DiscountForm.module.css';

const DiscountFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit: handleChange,
        values,
        intl,
        currentUserId,
        discountCode,
        discountCodes,
        discountInProgress,
        discountError,
      } = fieldRenderProps;

      const discountLabel = intl.formatMessage({
        id: 'DiscountForm.discountLabel',
      });
      const discountPlaceholder = intl.formatMessage({
        id: 'DiscountForm.discountPlaceholder',
      });

      const currentDiscountCode = discountCodes?.find(
        c => c.id?.toLowerCase() === values.code?.toLowerCase()
      );
      const currentDiscountCodeBlacklistedIds = currentDiscountCode?.blacklistedIds;

      const showForm = !discountError && !discountInProgress;
      const showAlreadyUsedCodeError = currentDiscountCodeBlacklistedIds?.includes(currentUserId);
      const showErrorIcon = values.code && values.code !== '';

      const classes = classNames(rootClassName || css.root, className);

      return showForm ? (
        <Form className={classes} onChange={handleChange} onSubmit={e => e.preventDefault()}>
          <label className={css.label}>{discountLabel}</label>
          <div className={css.fieldWrapper}>
            <FieldTextInput
              className={css.field}
              inputRootClass={classNames(css.input, {
                [css.inputSuccess]: discountCode,
                [css.inputError]: !discountCode && showErrorIcon,
              })}
              type="text"
              name="code"
              id={formId ? `${formId}.code` : 'code'}
              placeholder={discountPlaceholder}
            />
            {discountCode ? <IconSuccess /> : showErrorIcon && <IconError />}
          </div>
          <div className={css.errorContainer}>
            {discountError ? (
              <p className={css.error}>
                <FormattedMessage id="DiscountForm.sendEnquiryError" />
              </p>
            ) : null}
            {showAlreadyUsedCodeError ? (
              <p className={css.error}>
                <FormattedMessage id="DiscountForm.alreadyUsedCodeError" />
              </p>
            ) : null}
          </div>
        </Form>
      ) : null;
    }}
  />
);

DiscountFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  discountError: null,
};

DiscountFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,
  discountError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const DiscountForm = compose(injectIntl)(DiscountFormComponent);

DiscountForm.displayName = 'DiscountForm';

export default DiscountForm;
