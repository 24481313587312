import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ensureUser } from '../../util/data';
import { AvatarLarge, NamedLink } from '../../components';

import css from './CarouselProfileCard.module.css';

const CarouselProfileCard = props => {
  const { rootClassName, className, data: userData } = props;

  // User display name
  const { displayName } = ensureUser(userData?.attributes?.profile);
  const userId = userData?.id?.uuid;

  const classes = classNames(rootClassName || css.root, className);
  return userId ? (
    <NamedLink className={classes} name="ProfilePage" params={{ id: userId }}>
      <div className={css.cardWrapper}>
        <AvatarLarge disableProfileLink user={userData} />
        <h2 className={css.userName}>{displayName}</h2>
      </div>
    </NamedLink>
  ) : null;
};

CarouselProfileCard.defaultProps = { rootClassName: null, className: null, data: null };

const { string } = PropTypes;

CarouselProfileCard.propTypes = {
  rootClassName: string,
  className: string,
};

export default CarouselProfileCard;
