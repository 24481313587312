import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionShopNow.module.css';

const SectionShopNow = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionShopNow.title" />
      </div>
      <div className={css.shopNow}>
        <NamedLink className={css.shopNowLink} name="SearchPage">
          <FormattedMessage id="SectionShopNow.shopNowLink" />
        </NamedLink>
      </div>
    </div>
  );
};

SectionShopNow.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionShopNow.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionShopNow;
