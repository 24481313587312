import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { storableError } from '../../util/errors';
import { denormalisedResponseEntities } from '../../util/data';
import config from '../../config';

const LATEST_LISTINGS_RESULT_PAGE_SIZE = 24;
const CATEGORY_LISTINGS_RESULT_PAGE_SIZE = 24;
const MINIMAL_LISTING_STOCK = config.listingStockReservationQuantity;

// ================ Action types ================ //

export const FETCH_USER_PROFILES_REQUEST = 'app/LandingPage/FETCH_USER_PROFILES_REQUEST';
export const FETCH_USER_PROFILES_SUCCESS = 'app/LandingPage/FETCH_USER_PROFILES_SUCCESS';
export const FETCH_USER_PROFILES_ERROR = 'app/LandingPage/FETCH_USER_PROFILES_ERROR';

export const FETCH_LATEST_LISTINGS_REQUEST = 'app/LandingPage/FETCH_LATEST_LISTINGS_REQUEST';
export const FETCH_LATEST_LISTINGS_SUCCESS = 'app/LandingPage/FETCH_LATEST_LISTINGS_SUCCESS';
export const FETCH_LATEST_LISTINGS_ERROR = 'app/LandingPage/FETCH_LATEST_LISTINGS_ERROR';

export const FETCH_CATEGORY_LISTINGS_REQUEST = 'app/LandingPage/FETCH_CATEGORY_LISTINGS_REQUEST';
export const FETCH_CATEGORY_LISTINGS_SUCCESS = 'app/LandingPage/FETCH_CATEGORY_LISTINGS_SUCCESS';
export const FETCH_CATEGORY_LISTINGS_ERROR = 'app/LandingPage/FETCH_CATEGORY_LISTINGS_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/LandingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/LandingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/LandingPage/FETCH_REVIEWS_ERROR';

// ================ Reducer ================ //

const initialState = {
  userIds: [],
  fetchUsersInProgress: false,
  fetchUsersError: null,
  listingIds: [],
  fetchListingsInProgress: false,
  fetchListingsError: null,
  categoryListingIds: [],
  fetchCategoryListingInProgress: false,
  fetchCategoryListingsError: null,
  reviews: [],
  fetchReviewsInProgress: false,
  fetchReviewsError: null,
};

const resultIds = data => data.data.map(l => l.id);

const landingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_USER_PROFILES_REQUEST:
      return {
        ...state,
        fetchUsersInProgress: true,
        fetchUsersError: null,
        userIds: payload.userIds,
      };
    case FETCH_USER_PROFILES_SUCCESS: {
      return {
        ...state,
        fetchUsersInProgress: false,
      };
    }
    case FETCH_USER_PROFILES_ERROR:
      return { ...state, fetchUsersInProgress: false, fetchUsersError: payload };

    case FETCH_LATEST_LISTINGS_REQUEST:
      return {
        ...state,
        fetchListingsInProgress: true,
        fetchListingsError: null,
      };
    case FETCH_LATEST_LISTINGS_SUCCESS:
      return {
        ...state,
        listingIds: resultIds(payload.data),
        fetchListingsInProgress: false,
      };
    case FETCH_LATEST_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, fetchListingsInProgress: false, fetchListingsError: payload };

    case FETCH_CATEGORY_LISTINGS_REQUEST:
      return {
        ...state,
        fetchCategoryListingsInProgress: true,
        fetchCategoryListingsError: null,
      };
    case FETCH_CATEGORY_LISTINGS_SUCCESS:
      return {
        ...state,
        categoryListingIds: resultIds(payload.data),
        fetchCategoryListingsInProgress: false,
      };
    case FETCH_CATEGORY_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        fetchCategoryListingsInProgress: false,
        fetchCategoryListingsError: payload,
      };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsInProgress: true, fetchReviewsError: null };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, fetchReviewsInProgress: false, reviews: payload };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsInProgress: false, fetchReviewsError: payload };

    default:
      return state;
  }
};

export default landingPageReducer;

// ================ Action creators ================ //

const fetchUserProfilesRequest = userIds => ({
  type: FETCH_USER_PROFILES_REQUEST,
  payload: { userIds },
});
const fetchUserProfilesSuccess = () => ({
  type: FETCH_USER_PROFILES_SUCCESS,
});
const fetchUserProfilesError = e => ({
  type: FETCH_USER_PROFILES_ERROR,
  error: true,
  payload: e,
});

export const fetchLatestListingsRequest = () => ({
  type: FETCH_LATEST_LISTINGS_REQUEST,
});
export const fetchLatestListingsSuccess = response => ({
  type: FETCH_LATEST_LISTINGS_SUCCESS,
  payload: { data: response.data },
});
export const fetchLatestListingsError = e => ({
  type: FETCH_LATEST_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchCategoryListingsRequest = () => ({
  type: FETCH_CATEGORY_LISTINGS_REQUEST,
});
export const fetchCategoryListingsSuccess = response => ({
  type: FETCH_CATEGORY_LISTINGS_SUCCESS,
  payload: { data: response.data },
});
export const fetchCategoryListingsError = e => ({
  type: FETCH_CATEGORY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

// ================ Thunks ================ //

export const fetchUsers = userIds => (dispatch, getState, sdk) => {
  // Fetch users by ID
  const fetchUsersById = array => {
    // Call request function
    dispatch(fetchUserProfilesRequest(array));
    const promises = userIds.map(async id => {
      return sdk.users
        .show({
          id: id,
          include: ['profileImage'],
          'fields.author': ['profile.publicData'],
          'fields.image': ['variants.square-small', 'variants.square-small2x'],
        })
        .then(response => {
          dispatch(addMarketplaceEntities(response));
          return response.data.data;
        });
    });
    return Promise.all(promises);
  };

  // Call fetchUsersById function
  fetchUsersById(userIds)
    .then(function(result) {
      // Call success function
      dispatch(fetchUserProfilesSuccess(result));
    })
    .catch(e => dispatch(fetchUserProfilesError(storableError(e))));
};

export const fetchLatestListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchLatestListingsRequest());

  const params = {
    page: 1,
    per_page: LATEST_LISTINGS_RESULT_PAGE_SIZE,
    minStock: MINIMAL_LISTING_STOCK,
    include: ['author', 'images', 'currentStock'],
    'fields.listing': ['title', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchLatestListingsSuccess(response));

      return response;
    })
    .catch(e => {
      dispatch(fetchLatestListingsError(storableError(e)));
      throw e;
    });
};

export const fetchCategoryListings = () => (dispatch, getState, sdk) => {
  dispatch(fetchCategoryListingsRequest());

  const params = {
    page: 1,
    per_page: CATEGORY_LISTINGS_RESULT_PAGE_SIZE,
    minStock: MINIMAL_LISTING_STOCK,
    // filters
    pub_subcategory: 'jerseys',
    include: ['author', 'images', 'currentStock'],
    'fields.listing': ['title', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchCategoryListingsSuccess(response));

      return response;
    })
    .catch(e => {
      dispatch(fetchCategoryListingsError(storableError(e)));
      throw e;
    });
};

export const fetchReviews = reviewIds => (dispatch, getState, sdk) => {
  // Fetch users by ID
  const fetchReviewsById = array => {
    // Call request function
    dispatch(fetchReviewsRequest(array));

    const promises = reviewIds.map(async id => {
      return sdk.reviews
        .show({
          id: id,
          include: ['author', 'listing', 'images'],
          type: 'ofProvider',
        })
        .then(response => {
          const review = denormalisedResponseEntities(response)?.[0];
          dispatch(addMarketplaceEntities(response));

          return review;
        })
        .catch(e => dispatch(fetchReviewsError(storableError(e))));
    });
    return Promise.all(promises);
  };

  // Call fetchReviewsById function
  fetchReviewsById(reviewIds)
    .then(function(result) {
      // Call success function
      dispatch(fetchReviewsSuccess(result));
    })
    .catch(e => dispatch(fetchReviewsError(storableError(e))));
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Get users and reviews ids list from
  // marketplace-custom-config.js
  const userIds = config.custom.userIdsForLandingPage;
  const reviewIds = config.custom.reviewIdsForLandingPage;

  return Promise.all([
    dispatch(fetchUsers(userIds)),
    dispatch(fetchLatestListings()),
    dispatch(fetchCategoryListings()),
    dispatch(fetchReviews(reviewIds)),
  ]);
};
