import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './EditListingPhotosForm.module.css';

const LENGTH_FOR_TIPS_MARGIN = 2;

const Tips = props => {
  const { className, isRentPreference, images } = props;

  const imagesLength = images?.length;
  return (
    <div
      className={classNames(css.tips, className, {
        [css.tipsWithMargin]: !isRentPreference && imagesLength > LENGTH_FOR_TIPS_MARGIN,
      })}
    >
      <p className={css.tipsLabel}>
        <FormattedMessage id="EditListingPhotosForm.addImagesTip" />
      </p>
      <ul className={css.tipsList}>
        <li className={css.tip}>
          <FormattedMessage id="EditListingPhotosForm.mainTip" />
        </li>
        {isRentPreference ? (
          <li className={css.tip}>
            <FormattedMessage id="EditListingPhotosForm.firstRentTip" />
          </li>
        ) : (
          <li className={css.tip}>
            <FormattedMessage id="EditListingPhotosForm.firstTip" />
          </li>
        )}
        <li className={css.tip}>
          <FormattedMessage id="EditListingPhotosForm.secondTip" />
        </li>
        <li className={css.tip}>
          <FormattedMessage id="EditListingPhotosForm.thirdTip" />
        </li>
      </ul>
    </div>
  );
};

export default Tips;
