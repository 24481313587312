import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './OrderBreakdown.module.css';

const LineItemBasePriceMaybe = () => {
  return (
    <>
      <div className={css.lineItem}>
        <span className={classNames(css.itemLabel, css.itemLabelBolded)}>
          <FormattedMessage id="OrderBreakdown.paymentLabel" />
        </span>
      </div>
      <div className={css.totalDivider} />
    </>
  );
};

export default LineItemBasePriceMaybe;
