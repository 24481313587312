import React from 'react';
import { string } from 'prop-types';

const IconInbox = props => {
  const { className } = props;
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <g fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round">
        <path d="M.857 12.017h6.857l2.572-4.303 2.571 9.429 3.429-5.126h6.857M21.891 6.857a11.16 11.16 0 00-19.782 0M2.109 17.143a11.16 11.16 0 0019.782 0" />
      </g>
    </svg>
  );
};

IconInbox.propTypes = {
  className: string,
};

export default IconInbox;
