import React from 'react';
import { string } from 'prop-types';

const IconWishList = props => {
  const { className, isActive } = props;

  return isActive ? (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="22" height="22">
      <path d="M14.285 17.86c-1.047 1.027-2.152 2.097-3.285 3.222C5.105 15.234 0 10.867 0 6.594 0 2.922 2.84.918 5.758.918c2.015 0 4.062.953 5.242 2.977 1.172-2.012 3.227-2.97 5.25-2.97 2.91 0 5.75 1.99 5.75 5.669 0 1.445-.594 2.902-1.598 4.426L19.078 9.69c.688-1.109 1.09-2.144 1.09-3.097 0-2.633-2.031-3.836-3.918-3.836-2.988 0-4.563 3.187-5.25 4.605C10.316 5.95 8.723 2.75 5.758 2.75c-2.125 0-3.926 1.39-3.926 3.844 0 3.152 4.348 7.195 9.168 11.914l1.988-1.942zm6.797-5.028H13.75v1.836h7.332zm0 0" />
    </svg>
  ) : (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="22" height="22">
      <path d="M14.348 17.797c-1.063 1.047-2.188 2.137-3.348 3.285C5.105 15.234 0 10.867 0 6.594 0 2.922 2.84.918 5.758.918c2.015 0 4.062.953 5.242 2.977 1.172-2.012 3.227-2.97 5.25-2.97 2.91 0 5.75 1.99 5.75 5.669 0 1.164-.387 2.332-1.059 3.539l-1.359-1.36c.371-.77.586-1.496.586-2.18 0-2.632-2.031-3.835-3.918-3.835-2.988 0-4.563 3.187-5.25 4.605C10.316 5.95 8.723 2.75 5.758 2.75c-2.125 0-3.926 1.39-3.926 3.844 0 3.152 4.348 7.195 9.168 11.914l2.05-2.008zm6.734-4.965h-2.75v-2.75H16.5v2.75h-2.75v1.836h2.75v2.75h1.832v-2.75h2.75zm0 0" />
    </svg>
  );
};

IconWishList.propTypes = {
  className: string,
};

export default IconWishList;
