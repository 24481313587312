import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import { Button, Form, FieldCurrencyInput } from '../../components';

import css from './EditListingPricingForm.module.css';

const { Money } = sdkTypes;
const SELL_PREFERENCE = 'sell';

export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        params,
      } = formRenderProps;

      const listingPreference = params.preference;
      const isSellPreference = listingPreference === SELL_PREFERENCE;

      const { customerCommission } = config;

      const pricingMessage = isSellPreference
        ? 'EditListingPricingForm.pricePerUnit'
        : 'EditListingPricingForm.rentPricePerUnit';
      const pricingPlaceholderMessage = isSellPreference
        ? 'EditListingPricingForm.priceInputPlaceholder'
        : 'EditListingPricingForm.dailyPriceInputPlaceholder';

      const pricePerUnitMessage = intl.formatMessage({
        id: pricingMessage,
      });

      const pricePlaceholderMessage = intl.formatMessage({
        id: pricingPlaceholderMessage,
      });

      const retailPriceMessage = intl.formatMessage({
        id: 'EditListingPricingForm.retailPrice',
      });
      const retailPricePlaceholderMessage = intl.formatMessage({
        id: 'EditListingPricingForm.retailPricePlaceholder',
      });

      const priceRequired = validators.required(
        intl.formatMessage({
          id: 'EditListingPricingForm.priceRequired',
        })
      );
      const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
      const minPriceRequired = validators.moneySubUnitAmountAtLeast(
        intl.formatMessage(
          {
            id: 'EditListingPricingForm.priceTooLow',
          },
          {
            minPrice: formatMoney(intl, minPrice),
          }
        ),
        config.listingMinimumPriceSubUnits
      );
      const priceValidators = config.listingMinimumPriceSubUnits
        ? validators.composeValidators(priceRequired, minPriceRequired)
        : priceRequired;

      // Payout amount
      const payoutAmount = values?.price?.amount;

      // Payout calculation
      const payoutWithComission =
        payoutAmount / 100 + (payoutAmount / 100) * -customerCommission.key || 0;

      // Form a payout
      const formattedPayout = <strong>£{payoutWithComission}</strong>;

      // Calculate price and retail price discount
      const retailPriceAmount = values?.retailPrice?.amount;
      const calculatedDiscount = (payoutAmount / retailPriceAmount) * 100;
      const discount = 100 - calculatedDiscount;
      const formattedDiscount = <strong>{discount?.toFixed(0)}%</strong>;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const { updateListingError, showListingsError } = fetchErrors || {};

      return (
        <Form onSubmit={handleSubmit} className={classes}>
          {updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.updateFailed" />
            </p>
          ) : null}
          {showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPricingForm.showListingFailed" />
            </p>
          ) : null}
          <FieldCurrencyInput
            id="price"
            name="price"
            className={css.priceInput}
            autoFocus
            label={pricePerUnitMessage}
            placeholder={pricePlaceholderMessage}
            currencyConfig={config.currencyConfig}
            validate={priceValidators}
          />

          {payoutAmount ? (
            <p className={css.payout}>
              <FormattedMessage
                id="EditListingPricingForm.finalPrice"
                values={{
                  payout: formattedPayout,
                  commission: customerCommission.label,
                }}
              />
            </p>
          ) : null}

          {isSellPreference ? (
            <FieldCurrencyInput
              id="retailPrice"
              name="retailPrice"
              className={css.retailPriceInput}
              label={retailPriceMessage}
              placeholder={retailPricePlaceholderMessage}
              currencyConfig={config.currencyConfig}
            />
          ) : null}

          {isSellPreference && retailPriceAmount ? (
            <p className={css.payout}>
              {discount > 0 ? (
                <FormattedMessage
                  id="EditListingPricingForm.discount"
                  values={{ discount: formattedDiscount }}
                />
              ) : (
                <FormattedMessage id="EditListingPricingForm.noDiscount" />
              )}
            </p>
          ) : null}

          <div className={css.submitButtonWrapper}>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
