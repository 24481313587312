import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { fetchCurrentUserCartListings } from '../../ducks/user.duck';
import { util as sdkUtil } from '../../util/sdkLoader';
import config from '../../config';

const MINIMAL_LISTING_STOCK = config.listingStockReservationQuantity;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const FETCH_LISTINGS_REQUEST = 'app/WishListPage/FETCH_LISTINGS_REQUEST';
export const FETCH_LISTINGS_SUCCESS = 'app/WishListPage/FETCH_LISTINGS_SUCCESS';
export const FETCH_LISTINGS_ERROR = 'app/WishListPage/FETCH_LISTINGS_ERROR';

export const ADD_TO_CART_REQUEST = 'app/CartPage/ADD_TO_CART_REQUEST';
export const ADD_TO_CART_SUCCESS = 'app/CartPage/ADD_TO_CART_SUCCESS';
export const ADD_TO_CART_ERROR = 'app/CartPage/ADD_TO_CART_ERROR';

export const REMOVE_FROM_CART_REQUEST = 'app/CartPage/REMOVE_FROM_CART_REQUEST';
export const REMOVE_FROM_CART_SUCCESS = 'app/CartPage/REMOVE_FROM_CART_SUCCESS';
export const REMOVE_FROM_CART_ERROR = 'app/CartPage/REMOVE_FROM_CART_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchListingsInProgress: false,
  fetchListingsError: null,
  cartResultIds: [],
  addToCartInProgress: false,
  addToCartError: null,
  removeFromCartInProgress: false,
  removeFromCartError: null,
  currentRemoveListingIds: [],
};

const resultIds = data => data.data.map(l => l.id);

const cartPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_LISTINGS_REQUEST:
      return {
        ...state,
        fetchListingsInProgress: true,
        fetchListingsError: null,
        cartResultIds: [],
      };
    case FETCH_LISTINGS_SUCCESS:
      return {
        ...state,
        cartResultIds: resultIds(payload.data),
        fetchListingsInProgress: false,
      };
    case FETCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, fetchListingsInProgress: false, fetchListingsError: payload };

    case ADD_TO_CART_REQUEST:
      return {
        ...state,
        addToCartInProgress: true,
      };
    case ADD_TO_CART_SUCCESS:
      return {
        ...state,
        addToCartInProgress: false,
      };
    case ADD_TO_CART_ERROR:
      return {
        ...state,
        addToCartInProgress: false,
        addToCartError: payload,
      };

    case REMOVE_FROM_CART_REQUEST:
      return {
        ...state,
        currentRemoveListingIds: [...state.currentRemoveListingIds, payload],
        removeFromCartInProgress: true,
      };
    case REMOVE_FROM_CART_SUCCESS:
      return {
        ...state,
        cartResultIds: payload,
        removeFromCartInProgress: false,
      };
    case REMOVE_FROM_CART_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        removeFromCartInProgress: false,
        removeFromCartError: payload,
      };

    default:
      return state;
  }
};

export default cartPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const queryListingsRequest = () => ({
  type: FETCH_LISTINGS_REQUEST,
});
export const queryListingsSuccess = response => ({
  type: FETCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});
export const queryListingsError = e => ({
  type: FETCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const addToCartRequest = () => ({
  type: ADD_TO_CART_REQUEST,
});
export const addToCartSuccess = () => ({
  type: ADD_TO_CART_SUCCESS,
});
export const addToCartError = e => ({
  type: ADD_TO_CART_ERROR,
  error: true,
  payload: e,
});

export const removeFromCartRequest = listingId => ({
  type: REMOVE_FROM_CART_REQUEST,
  payload: listingId,
});
export const removeFromCartSuccess = listingIds => ({
  type: REMOVE_FROM_CART_SUCCESS,
  payload: listingIds,
});
export const removeFromCartError = e => ({
  type: REMOVE_FROM_CART_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryListings = () => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest());

  return sdk.currentUser
    .show()
    .then(res => {
      const cartListingIds = res.data.data.attributes?.profile?.publicData?.cartListingIds;
      const queryCartListingIds = cartListingIds ? cartListingIds : [];

      // Listing query params
      const params = {
        ids: queryCartListingIds,
        minStock: MINIMAL_LISTING_STOCK,
        include: ['author', 'author.profileImage', 'images'],
        'fields.listing': ['title', 'price', 'publicData'],
        'fields.image': [
          // Listings
          'variants.landscape-crop',
          'variants.landscape-crop2x',
          'variants.landscape-crop4x',
          'variants.landscape-crop6x',
          'variants.portrait-crop',

          // Avatars
          'variants.square-small',
          'variants.square-small2x',
        ],
        'limit.images': 1,
        'imageVariant.portrait-crop': sdkUtil.objectQueryString({
          w: 140,
          h: 120,
          fit: 'crop',
        }),
      };

      // Query all listings that has
      // currentUser cart listingIds
      return sdk.listings
        .query(params)
        .then(response => {
          dispatch(addMarketplaceEntities(response));
          dispatch(queryListingsSuccess(response));

          return response;
        })
        .catch(e => {
          dispatch(queryListingsError(storableError(e)));
          throw e;
        });
    })
    .catch(e => dispatch(queryListingsError(storableError(e))));
};

export const addToCart = listingId => (dispatch, getState, sdk) => {
  if (!listingId) {
    return null;
  }

  dispatch(addToCartRequest());

  // Show the currentUser
  return sdk.currentUser
    .show()
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      const currentUser = entities?.[0];
      const cartListingIdsFromPublicData =
        currentUser.attributes.profile.publicData?.cartListingIds;

      let cartListingIds = [];
      if (cartListingIdsFromPublicData) {
        cartListingIds = [...cartListingIdsFromPublicData, listingId];
      } else {
        cartListingIds = [listingId];
      }

      // Update the currentUser
      return sdk.currentUser
        .updateProfile({
          publicData: {
            cartListingIds,
          },
        })
        .then(updatedUserResponse => {
          dispatch(addMarketplaceEntities(updatedUserResponse));
          dispatch(fetchCurrentUserCartListings());
          dispatch(addToCartSuccess());
        })
        .catch(e => dispatch(addToCartError(storableError(e))));
    })
    .catch(e => dispatch(addToCartError(storableError(e))));
};

export const removeFromCart = listingId => (dispatch, getState, sdk) => {
  dispatch(removeFromCartRequest(listingId));

  const { cartResultIds: prevCartResultIds } = getState().CartPage;

  // Result ids
  const cartResultIds = prevCartResultIds.filter(id => id.uuid !== listingId);
  const cartListingIds = cartResultIds?.map(id => id.uuid);

  return sdk.currentUser
    .updateProfile({
      publicData: {
        cartListingIds,
      },
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchCurrentUserCartListings());
      dispatch(removeFromCartSuccess(cartResultIds));
    })
    .catch(e => dispatch(removeFromCartError(storableError(e))));
};

export const loadData = () => {
  return queryListings();
};
