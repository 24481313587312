import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureOwnListing } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import config from '../../config';
import {
  InlineTextButton,
  IconSpinner,
  ResponsiveImage,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
} from '../../components';

import MenuIcon from './MenuIcon';
import Overlay from './Overlay';
import css from './WishListCard.module.css';

const MENU_CONTENT_OFFSET = -12;
const MAX_LENGTH_FOR_WORDS_IN_TITLE = 7;
const NO_LISTING_STOCK = 0;
const RENT_PREFERENCE = 'rent';

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'WishListCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'WishListCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const createListingURL = (routes, listing) => {
  const id = listing.id.uuid;
  const slug = createSlug(listing.attributes.title);

  const linkProps = {
    name: 'ListingPage',
    params: { id, slug },
  };

  return createResourceLocatorString(linkProps.name, routes, linkProps.params, {});
};

// Cards are not fixed sizes - So, long words in title make flexboxed items to grow too big.
// 1. We split title to an array of words and spaces.
//    "foo bar".split(/([^\s]+)/gi) => ["", "foo", " ", "bar", ""]
// 2. Then we break long words by adding a '<span>' with word-break: 'break-all';
const formatTitle = (title, maxLength) => {
  const nonWhiteSpaceSequence = /([^\s]+)/gi;
  return title.split(nonWhiteSpaceSequence).map((word, index) => {
    return word.length > maxLength ? (
      <span key={index} style={{ wordBreak: 'break-all' }}>
        {word}
      </span>
    ) : (
      word
    );
  });
};

export const WishListCardComponent = props => {
  const {
    className,
    rootClassName,
    history,
    intl,
    isMenuOpen,
    actionsInProgressListingId,
    listing,
    renderSizes,
    onAddToWishList,
    addToWishListError,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price } = currentListing.attributes;
  const { quantity: listingStock } = currentListing?.currentStock?.attributes || {};
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const menuItemClasses = classNames(css.menuItem, {
    [css.menuItemDisabled]: !!actionsInProgressListingId,
  });

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const hasError = addToWishListError;
  const thisListingInProgress =
    actionsInProgressListingId && actionsInProgressListingId.uuid === id;

  const onOverListingLink = () => {
    // Enforce preloading of ListingPage (loadable component)
    const { component: Page } = findRouteByRouteName('ListingPage', routeConfiguration());
    // Loadable Component has a "preload" function.
    if (Page.preload) {
      Page.preload();
    }
  };

  const isListingSold = listingStock === NO_LISTING_STOCK;

  return (
    <div className={classes}>
      <div
        className={css.threeToTwoWrapper}
        tabIndex={0}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();

          // WishListCard contains links, buttons and elements that are working with routing.
          // This card doesn't work if <a> or <button> is used to wrap events that are card 'clicks'.
          //
          // NOTE: It might be better to absolute-position those buttons over a card-links.
          // (So, that they have no parent-child relationship - like '<a>bla<a>blaa</a></a>')
          history.push({
            pathname: createListingURL(routeConfiguration(), listing),
            state: {
              prevPath: {
                page: 'WishListPage',
              },
            },
          });
        }}
        onMouseOver={onOverListingLink}
        onTouchStart={onOverListingLink}
      >
        <div className={css.aspectWrapper}>
          <ResponsiveImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
          <div
            className={classNames(css.menuOverlayWrapper, { [css.menuOverlayOpen]: isMenuOpen })}
          >
            <div className={classNames(css.menuOverlay)} />
            <div className={css.menuOverlayContent}>
              <FormattedMessage id="ManageListingCard.viewListing" />
            </div>
          </div>
          <div className={css.menubarWrapper}>
            <div className={css.menubarGradient} />
            <div className={css.menubar}>
              <Menu
                className={classNames(css.menu, { [css.cardIsOpen]: !isMenuOpen })}
                contentPlacementOffset={MENU_CONTENT_OFFSET}
                contentPosition="left"
                useArrow={false}
              >
                <MenuLabel className={css.menuLabel} isOpenClassName={css.listingMenuIsOpen}>
                  <div className={css.iconWrapper}>
                    <MenuIcon className={css.menuIcon} isActive={isMenuOpen} />
                  </div>
                </MenuLabel>
                <MenuContent rootClassName={css.menuContent}>
                  <MenuItem key="remove-wishlist">
                    <InlineTextButton
                      rootClassName={menuItemClasses}
                      onClick={event => {
                        event.preventDefault();
                        event.stopPropagation();
                        onAddToWishList(id);
                      }}
                    >
                      <FormattedMessage id="WishListPage.removeWishList" />
                    </InlineTextButton>
                  </MenuItem>
                </MenuContent>
              </Menu>
            </div>
          </div>
        </div>
        <div className={classNames(css.menuOverlayWrapper, { [css.menuOverlayOpen]: isMenuOpen })}>
          <div className={classNames(css.menuOverlay)} />
          <div className={css.menuOverlayContent}>
            <FormattedMessage id="WishListCard.viewListing" />
          </div>
        </div>

        {isListingSold ? (
          <Overlay
            message={intl.formatMessage(
              {
                id: 'WishListCard.soldListing',
              },
              { listingTitle: title }
            )}
          />
        ) : null}
        {thisListingInProgress ? (
          <Overlay>
            <IconSpinner />
          </Overlay>
        ) : hasError ? (
          <Overlay errorMessage={intl.formatMessage({ id: 'WishListCard.actionFailed' })} />
        ) : null}
      </div>

      <div className={css.info}>
        <div className={css.price}>
          {formattedPrice ? (
            <React.Fragment>
              <div className={css.priceValue} title={priceTitle}>
                {formattedPrice}
              </div>
            </React.Fragment>
          ) : (
            <div className={css.noPrice}>
              <FormattedMessage id="WishListCard.priceNotSet" />
            </div>
          )}
        </div>
        <div className={css.mainInfo}>
          <div className={css.titleWrapper}>
            <InlineTextButton
              rootClassName={css.title}
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                history.push(createListingURL(routeConfiguration(), listing));
              }}
            >
              {formatTitle(title, MAX_LENGTH_FOR_WORDS_IN_TITLE)}
            </InlineTextButton>
          </div>
        </div>
      </div>
    </div>
  );
};

WishListCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  actionsInProgressListingId: null,
  renderSizes: null,
};

const { func, shape, string } = PropTypes;

WishListCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  actionsInProgressListingId: shape({ uuid: string.isRequired }),

  // Responsive image sizes hint
  renderSizes: string,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(WishListCardComponent);
