import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, PrimaryButton, FieldTextInput } from '../../components';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';

import css from './MailchimpForm.module.css';

const MailchimpFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        values,
        intl,
        mailchimpError,
        isNewsletterSubmitted,
      } = fieldRenderProps;

      const emailPlaceholder = intl.formatMessage({
        id: 'MailchimpForm.emailPlaceholder',
      });

      const emailFromValues = !!values.email;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = submitInProgress || !emailFromValues;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.wrapper}>
            <FieldTextInput
              className={css.field}
              inputClassName={css.input}
              type="email"
              name="email"
              id={formId ? `${formId}.email` : 'email'}
              placeholder={emailPlaceholder}
            />
            <div className={css.submitButtonWrapper}>
              <PrimaryButton
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
              >
                <FormattedMessage id="MailchimpForm.submitButtonText" />
              </PrimaryButton>
            </div>
          </div>
          {!mailchimpError && isNewsletterSubmitted ? (
            <p className={css.success}>
              <FormattedMessage id="MailchimpForm.mailchimpSuccess" />
            </p>
          ) : null}
          {mailchimpError ? (
            <p className={css.error}>
              <FormattedMessage id="MailchimpForm.mailchimpError" />
            </p>
          ) : null}
        </Form>
      );
    }}
  />
);

MailchimpFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  mailchimpError: null,
};

MailchimpFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  inProgress: bool,
  mailchimpError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,
};

const MailchimpForm = compose(injectIntl)(MailchimpFormComponent);

MailchimpForm.displayName = 'MailchimpForm';

export default MailchimpForm;
