import React from 'react';
import { NamedLink, IconSpinner } from '../../components';
import { brandsList } from '../../brands-list';
import classNames from 'classnames';

import css from './TopbarMobileMenu.module.css';

const POPULAR_BRANDS_OPTION_KEY = 'popular-brands';

const BrandMenuAccordionItem = ({ brand }) => {
  const option = {
    key: brand,
    label: brandsList.find(b => b.key === brand)?.label,
    search: `?pub_brand=${brand}`,
  };
  return (
    <NamedLink
      className={css.accordionSubItemLink}
      name="SearchPage"
      to={{ search: option.search }}
    >
      {option.label}
    </NamedLink>
  );
};

const MenuAccordionSubItem = props => {
  const {
    item,
    activeSubItemKey,
    setActiveSubItemKey,
    onFetchPopularBrands,
    popularBrands,
    fetchPopularBrandsInProgress,
  } = props;

  const isActiveSubItemKey = activeSubItemKey === item.key;
  const isPopularBrands = item.key === POPULAR_BRANDS_OPTION_KEY;
  const hasPopularBrands = popularBrands?.length > 0;

  // Toggle active sub-menu item
  const toggleActiveSubItem = key => {
    if (isActiveSubItemKey) {
      setActiveSubItemKey(null);
    } else {
      setActiveSubItemKey(key);
    }

    if (isPopularBrands && !hasPopularBrands) {
      onFetchPopularBrands();
    }
  };

  const subMenuClasses = classNames(css.accordionSubItem, {
    [css.activeAccordionSubItem]: isActiveSubItemKey,
  });

  // We need to render the popular brands a bit different
  const showPopularBrands = !fetchPopularBrandsInProgress && hasPopularBrands;
  const renderPopularBrands = showPopularBrands ? (
    popularBrands?.map(brand => {
      return <BrandMenuAccordionItem key={brand} brand={brand} />;
    })
  ) : (
    <div className={css.accordionSubItemLink}>
      <IconSpinner className={css.loadingIcon} />
    </div>
  );

  return (
    <div className={subMenuClasses}>
      <div className={css.accordionSubItemNav} onClick={() => toggleActiveSubItem(item.key)}>
        <span className={css.subItemLabel}>{item.label}</span>
        <span className={css.plusIcon} />
      </div>
      {isActiveSubItemKey &&
        (isPopularBrands
          ? renderPopularBrands
          : item.options.map(subItem => {
              if (subItem.key === 'bike') {
                return null;
              }
              return (
                <NamedLink
                  className={css.accordionSubItemLink}
                  key={subItem.key}
                  name="SearchPage"
                  to={{ search: subItem.search }}
                >
                  {subItem.label}
                </NamedLink>
              );
            }))}
    </div>
  );
};

export default MenuAccordionSubItem;
