/**
 * This file imports generic single fields inside ./default-listing-fields.js.
 *
 * Generic single fields are fields that in general doesn't change and has same
 * values. They are used throughout all sub-categories.
 */

import { brandsList } from '../../brands-list';
import { brandsListBox } from '../../brands-list-box';

const brandField = {
  key: 'brand',
  labelMsgId: 'MarketplaceConfig.brandLabel',
  placeholderMsgId: 'MarketplaceConfig.brandPlaceholder',
  type: 'select',
  values: brandsList,
  required: true,
};

const brandFieldBox = {
  key: 'brand',
  labelMsgId: 'MarketplaceConfig.brandLabel',
  placeholderMsgId: 'MarketplaceConfig.brandPlaceholder',
  type: 'select',
  values: brandsListBox,
  required: true,
};

const genderField = {
  key: 'gender',
  labelMsgId: 'MarketplaceConfig.genderLabel',
  placeholderMsgId: 'MarketplaceConfig.genderPlaceholder',
  type: 'select',
  values: [
    { key: 'man', label: 'Man' },
    { key: 'woman', label: 'Woman' },
    { key: 'unisex', label: 'Unisex' },
    { key: 'kid', label: 'Kid' },
  ],
  required: true,
};

const sizeField = {
  key: 'size',
  labelMsgId: 'MarketplaceConfig.sizeLabel',
  placeholderMsgId: 'MarketplaceConfig.sizePlaceholder',
  type: 'text',
  required: true,
};

const clothesSizeField = {
  key: 'clothes-size',
  labelMsgId: 'MarketplaceConfig.sizeLabel',
  placeholderMsgId: 'MarketplaceConfig.sizePlaceholder',
  type: 'select',
  values: [
    {
      key: 'xxs',
      label: 'XXS',
    },
    {
      key: 'xs',
      label: 'XS',
    },
    {
      key: 's',
      label: 'S',
    },
    {
      key: 's/m',
      label: 'S/M',
    },
    {
      key: 'm',
      label: 'M',
    },
    {
      key: 'm/l',
      label: 'M/L',
    },
    {
      key: 'l',
      label: 'L',
    },
    {
      key: 'l/xl',
      label: 'L/XL',
    },
    {
      key: 'xl',
      label: 'XL',
    },
    {
      key: 'xxl',
      label: 'XXL',
    },
    {
      key: 'xxxl',
      label: 'XXXL',
    },
    {
      key: 'kids',
      label: 'Kids',
    },
    {
      key: 'one-size',
      label: 'One Size',
    },
    {
      key: 'other',
      label: 'Other',
    },
  ],
  required: true,
};

const frameSizeField = {
  key: 'frame-size',
  labelMsgId: 'MarketplaceConfig.frameSizeLabel',
  placeholderMsgId: 'MarketplaceConfig.frameSizePlaceholder',
  type: 'select',
  values: [
    {
      key: 'xs',
      label: 'XS',
    },
    {
      key: 's',
      label: 'S',
    },
    {
      key: 'm',
      label: 'M',
    },
    {
      key: 'l',
      label: 'L',
    },
    {
      key: 'xl',
      label: 'XL',
    },
    {
      key: 'xxl',
      label: 'XXL',
    },
    {
      key: '44-cm',
      label: '44cm',
    },
    {
      key: '47-cm',
      label: '47cm',
    },
    {
      key: '48-cm',
      label: '48cm',
    },
    {
      key: '50-cm',
      label: '50cm',
    },
    {
      key: '51-cm',
      label: '51cm',
    },
    {
      key: '52-cm',
      label: '52cm',
    },
    {
      key: '53-cm',
      label: '53cm',
    },
    {
      key: '54-cm',
      label: '54cm',
    },
    {
      key: '55-cm',
      label: '55cm',
    },
    {
      key: '56-cm',
      label: '56cm',
    },
    {
      key: '57-cm',
      label: '57cm',
    },
    {
      key: '58-cm',
      label: '58cm',
    },
    {
      key: '59-cm',
      label: '59cm',
    },
    {
      key: '60-cm',
      label: '60cm',
    },
    {
      key: '61-cm',
      label: '61cm',
    },
    {
      key: '62-cm',
      label: '62cm',
    },
    {
      key: '63-cm',
      label: '63cm',
    },
    {
      key: '64-cm',
      label: '64cm',
    },
    {
      key: 'kids',
      label: 'Kids',
    },
    {
      key: 'other',
      label: 'Other',
    },
  ],
  required: true,
};

const clothesShoesSizeField = {
  key: 'clothes-size',
  labelMsgId: 'MarketplaceConfig.sizeLabel',
  placeholderMsgId: 'MarketplaceConfig.sizePlaceholder',
  type: 'select',
  values: [
    {
      key: 'uk_3',
      label: 'UK 3',
    },
    {
      key: 'uk_3.5',
      label: 'UK 3.5',
    },
    {
      key: 'uk_4',
      label: 'UK 4',
    },
    {
      key: 'uk_4.5',
      label: 'UK 4.5',
    },
    {
      key: 'uk_5',
      label: 'UK 5',
    },
    {
      key: 'uk_5.5',
      label: 'UK 5.5',
    },
    {
      key: 'uk_6',
      label: 'UK 6',
    },
    {
      key: 'uk_6.5',
      label: 'UK 6.5',
    },
    {
      key: 'uk_7',
      label: 'UK 7',
    },
    {
      key: 'uk_7.5',
      label: 'UK 7.5',
    },
    {
      key: 'uk_8',
      label: 'UK 8',
    },
    {
      key: 'uk_8.5',
      label: 'UK 8.5',
    },
    {
      key: 'uk_9',
      label: 'UK 9',
    },
    {
      key: 'uk_9.5',
      label: 'UK 9.5',
    },
    {
      key: 'uk_10',
      label: 'UK 10',
    },
    {
      key: 'uk_10.5',
      label: 'UK 10.5',
    },
    {
      key: 'uk_11',
      label: 'UK 11',
    },
    {
      key: 'uk_11.5',
      label: 'UK 11.5',
    },
    {
      key: 'uk_12',
      label: 'UK 12',
    },
    {
      key: 'uk_12.5',
      label: 'UK 12.5',
    },
    {
      key: 'uk_13',
      label: 'UK 13',
    },
    {
      key: 'uk_13.5',
      label: 'UK 13.5',
    },
    {
      key: 'uk_14',
      label: 'UK 14',
    },
    {
      key: 'eu_36',
      label: 'EU 36',
    },
    {
      key: 'eu_36.5',
      label: 'EU 36.5',
    },
    {
      key: 'eu_37',
      label: 'EU 37',
    },
    {
      key: 'eu_37.5',
      label: 'EU 37.5',
    },
    {
      key: 'eu_38',
      label: 'EU 38',
    },
    {
      key: 'eu_38.5',
      label: 'EU 38.5',
    },
    {
      key: 'eu_39',
      label: 'EU 39',
    },
    {
      key: 'eu_39.5',
      label: 'EU 39.5',
    },
    {
      key: 'eu_40',
      label: 'EU 40',
    },
    {
      key: 'eu_40.5',
      label: 'EU 40.5',
    },
    {
      key: 'eu_41',
      label: 'EU 41',
    },
    {
      key: 'eu_41.5',
      label: 'EU 41.5',
    },
    {
      key: 'eu_42',
      label: 'EU 42',
    },
    {
      key: 'eu_42.5',
      label: 'EU 42.5',
    },
    {
      key: 'eu_43',
      label: 'EU 43',
    },
    {
      key: 'eu_43.5',
      label: 'EU 43.5',
    },
    {
      key: 'eu_44',
      label: 'EU 44',
    },
    {
      key: 'eu_44.5',
      label: 'EU 44.5',
    },
    {
      key: 'eu_45',
      label: 'EU 45',
    },
    {
      key: 'eu_45.5',
      label: 'EU 45.5',
    },
    {
      key: 'eu_46',
      label: 'EU 46',
    },
    {
      key: 'eu_46.5',
      label: 'EU 46.5',
    },
    {
      key: 'eu_47',
      label: 'EU 47',
    },
    {
      key: 'kids',
      label: 'Kids',
    },
    {
      key: 'one-size',
      label: 'One Size',
    },
    {
      key: 'other',
      label: 'Other',
    },
  ],
  required: true,
};

const colorField = {
  key: 'color',
  labelMsgId: 'MarketplaceConfig.colorLabel',
  placeholderMsgId: 'MarketplaceConfig.colorPlaceholder',
  type: 'select',
  values: [
    {
      key: 'white',
      label: 'White',
    },
    {
      key: 'black',
      label: 'Black',
    },
    {
      key: 'yellow',
      label: 'Yellow',
    },
    {
      key: 'red',
      label: 'Red',
    },
    {
      key: 'blue',
      label: 'Blue',
    },
    {
      key: 'green',
      label: 'Green',
    },
    {
      key: 'brown',
      label: 'Brown',
    },
    {
      key: 'pink',
      label: 'Pink',
    },
    {
      key: 'orange',
      label: 'Orange',
    },
    {
      key: 'purple',
      label: 'Purple',
    },
  ],
};

const skuField = {
  key: 'sku',
  labelMsgId: 'MarketplaceConfig.skuLabel',
  placeholderMsgId: 'MarketplaceConfig.skuPlaceholder',
  type: 'text',
};

const conditionField = {
  key: 'condition',
  labelMsgId: 'MarketplaceConfig.condiitonLabel',
  placeholderMsgId: 'MarketplaceConfig.conditionPlaceholder',
  type: 'select',
  values: [
    { key: 'medium', label: 'Medium' },
    { key: 'good', label: 'Good' },
    { key: 'very-good', label: 'Very good' },
    { key: 'new', label: 'New' },
  ],
  required: true,
};

export {
  brandField,
  brandFieldBox,
  genderField,
  sizeField,
  clothesSizeField,
  frameSizeField,
  clothesShoesSizeField,
  colorField,
  skuField,
  conditionField,
};
