import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconStravaProfile = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName, className);
  return (
    <svg
      className={classes}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
    >
      <path d="M0 0h16v16H0z" fill="#fc4c02" />
      <g fill="#fff" fillRule="evenodd">
        <path d="M6.9 8.8l2.5 4.5 2.4-4.5h-1.5l-.9 1.7-1-1.7z" opacity={0.6} />
        <path d="M7.2 2.5l3.1 6.3H4zm0 3.8l1.2 2.5H5.9z" />
      </g>
    </svg>
  );
};

IconStravaProfile.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconStravaProfile.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconStravaProfile;
