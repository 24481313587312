import React from 'react';
import { string, func } from 'prop-types';
import { intlShape, FormattedMessage } from '../../util/reactIntl';
import { Form, FieldTextInput, FieldSelect, PrimaryButton } from '../../components';
import { Form as FinalForm } from 'react-final-form';
import * as validators from '../../util/validators';
import config from '../../config';
import getCountryCodes from '../../translations/countryCodes';
import classNames from 'classnames';

import css from './ShippingAddressForm.module.css';

export const ShippingAddressFields = props => {
  const { rootClassName, className, intl, fieldId, values } = props;
  const classes = classNames(rootClassName || css.root, className);

  const hasRequiredValues = values && (values.addressLine1 || values.postalCode || values.city);

  const optionalText = intl.formatMessage({
    id: 'ShippingAddressForm.optionalText',
  });

  const addressLine1Label = intl.formatMessage({
    id: 'ShippingAddressForm.addressLine1Label',
  });
  const addressLine1Placeholder = intl.formatMessage({
    id: 'ShippingAddressForm.addressLine1Placeholder',
  });
  const addressLine1Required = hasRequiredValues
    ? validators.required(
        intl.formatMessage({
          id: 'ShippingAddressForm.addressLine1Required',
        })
      )
    : null;

  const addressLine2Label = intl.formatMessage(
    { id: 'ShippingAddressForm.addressLine2Label' },
    { optionalText: optionalText }
  );

  const addressLine2Placeholder = intl.formatMessage({
    id: 'ShippingAddressForm.addressLine2Placeholder',
  });

  const postalCodeLabel = intl.formatMessage({
    id: 'ShippingAddressForm.postalCodeLabel',
  });
  const postalCodePlaceholder = intl.formatMessage({
    id: 'ShippingAddressForm.postalCodePlaceholder',
  });
  const postalCodeRequired = hasRequiredValues
    ? validators.required(
        intl.formatMessage({
          id: 'ShippingAddressForm.postalCodeRequired',
        })
      )
    : null;

  const cityLabel = intl.formatMessage({
    id: 'ShippingAddressForm.cityLabel',
  });
  const cityPlaceholder = intl.formatMessage({
    id: 'ShippingAddressForm.cityPlaceholder',
  });
  const cityRequired = hasRequiredValues
    ? validators.required(
        intl.formatMessage({
          id: 'ShippingAddressForm.cityRequired',
        })
      )
    : null;

  const stateLabel = intl.formatMessage(
    { id: 'ShippingAddressForm.stateLabel' },
    { optionalText: optionalText }
  );
  const statePlaceholder = intl.formatMessage({
    id: 'ShippingAddressForm.statePlaceholder',
  });

  const countryLabel = intl.formatMessage({
    id: 'ShippingAddressForm.countryLabel',
  });
  const countryPlaceholder = intl.formatMessage({
    id: 'ShippingAddressForm.countryPlaceholder',
  });
  const countryRequired = hasRequiredValues
    ? validators.required(
        intl.formatMessage({
          id: 'ShippingAddressForm.countryRequired',
        })
      )
    : null;

  // Use the language set in config.locale to get the correct translations of the country names
  const countryCodes = getCountryCodes(config.locale);

  return (
    <div className={classes}>
      <div className={css.formRow}>
        <FieldTextInput
          id={`${fieldId}.addressLine1`}
          name={fieldId ? `${fieldId}.addressLine1` : 'addressLine1'}
          className={css.field}
          type="text"
          autoComplete="billing address-line1"
          label={addressLine1Label}
          placeholder={addressLine1Placeholder}
          validate={addressLine1Required}
        />

        <FieldTextInput
          id={`${fieldId}.addressLine2`}
          name={fieldId ? `${fieldId}.addressLine2` : 'addressLine2'}
          className={css.field}
          type="text"
          autoComplete="billing address-line2"
          label={addressLine2Label}
          placeholder={addressLine2Placeholder}
        />
      </div>
      <div className={css.formRow}>
        <FieldTextInput
          id={`${fieldId}.postalCode`}
          name={fieldId ? `${fieldId}.postalCode` : 'postalCode'}
          className={css.field}
          type="text"
          label={postalCodeLabel}
          placeholder={postalCodePlaceholder}
          validate={postalCodeRequired}
        />

        <FieldTextInput
          id={`${fieldId}.city`}
          name={fieldId ? `${fieldId}.city` : 'city'}
          className={css.field}
          type="text"
          autoComplete="billing address-level2"
          label={cityLabel}
          placeholder={cityPlaceholder}
          validate={cityRequired}
        />
      </div>
      <div className={css.formRow}>
        <FieldTextInput
          id={`${fieldId}.state`}
          name={fieldId ? `${fieldId}.state` : 'state'}
          className={css.field}
          type="text"
          autoComplete="billing address-level1"
          label={stateLabel}
          placeholder={statePlaceholder}
        />

        <FieldSelect
          id={`${fieldId}.country`}
          name={fieldId ? `${fieldId}.country` : 'country'}
          className={css.field}
          label={countryLabel}
          validate={countryRequired}
          disabled
        >
          <option disabled value="">
            {countryPlaceholder}
          </option>
          {countryCodes.map(country => {
            return (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            );
          })}
        </FieldSelect>
      </div>
    </div>
  );
};

ShippingAddressFields.defaultProps = {
  rootClassName: null,
  className: null,
  fieldId: null,
};

ShippingAddressFields.propTypes = {
  rootClassName: string,
  className: string,
  fieldId: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ShippingAddressForm = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        intl,
        handleSubmit,
        inProgress,
        fieldId,
        pristine,
        values,
      } = fieldRenderProps;

      const submitDisabled = inProgress || pristine;

      return (
        <Form onSubmit={handleSubmit}>
          <ShippingAddressFields
            intl={intl}
            rootClassName={rootClassName}
            className={className}
            fieldId={fieldId}
            values={values}
          />
          <PrimaryButton className={css.button} inProgress={inProgress} disabled={submitDisabled}>
            <FormattedMessage id="ShippingAddressForm.buttonText" />
          </PrimaryButton>
        </Form>
      );
    }}
  />
);

ShippingAddressForm.defaultProps = {
  rootClassName: null,
  className: null,
  fieldId: null,
};

ShippingAddressForm.propTypes = {
  rootClassName: string,
  className: string,
  handleSubmit: func,
  fieldId: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default ShippingAddressForm;
