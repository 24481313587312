/**
 * This file is used to provide functions that will generate secondary filters
 *
 * Secondary filters are being pulled from the ../fields/listing-categories.js
 *
 * We make sure that we get only the fields that can be filtered out, such as
 * select and checkbox fields. Some of the fields that goes into all categories
 * are being filtered out and generated directly in ../../marketplace-custom-config.js
 */

import { editListingCategories } from '../../marketplace-custom-config';
import { getFiltersFromListingFields } from '../data';
import { brandsList } from '../../brands-list';
import { genderField, conditionField } from '../listing-fields/single-fields';

const DISALLOWED_TYPE = 'text';
const DISALLOWED_FIELDS = [];
const CHECKBOX_GROUP_TYPE = 'checkbox-group';

export const generateSecondaryFilters = (params, intl) => {
  if (!params.preference || !params.category) {
    return null;
  }
  const listingFields = getFiltersFromListingFields(editListingCategories, params);
  const filteredListingFields = listingFields?.filter(
    field =>
      field.required && field.type !== DISALLOWED_TYPE && !DISALLOWED_FIELDS.includes(field.key)
  );

  const generatedFilters = filteredListingFields?.map(field => ({
    id: field.key,
    label: field.labelMsgId
      ? intl.formatMessage({
          id: field.labelMsgId,
        })
      : null,
    type: field.type === CHECKBOX_GROUP_TYPE ? 'SelectMultipleFilter' : 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: [`pub_${field.key}`],
    config: {
      options: field.values,
    },
  }));

  return generatedFilters ? generatedFilters : null;
};

export const defaultSecondaryFilters = [
  {
    id: 'brand',
    label: 'Brand',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_brand'],
    searchType: 'default', // Default filter
    config: {
      options: brandsList,
    },
  },
  {
    id: 'gender',
    label: 'Gender',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_gender'],
    searchType: 'default', // Default filter
    config: {
      options: genderField.values,
    },
  },
  {
    id: 'condition',
    label: 'Condition',
    type: 'SelectSingleFilter',
    group: 'secondary',
    queryParamNames: ['pub_condition'],
    searchType: 'default', // Default filter
    config: {
      options: conditionField.values,
    },
  },
  {
    id: 'features',
    label: 'Features',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_features'],
    searchType: 'default', // Default filter
    config: {
      searchMode: 'has_any',
      options: [
        {
          key: 'repair-kit',
          label: 'Repair kit',
        },
        {
          key: 'front-light',
          label: 'Front light',
        },
        {
          key: 'rear-light',
          label: 'Rear light',
        },
        {
          key: 'pump',
          label: 'Pump',
        },
        {
          key: 'bottle',
          label: 'Bottle',
        },
        {
          key: 'bottle-holder',
          label: 'Bottle holder',
        },
        {
          key: 'shimano-pedals',
          label: 'Shimano pedals',
        },
        {
          key: 'look-pedals',
          label: 'Look pedals',
        },
        {
          key: 'other-pedals',
          label: 'Other pedals',
        },
        {
          key: 'computer-mount',
          label: 'Computer mount',
        },
        {
          key: 'bike-computer-gps',
          label: 'Bike Computer/GPS',
        },
        {
          key: 'saddle-bag',
          label: 'Saddle bag',
        },
        {
          key: 'bell',
          label: 'Bell',
        },
      ],
    },
  },
];
