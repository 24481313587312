import React from 'react';
import { array, object } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink, IconSpinner } from '../../components';
import { findConfigImageForMenuItem } from '../../util/data';
import { brandsList } from '../../brands-list';
import classNames from 'classnames';

import css from './TopbarDropdownMenu.module.css';

const POPULAR_BRANDS_OPTION_KEY = 'popular-brands';

const DropdownItemOption = ({ option }) => {
  return (
    <li key={option.key} className={css.dropdownListItem}>
      <NamedLink className={css.dropdownListLink} name="SearchPage" to={{ search: option.search }}>
        {option.labelMsgId ? <FormattedMessage id={option.labelMsgId} /> : option.label}
      </NamedLink>
    </li>
  );
};

const DropdownBrandOption = ({ brand }) => {
  const option = {
    key: brand,
    label: brandsList.find(b => b.key === brand)?.label,
    search: `?pub_brand=${brand}`,
  };
  return (
    <li key={option.key} className={css.dropdownListItem}>
      <NamedLink className={css.dropdownListLink} name="SearchPage" to={{ search: option.search }}>
        {option.label}
      </NamedLink>
    </li>
  );
};

const DropdownImage = ({ page, search, src, alt }) => {
  return (
    <NamedLink name={page} to={{ search }}>
      <img className={css.dropdownImage} src={src} alt={alt} />
    </NamedLink>
  );
};

const Dropdown = props => {
  const { item: dropdownItem, dropdownConfig, popularBrands, fetchPopularBrandsInProgress } = props;
  const { label } = dropdownItem;

  // Images
  const dropdownImages = findConfigImageForMenuItem(dropdownItem);

  // Use of two columns
  const twoColumns = dropdownConfig.length === 2;

  return (
    <div className={classNames(css.dropdown, { [css.twoColumnsDropdown]: !!twoColumns })}>
      <div
        className={classNames(css.dropdownContent, {
          [css.twoColumnsDropdownContent]: !!twoColumns,
        })}
      >
        {dropdownConfig?.map(i => {
          const { options: itemOptions } = i;
          const isPopularBrands = i.key === POPULAR_BRANDS_OPTION_KEY;
          const showPopularBrands = popularBrands?.length > 0;

          // We need to render the popular brands a bit different
          const renderPopularBrands = !fetchPopularBrandsInProgress ? (
            showPopularBrands &&
            popularBrands?.map(brand => {
              return <DropdownBrandOption key={brand} brand={brand} />;
            })
          ) : (
            <IconSpinner className={css.loadingIcon} />
          );

          return (
            <div key={i.key} className={css.dropdownItem}>
              <div className={css.dropdownItemLabel}>{i.label}</div>
              <ul className={css.dropdownList}>
                {isPopularBrands
                  ? renderPopularBrands
                  : itemOptions.map(option => {
                      return <DropdownItemOption key={option.key} option={option} />;
                    })}
              </ul>
            </div>
          );
        })}
      </div>
      <div
        className={classNames(css.dropdownImageContainer, {
          [css.twoColumnsDropdownImageContainer]: !!twoColumns,
        })}
      >
        {dropdownImages.map(({ imagePage, imageSearch, imageSrc }, imageIndex) => {
          return (
            <DropdownImage
              key={imageIndex}
              page={imagePage}
              search={imageSearch}
              src={imageSrc}
              alt={label}
            />
          );
        })}
      </div>
    </div>
  );
};

Dropdown.defaulProps = {
  item: null,
  dropdownConfig: [],
};

Dropdown.propTypes = {
  item: object.isRequired,
  dropdownConfig: array.isRequired,
};

export default Dropdown;
