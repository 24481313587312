import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { IconLongArrow } from '../../components';
import classNames from 'classnames';
import Slider from 'react-slick';

import { default as sliderItems } from './items';

import css from './SectionHowItWorks.module.css';

// Slider arrow component
const SliderArrow = ({ className, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <IconLongArrow className={css.sliderArrowIcon} />
    </div>
  );
};

// Slider item component
const SliderItem = ({ slider, item }) => {
  const gradient = 'linear-gradient(-45deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3))';
  const backgroundStyle = item.imageSrc
    ? {
        backgroundImage: `${gradient}, url(${item.imageSrc})`,
      }
    : { backgroundColor: item.backgroundColor };
  return (
    <div className={css.sliderItemContainer}>
      <div className={css.sliderItem} style={{ ...backgroundStyle }}>
        <div className={css.itemGrid}>
          <div className={css.leftContent}>
            <div className={css.itemOrder}>{`${item.id} / ${sliderItems.length}`}</div>
            <h1 className={css.itemTitle}>
              <FormattedMessage id={item.titleMsgId} />
            </h1>
          </div>
          <div className={css.rightContent}>
            <p className={css.itemText}>
              <FormattedMessage id={item.textMsgId} />
            </p>
            <div className={css.sliderArrows}>
              <SliderArrow
                className={css.sliderArrow}
                onClick={() => slider?.current?.slickPrev()}
              />
              <SliderArrow
                className={classNames(css.sliderArrow, css.sliderNextArrow)}
                onClick={() => slider?.current?.slickNext()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Slider Settings
const sliderSettings = {
  className: classNames(css.slider, 'howItWorksSlider'),
  dots: false,
  arrows: false,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const SectionHowItWorks = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  const slider = useRef(null);

  return (
    <div className={classes}>
      <Slider ref={slider} {...sliderSettings}>
        {sliderItems.map(i => {
          return <SliderItem key={i.id} slider={slider} item={i} />;
        })}
      </Slider>
    </div>
  );
};

SectionHowItWorks.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHowItWorks;
