import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconHelp.module.css';

const IconHelp = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      viewBox="0 0 24 24"
    >
      <g transform="scale(1.71429)">
        <circle cx={7} cy={7} r={6.5} />
        <path d="M5.5 5.5A1.5 1.5 0 1 1 7 7v1" />
        <path d="M7 9.5a.75.75 0 1 0 .75.75A.76.76 0 0 0 7 9.5Z" />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconHelp.defaultProps = {
  className: null,
  rootClassName: null,
};

IconHelp.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconHelp;
