import React from 'react';
import css from './IconFrance.module.css';

const IconFrance = props => {
  return (
    <svg
      className={css.icon}
      height="18"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      viewBox="0 0 512 512"
      {...props}
    >
      <path fill="#f0f0f0" d="M0 85.331h512v341.337H0z" />
      <path fill="#0052b4" d="M0 85.331h170.663v341.337H0z" />
      <path fill="#d80027" d="M341.337 85.331H512v341.337H341.337z" />
    </svg>
  );
};

export default IconFrance;
