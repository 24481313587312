import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconLocation.module.css';

const IconLocation = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      height="12"
      width="12"
      viewBox="0 0 24 24"
    >
      <g transform="scale(1.71429)">
        <path
          d="M11.5 5c0 2.49-4.5 8.5-4.5 8.5S2.5 7.49 2.5 5a4.5 4.5 0 0 1 9 0Z"
          style={{
            fill: 'none',
            stroke: '#4a4a4a',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
        <circle
          cx={7}
          cy={5}
          r={1.5}
          style={{
            fill: 'none',
            stroke: '#4a4a4a',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
        />
      </g>
    </svg>
  );
};

const { string } = PropTypes;

IconLocation.defaultProps = {
  className: null,
  rootClassName: null,
};

IconLocation.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconLocation;
