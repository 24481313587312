import React, { useState } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './StockFilter.module.css';

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

const StockFilter = props => {
  const { className, rootClassName, queryParamNames, initialValues, label } = props;
  const classes = classNames(rootClassName || css.root, className);

  const queryParamName = getQueryParamName(queryParamNames);
  const initialValue =
    initialValues && initialValues[queryParamNames] ? initialValues[queryParamNames] : null;
  const isStockUndefined = typeof stockParam === undefined || typeof stockParam === 'undefined';

  const defaultOpenValue = isStockUndefined ? true : initialValue === 1 ? true : false;
  const [isOpen, setOpen] = useState(defaultOpenValue);
  const onSelect = () => {
    setOpen(!isOpen);
    props.onSelect({ [queryParamName]: isOpen ? 1 : 0 });
  };
  return (
    <div className={classes}>
      <button
        onClick={onSelect}
        className={classNames(css.stockFilterButton, { [css.stockFilterButtonSelected]: isOpen })}
      >
        {label}
      </button>
    </div>
  );
};

StockFilter.defaultProps = {
  className: null,
  rootClassName: null,
  label: null,
};

StockFilter.propTypes = {
  className: string,
  rootClassName: string,
  label: string,
};

export default StockFilter;
