import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  IconSocialMediaStrava,
  IconVisa,
  IconMastercard,
  IconAmex,
  ExternalLink,
  NamedLink,
  Newsletter,
  LanguagePicker,
} from '../../components';

import css from './Footer.module.css';

const withBorderPageNames = [
  'InboxPage',
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
  'PrivacyPolicyPage',
  'TermsOfServicePage',
];

const siteCompanyInformationPage = config.siteCompanyInformationPage;

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle, siteStravaPage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });
  const goToStrava = intl.formatMessage({ id: 'Footer.goToStrava' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.instagramIcon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;

  const stravaLink = siteStravaPage ? (
    <ExternalLink
      key="linkToStrava"
      href={siteStravaPage}
      className={classNames(css.icon, css.stravaIcon)}
      title={goToStrava}
    >
      <IconSocialMediaStrava className={css.strava} />
    </ExternalLink>
  ) : null;
  return [fbLink, twitterLink, instragramLink, stravaLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl, showNewsletter, currentPage } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className, {
    [css.rootWithBorder]: withBorderPageNames.includes(currentPage),
  });

  return (
    <>
      {showNewsletter ? <Newsletter /> : null}
      <div className={classes}>
        <div className={css.content}>
          <div className={css.links}>
            <ul className={css.list}>
              <li className={css.listItem}>
                <span className={css.listTitle}>
                  <FormattedMessage id="Footer.firstListTitle" />
                </span>
              </li>
              <li className={css.listItem}>
                <NamedLink name="AboutPage" className={css.link}>
                  <FormattedMessage id="Footer.toAboutPage" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="SupportPage" className={css.link}>
                  <FormattedMessage id="Footer.toFAQPage" />
                </NamedLink>
              </li>
              {/* <li className={css.listItem}>
                <NamedLink name="PressPage" className={css.link}>
                  <FormattedMessage id="Footer.toPressPage" />
                </NamedLink>
              </li> */}
              <li className={css.listItem}>
                <NamedLink name="ContactPage" className={css.link}>
                  <FormattedMessage id="Footer.toContactPage" />
                </NamedLink>
              </li>
            </ul>
            <ul className={css.list}>
              <li className={css.listItem}>
                <span className={css.listTitle}>
                  <FormattedMessage id="Footer.secondListTitle" />
                </span>
              </li>
              <li className={css.listItem}>
                <NamedLink
                  name="TipsSupportPage"
                  params={{ slug: 'first-article' }}
                  className={css.link}
                >
                  <FormattedMessage id="Footer.toTipsPage" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink
                  name="ShippingSupportPage"
                  params={{ slug: 'first-article' }}
                  className={css.link}
                >
                  <FormattedMessage id="Footer.toShippingPage" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink
                  name="PaymentSupportPage"
                  params={{ slug: 'first-article' }}
                  className={css.link}
                >
                  <FormattedMessage id="Footer.toPaymentPage" />
                </NamedLink>
              </li>
            </ul>
            <ul className={css.list}>
              <li className={css.listItem}>
                <span className={css.listTitle}>
                  <FormattedMessage id="Footer.thirdListTitle" />
                </span>
              </li>
              <li className={css.listItem}>
                <NamedLink name="PrivacyPolicyPage" className={css.link}>
                  <FormattedMessage id="Footer.privacyPolicy" />
                </NamedLink>
              </li>
              <li className={css.listItem}>
                <NamedLink name="TermsOfServicePage" className={css.link}>
                  <FormattedMessage id="Footer.termsOfUse" />
                </NamedLink>
              </li>
            </ul>
          </div>
          <div className={css.organizationInfo}>
            <div className={css.socialAndLanguageContainer}>
              <div className={css.socialLinks}>{socialMediaLinks}</div>
              <LanguagePicker />
            </div>
            <div className={css.copyrightWrapper}>
              <p className={css.organizationCopyright}>
                <ExternalLink href={siteCompanyInformationPage} className={css.cookieLink}>
                  <FormattedMessage id="Footer.copyright" />
                </ExternalLink>
              </p>

              <div className={css.paymentLinks}>
                <IconVisa className={css.paymentIcon} />
                <IconMastercard className={css.paymentIcon} />
                <IconAmex className={css.paymentIcon} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
