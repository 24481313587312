import React, { useState } from 'react';
import config from '../../config';

import MenuAccordionItem from './MenuAccordionItem';

const BLACKLISTED_MENU_KEYS = ['list-a-product'];

const MenuAccordion = props => {
  const { onFetchPopularBrands, popularBrands, fetchPopularBrandsInProgress } = props;

  const [activeItemKey, setActiveItemKey] = useState(null);
  const [activeSubItemKey, setActiveSubItemKey] = useState(null);

  const menuConfig = config.custom.topbarDropdownMenu.filter(
    i => !BLACKLISTED_MENU_KEYS.includes(i.key)
  );

  return menuConfig.map(item => {
    return (
      <MenuAccordionItem
        key={item.key}
        item={item}
        activeItemKey={activeItemKey}
        setActiveItemKey={setActiveItemKey}
        activeSubItemKey={activeSubItemKey}
        setActiveSubItemKey={setActiveSubItemKey}
        onFetchPopularBrands={onFetchPopularBrands}
        popularBrands={popularBrands}
        fetchPopularBrandsInProgress={fetchPopularBrandsInProgress}
      />
    );
  });
};

export default MenuAccordion;
