import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { IconClose } from '../../components';
import classNames from 'classnames';

import css from './TopbarNotice.module.css';

const DEFAULT_NOTICE_STATE = false;

const TopbarNotice = props => {
  const { rootClassName, className, topbarNoticeVisible, onSetTopbarNoticeVisible } = props;
  const classes = classNames(rootClassName || css.root, className);

  // Handle session cookie
  useEffect(() => {
    const cookies = document.cookie.split('; ').reduce((acc, c) => {
      const [name, value] = c.split('=');
      return { ...acc, [name]: decodeURIComponent(value) };
    }, {});

    if (cookies.showTopbarNotice !== '1') {
      onSetTopbarNoticeVisible(true);
    }
  }, []);

  // Save the session cookie
  const saveCookieConsent = () => {
    document.cookie = 'showTopbarNotice=1; expires=0; path=/';
  };

  return topbarNoticeVisible ? (
    <div className={classes}>
      <p className={css.noticeText}>
        <FormattedMessage id="TopbarNotice.noticeText" />
      </p>
      <div
        className={css.closeIconContainer}
        onClick={() => {
          onSetTopbarNoticeVisible(false);
          saveCookieConsent();
        }}
      >
        <IconClose className={css.closeIcon} />
      </div>
    </div>
  ) : null;
};

TopbarNotice.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

TopbarNotice.propTypes = {
  rootClassName: string,
  className: string,
};

export default TopbarNotice;
