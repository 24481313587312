import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from '../../util/reactIntl';
import { MailchimpForm } from '../../forms';
import { mailchimpSubscribe } from '../../ducks/user.duck';
import classNames from 'classnames';

import css from './Newsletter.module.css';

const NewsletterComponent = props => {
  const {
    rootClassName,
    className,
    onMailchimpSubscribe,
    mailchimpSubscribeInProgress,
    mailchimpSubscribeError,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  // Handle newsletter submission
  const [isNewsletterSubmitted, setNewsletterSubmitted] = useState(false);

  const handleSubmit = (values, form) => {
    const { email } = values;

    // Reset to default
    setNewsletterSubmitted(false);

    onMailchimpSubscribe(email)
      .then(() => {
        setNewsletterSubmitted(true);
        form.reset();
      })
      .catch(e => {
        // do nothing as we handle error with redux
      });
  };

  return (
    <div className={classes}>
      <div className={css.titleWrapper}>
        <h2 className={css.title}>
          <FormattedMessage id="Newsletter.title" />
        </h2>
      </div>
      <div className={css.form}>
        <MailchimpForm
          onSubmit={handleSubmit}
          inProgress={mailchimpSubscribeInProgress}
          mailchimpError={mailchimpSubscribeError}
          isNewsletterSubmitted={isNewsletterSubmitted}
        />
      </div>
    </div>
  );
};

NewsletterComponent.defaultProps = { rootClassName: null, className: null };

const { string, func } = PropTypes;

NewsletterComponent.propTypes = {
  rootClassName: string,
  className: string,
  onMailchimpSubscribe: func.isRequired,
};

const mapStateToProps = state => {
  const { mailchimpSubscribeError, mailchimpSubscribeInProgress } = state.user;

  return {
    mailchimpSubscribeError,
    mailchimpSubscribeInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onMailchimpSubscribe: email => dispatch(mailchimpSubscribe(email)),
});

const Newsletter = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(NewsletterComponent);

export default Newsletter;
