import React from 'react';
import { string } from 'prop-types';
import { ResponsiveImage } from '../../components';
import { formatMoney } from '../../util/currency';
import classNames from 'classnames';

import css from './CartDetailsContainer.module.css';

const ListingCard = ({ intl, listing }) => {
  const { title, price } = listing.attributes;
  const listingTitle = title;
  const listingImages = listing.images;

  const hasImages = listingImages.length > 0;
  const firstImage = hasImages ? listingImages[0] : null;

  // Listing price
  const formattedPrice = price ? formatMoney(intl, price) : null;

  return (
    <div className={css.listingCard}>
      <div className={css.listingImageWrapper}>
        <ResponsiveImage
          rootClassName={css.listingImage}
          alt={listingTitle}
          image={firstImage}
          variants={['portrait-crop']}
        />
      </div>
      <div className={css.listingCardInfo}>
        <div className={css.listingTitle}>{listingTitle}</div>
        <div className={css.priceContainer}>{formattedPrice}</div>
      </div>
    </div>
  );
};

const CartDetailsContainer = props => {
  const { rootClassName, className, cartPurchase, listing, intl, children } = props;
  const otherListings = cartPurchase?.otherListings;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.listingsPanel}>
        <ListingCard intl={intl} listing={listing} />
        {otherListings?.map(l => {
          return <ListingCard key={l.id.uuid} intl={intl} listing={l} />;
        })}
      </div>
      {children}
    </div>
  );
};

CartDetailsContainer.defaultProps = {
  className: null,
  rootClassName: null,
};

CartDetailsContainer.propTypes = {
  className: string,
  rootClassName: string,
};

export default CartDetailsContainer;
