import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { sortFieldsByRequired } from '../../util/data';
import { Button, ListingField, Form, IconArrowHead } from '../../components';
import config from '../../config';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import css from './EditListingFeaturesForm.module.css';

const OPTIONAL_FIELDS_LENGTH = 0;

const EditListingFeaturesFormComponent = props => {
  const { listingFields: allListingFields } = props;
  const listingFields = sortFieldsByRequired(allListingFields);

  const requiredFields = listingFields.filter(field => field?.required);
  const nonRequiredFields = listingFields.filter(field => !field?.required);

  const visibleFields =
    nonRequiredFields?.length > OPTIONAL_FIELDS_LENGTH
      ? listingFields?.slice(0, requiredFields?.length + OPTIONAL_FIELDS_LENGTH)
      : listingFields;

  const [fields, setFields] = useState(visibleFields);
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          disabled,
          ready,
          rootClassName,
          className,
          handleSubmit,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          intl,
        } = formRenderProps;

        const classes = classNames(rootClassName || css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = disabled || submitInProgress;

        const { updateListingError, showListingsError } = fetchErrors || {};
        const errorMessage = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
          </p>
        ) : null;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessage}
            {errorMessageShowListing}

            <div className={css.fields}>
              {fields?.map(field => {
                return field.key ? (
                  <ListingField
                    intl={intl}
                    key={field.key}
                    id={field.key}
                    name={field.key}
                    type={field.type}
                    labelMsgId={field.labelMsgId}
                    placeholderMsgId={field.placeholderMsgId}
                    required={!!field.required}
                    values={field.values}
                  />
                ) : null;
              })}
              {allListingFields?.length !== fields?.length ? (
                <div className={css.showMoreContainer} onClick={() => setFields(listingFields)}>
                  <FormattedMessage
                    id="EditListingFeaturesForm.showMore"
                    values={{ missingFields: nonRequiredFields?.length }}
                  />
                  <IconArrowHead className={css.showMoreIcon} direction="down" />
                </div>
              ) : null}
              {nonRequiredFields?.length > OPTIONAL_FIELDS_LENGTH &&
              allListingFields?.length === fields?.length ? (
                <div className={css.showMoreContainer} onClick={() => setFields(visibleFields)}>
                  <FormattedMessage
                    id="EditListingFeaturesForm.showLess"
                    values={{ missingFields: nonRequiredFields?.length }}
                  />
                  <IconArrowHead className={css.showMoreIcon} direction="up" />
                </div>
              ) : null}
            </div>

            <div className={css.submitButtonWrapper}>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default EditListingFeaturesForm;
