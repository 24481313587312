// You can define custom react-select styles here
// Check https://react-select.com/styles for a reference

export const customStyles = {
  control: base => ({
    ...base,
    boxShadow: 'none',
  }),
  option: (base, state) => ({
    ...base,
    background: state.isSelected ? '#13795a' : '#fff',
    '&:hover': {
      background: '#115a36',
      color: '#fff',
    },
  }),
};
