import React from 'react';
import PropTypes from 'prop-types';
import { IconHelp } from '../../components';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

import css from './Tooltip.module.css';

const Tooltip = props => {
  const {
    rootClassName,
    className,
    tooltipClassName,
    tooltipIconClassName,
    tooltipId,
    tooltipMessage,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const tooltipClasses = classNames(css.tooltip, tooltipClassName);
  const tooltipIconClasses = classNames(css.tooltipIcon, tooltipIconClassName);

  if (!tooltipMessage) {
    throw new Error('Tooltip message is required');
  }
  return (
    <div className={classes} data-tip={tooltipMessage} data-for={tooltipId}>
      <IconHelp className={tooltipIconClasses} />
      <ReactTooltip className={tooltipClasses} id={tooltipId} />
    </div>
  );
};

const { string } = PropTypes;

Tooltip.defaultProps = {
  rootClassName: null,
  className: null,
  tooltipClasses: null,
  tooltipIconClasses: null,

  // Tooltip
  tooltipId: null,
  tooltipMessage: null,
};

Tooltip.propTypes = {
  rootClassName: string,
  className: string,
  tooltipClasses: string,
  tooltipIconClasses: string,

  // Tooltip
  tooltipId: string,
  tooltipMessage: string,
};

export default Tooltip;
