import React from 'react';
import { string, object } from 'prop-types';
import { IconLocation } from '../../components';
import classNames from 'classnames';

import css from './ShippingAddress.module.css';

const ShippingAddress = props => {
  const { rootClassName, className, shippingAddress } = props;
  const classes = classNames(rootClassName || css.root, className);

  const { contactName, email, ...shippingAddressFields } = shippingAddress || {};
  return (
    <div className={classes}>
      <div className={css.locationIconContainer}>
        <IconLocation className={css.locationIcon} />
      </div>
      <ul className={css.shippingAddressList}>
        <li>{contactName}</li>
        <li>{email}</li>
        {Object.keys(shippingAddressFields).map((k, index) => {
          return <li key={index}>{shippingAddressFields[k]}</li>;
        })}
      </ul>
    </div>
  );
};

ShippingAddress.defaultProps = {
  rootClassName: null,
  className: null,
};

ShippingAddress.propTypes = {
  rootClassName: string,
  className: string,
  shippingAddress: object,
};

export default ShippingAddress;
