import React from 'react';
import { NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './StaticPageHeader.module.css';

const ABOUT_PAGE = 'AboutPage';
const SUPPORT_PAGE = 'SupportPage';
const PRESS_PAGE = 'PressPage';
const CONTACT_PAGE = 'ContactPage';

const StaticPageHeader = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    subTitleClassName,
    title,
    subTitle,
    currentPage,
    heroImage,
    largeHeader,
  } = props;

  const classes = rootClassName || classNames(css.root, className);

  const backgroundImageMaybe = heroImage
    ? {
        style: {
          backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${heroImage})`,
        },
      }
    : {};
  return (
    <div className={classes}>
      <div
        className={classNames(css.header, {
          [css.largeHeader]: largeHeader,
          [css.heroImageHeader]: heroImage,
        })}
        {...backgroundImageMaybe}
      >
        <h1 className={classNames(css.headerTitle, titleClassName)}>{title}</h1>
        {subTitle ? (
          <p className={classNames(css.headerSubTitle, subTitleClassName)}>{subTitle}</p>
        ) : null}
      </div>

      <ul className={css.headerLinks}>
        <li className={css.headerLink}>
          <NamedLink
            name={ABOUT_PAGE}
            className={classNames(css.pageLink, {
              [css.active]: currentPage === ABOUT_PAGE,
            })}
          >
            <FormattedMessage id="StaticPageHeader.toAboutPage" />
          </NamedLink>
        </li>
        <li className={css.headerLink}>
          <NamedLink
            name={SUPPORT_PAGE}
            className={classNames(css.pageLink, {
              [css.active]: currentPage === SUPPORT_PAGE,
            })}
          >
            <FormattedMessage id="StaticPageHeader.toSupportPage" />
          </NamedLink>
        </li>
        {/* <li className={css.headerLink}>
          <NamedLink
            name={PRESS_PAGE}
            className={classNames(css.pageLink, {
              [css.active]: currentPage === PRESS_PAGE,
            })}
          >
            <FormattedMessage id="StaticPageHeader.PressPage" />
          </NamedLink>
        </li> */}
        <li className={css.headerLink}>
          <NamedLink
            name={CONTACT_PAGE}
            className={classNames(css.pageLink, {
              [css.active]: currentPage === CONTACT_PAGE,
            })}
          >
            <FormattedMessage id="StaticPageHeader.ContactPage" />
          </NamedLink>
        </li>
      </ul>
    </div>
  );
};

export default StaticPageHeader;
