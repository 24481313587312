/**
 * This file imports default listing fields in inside ../listing-categories.js.
 *
 * We are generating default fields that helps avoiding repeating single fields
 * when the sub-categories from same category hold same fields.
 */

import {
  brandField,
  brandFieldBox,
  genderField,
  colorField,
  skuField,
  clothesSizeField,
  frameSizeField,
  clothesShoesSizeField,
  conditionField,
} from './single-fields';

/* Default clothes listing fields */
const defaultClothesListingFields = [
  brandField,
  genderField,
  conditionField,
  clothesSizeField,
  colorField,
];
const defaultShoesListingFields = [
  brandField,
  genderField,
  conditionField,
  clothesShoesSizeField,
  colorField,
];

/* Default accessories listing fields */
const defaultAccessoriesListingFields = [brandField, conditionField];

/* Default components listing fields */
const defaultComponentsListingFields = [
  brandField,
  {
    key: 'type',
    labelMsgId: 'MarketplaceConfig.typeLabel',
    placeholderMsgId: 'MarketplaceConfig.typePlaceholder',
    type: 'select',
    values: [
      { key: 'bmx', label: 'BMX' },
      { key: 'city', label: 'City' },
      { key: 'cyclocross', label: 'Cyclocross' },
      { key: 'electric', label: 'Electric' },
      { key: 'folding', label: 'Folding' },
      { key: 'gravel', label: 'Gravel' },
      { key: 'mountain', label: 'Mountain' },
      { key: 'road', label: 'Road' },
      { key: 'track', label: 'Track' },
      { key: 'time-trial', label: 'Time trial' },    
      { key: 'other', label: 'Other' },
    ],
    required: true,
  },
  conditionField,
];

/* Default bikes listing fields */
const defaultBikesListingFields = [
  brandField,
  genderField,
  conditionField,
  frameSizeField,
  {
    key: 'type',
    labelMsgId: 'MarketplaceConfig.typeLabel',
    placeholderMsgId: 'MarketplaceConfig.typePlaceholder',
    type: 'select',
    values: [
      { key: 'bmx', label: 'BMX' },
      { key: 'city', label: 'City' },
      { key: 'cyclocross', label: 'Cyclocross' },
      { key: 'electric', label: 'Electric' },
      { key: 'folding', label: 'Folding' },
      { key: 'gravel', label: 'Gravel' },
      { key: 'mountain', label: 'Mountain' },
      { key: 'road', label: 'Road' },
      { key: 'track', label: 'Track' },
      { key: 'time-trial', label: 'Time trial' },    
      { key: 'other', label: 'Other' },
    ],
    required: true,
  },
  {
    key: 'receipt',
    labelMsgId: 'MarketplaceConfig.receiptLabel',
    type: 'checkbox',
    values: [
      {
        key: 'receipt',
        label: 'Receipt',
      },
    ],
  },
  {
    key: 'claimed-weight',
    labelMsgId: 'MarketplaceConfig.claimedWeightLabel',
    placeholderMsgId: 'MarketplaceConfig.claimedWeightPlaceholder',
    type: 'text',
  },
  {
    key: 'frame',
    labelMsgId: 'MarketplaceConfig.frameLabel',
    placeholderMsgId: 'MarketplaceConfig.framePlaceholder',
    type: 'text',
  },
  {
    key: 'fork',
    labelMsgId: 'MarketplaceConfig.forkLabel',
    placeholderMsgId: 'MarketplaceConfig.forkPlaceholder',
    type: 'text',
  },
  {
    key: 'headset',
    labelMsgId: 'MarketplaceConfig.headsetLabel',
    placeholderMsgId: 'MarketplaceConfig.headsetPlaceholder',
    type: 'text',
  },
  {
    key: 'bottom-bracket',
    labelMsgId: 'MarketplaceConfig.bottomBracketLabel',
    placeholderMsgId: 'MarketplaceConfig.bottomBracketPlaceholder',
    type: 'text',
  },
  {
    key: 'Stem',
    labelMsgId: 'MarketplaceConfig.stemLabel',
    placeholderMsgId: 'MarketplaceConfig.stemPlaceholder',
    type: 'text',
  },
  {
    key: 'handlebar',
    labelMsgId: 'MarketplaceConfig.handlebarLabel',
    placeholderMsgId: 'MarketplaceConfig.handlebarPlaceholder',
    type: 'text',
  },
  {
    key: 'front-brake',
    labelMsgId: 'MarketplaceConfig.frontBreakLabel',
    placeholderMsgId: 'MarketplaceConfig.frontBreakPlaceholder',
    type: 'text',
  },
  {
    key: 'rear-brake',
    labelMsgId: 'MarketplaceConfig.rearBreakLabel',
    placeholderMsgId: 'MarketplaceConfig.rearBreakPlaceholder',
    type: 'text',
  },
  {
    key: 'front-rotor',
    labelMsgId: 'MarketplaceConfig.frontRotorLabel',
    placeholderMsgId: 'MarketplaceConfig.frontRotorPlaceholder',
    type: 'text',
  },
  {
    key: 'rear-rotor',
    labelMsgId: 'MarketplaceConfig.rearRotorLabel',
    placeholderMsgId: 'MarketplaceConfig.rearRotorPlaceholder',
    type: 'text',
  },

  {
    key: 'rotor',
    labelMsgId: 'MarketplaceConfig.rotorLabel',
    placeholderMsgId: 'MarketplaceConfig.rotorPlaceholder',
    type: 'select',
    values: [
      { key: '4-bolts', label: '4 Bolts' },
      { key: '6-bolts', label: '6 Bolts' },
      { key: 'center-lock', label: 'Center lock' },
      { key: 'other', label: 'Other' },
      { key: 'none', label: 'None' },
    ],
  },
  {
    key: 'brake-levers',
    labelMsgId: 'MarketplaceConfig.brakeLeversLabel',
    placeholderMsgId: 'MarketplaceConfig.brakeLeversPlaceholder',
    type: 'text',
  },
  {
    key: 'front-derailleur',
    labelMsgId: 'MarketplaceConfig.frontDerailleurLabel',
    placeholderMsgId: 'MarketplaceConfig.frontDerailleurPlaceholder',
    type: 'text',
  },
  {
    key: 'rear-derailleur',
    labelMsgId: 'MarketplaceConfig.rearDerailleurLabel',
    placeholderMsgId: 'MarketplaceConfig.rearDerailleurPlaceholder',
    type: 'text',
  },
  {
    key: 'shift-levers',
    labelMsgId: 'MarketplaceConfig.shiftLeversLabel',
    placeholderMsgId: 'MarketplaceConfig.shiftLeversPlaceholder',
    type: 'text',
  },
  {
    key: 'cassette',
    labelMsgId: 'MarketplaceConfig.cassetteLabel',
    placeholderMsgId: 'MarketplaceConfig.cassettePlaceholder',
    type: 'text',
  },
  {
    key: 'crankset',
    labelMsgId: 'MarketplaceConfig.cranksetLabel',
    placeholderMsgId: 'MarketplaceConfig.cranksetPlaceholder',
    type: 'text',
  },
  {
    key: 'chain',
    labelMsgId: 'MarketplaceConfig.chainLabel',
    placeholderMsgId: 'MarketplaceConfig.chainPlaceholder',
    type: 'text',
  },
  {
    key: 'front-hub',
    labelMsgId: 'MarketplaceConfig.frontHubLabel',
    placeholderMsgId: 'MarketplaceConfig.frontHubPlaceholder',
    type: 'text',
  },
  {
    key: 'rear-hub',
    labelMsgId: 'MarketplaceConfig.rearHubLabel',
    placeholderMsgId: 'MarketplaceConfig.rearHubPlaceholder',
    type: 'text',
  },
  {
    key: 'freewheel',
    labelMsgId: 'MarketplaceConfig.freeWheelLabel',
    placeholderMsgId: 'MarketplaceConfig.freeWheelPlaceholder',
    type: 'text',
  },
  {
    key: 'front-tyre',
    labelMsgId: 'MarketplaceConfig.frontTyreLabel',
    placeholderMsgId: 'MarketplaceConfig.frontTyrePlaceholder',
    type: 'text',
  },
  {
    key: 'rear-tyre',
    labelMsgId: 'MarketplaceConfig.rearTyreLabel',
    placeholderMsgId: 'MarketplaceConfig.rearTyrePlaceholder',
    type: 'text',
  },
  {
    key: 'tubeless',
    labelMsgId: 'MarketplaceConfig.tubelessLabel',
    type: 'checkbox',
    values: [
      {
        key: 'tubeless',
        label: 'Tubeless',
      },
    ],
  },
  {
    key: 'saddle',
    labelMsgId: 'MarketplaceConfig.saddleLabel',
    placeholderMsgId: 'MarketplaceConfig.saddleTyrePlaceholder',
    type: 'text',
  },
  {
    key: 'seatpost',
    labelMsgId: 'MarketplaceConfig.seatpostLabel',
    placeholderMsgId: 'MarketplaceConfig.seatpostPlaceholder',
    type: 'text',
  },
  {
    key: 'pedals',
    labelMsgId: 'MarketplaceConfig.pedalsLabel',
    placeholderMsgId: 'MarketplaceConfig.pedalsPlaceholder',
    type: 'text',
  },
  {
    key: 'battery',
    labelMsgId: 'MarketplaceConfig.batteryLabel',
    placeholderMsgId: 'MarketplaceConfig.batteryPlaceholder',
    type: 'text',
  },
  {
    key: 'charger',
    labelMsgId: 'MarketplaceConfig.chargerLabel',
    placeholderMsgId: 'MarketplaceConfig.chargerPlaceholder',
    type: 'text',
  },
  {
    key: 'remote',
    labelMsgId: 'MarketplaceConfig.remoteLabel',
    placeholderMsgId: 'MarketplaceConfig.remotePlaceholder',
    type: 'text',
  },
];

/* Default frames listing fields */
const defaultFramesListingFields = [
  brandField,
  frameSizeField,
  genderField,
  conditionField,
  {
    key: 'type',
    labelMsgId: 'MarketplaceConfig.typeLabel',
    placeholderMsgId: 'MarketplaceConfig.typePlaceholder',
    type: 'select',
    values: [
      { key: 'bmx', label: 'BMX' },
      { key: 'city', label: 'City' },
      { key: 'cyclocross', label: 'Cyclocross' },
      { key: 'electric', label: 'Electric' },
      { key: 'folding', label: 'Folding' },
      { key: 'gravel', label: 'Gravel' },
      { key: 'mountain', label: 'Mountain' },
      { key: 'road', label: 'Road' },
      { key: 'track', label: 'Track' },
      { key: 'time-trial', label: 'Time trial' },    
      { key: 'other', label: 'Other' },
    ],
    required: true,
  },
  {
    key: 'receipt',
    labelMsgId: 'MarketplaceConfig.receiptLabel',
    type: 'checkbox',
    values: [
      {
        key: 'receipt',
        label: 'Receipt',
      },
    ],
  },
  {
    key: 'claimed-weight',
    labelMsgId: 'MarketplaceConfig.claimedWeightLabel',
    placeholderMsgId: 'MarketplaceConfig.claimedWeightPlaceholder',
    type: 'text',
  },
  {
    key: 'frame',
    labelMsgId: 'MarketplaceConfig.frameLabel',
    placeholderMsgId: 'MarketplaceConfig.framePlaceholder',
    type: 'text',
  },

  {
    key: 'fork',
    labelMsgId: 'MarketplaceConfig.forkLabel',
    placeholderMsgId: 'MarketplaceConfig.forkPlaceholder',
    type: 'text',
  },
  {
    key: 'seatpost',
    labelMsgId: 'MarketplaceConfig.seatpostLabel',
    placeholderMsgId: 'MarketplaceConfig.seatpostPlaceholder',
    type: 'text',
  },
  {
    key: 'front-axle',
    labelMsgId: 'MarketplaceConfig.frontAxleLabel',
    placeholderMsgId: 'MarketplaceConfig.frontAxlePlaceholder',
    type: 'text',
  },
  {
    key: 'rear-axle',
    labelMsgId: 'MarketplaceConfig.rearAxleLabel',
    placeholderMsgId: 'MarketplaceConfig.rearAxlePlaceholder',
    type: 'text',
  },
];

/* Default rent bikes listing fields */
const defaultRentBikesListingFields = [
  brandField,
  skuField,
  {
    key: 'features',
    labelMsgId: 'MarketplaceConfig.featuresLabel',
    type: 'checkbox-group',
    required: true,
    values: [
      {
        key: 'repair-kit',
        label: 'Repair kit',
      },
      {
        key: 'front-light',
        label: 'Front light',
      },
      {
        key: 'rear-light',
        label: 'Rear light',
      },
      {
        key: 'pump',
        label: 'Pump',
      },
      {
        key: 'bottle',
        label: 'Bottle',
      },
      {
        key: 'bottle-holder',
        label: 'Bottle holder',
      },
      {
        key: 'shimano-pedals',
        label: 'Shimano pedals',
      },
      {
        key: 'look-pedals',
        label: 'Look pedals',
      },
      {
        key: 'other-pedals',
        label: 'Other pedals',
      },
      {
        key: 'computer-mount',
        label: 'Computer mount',
      },
      {
        key: 'bike-computer-gps',
        label: 'Bike Computer/GPS',
      },
      {
        key: 'saddle-bag',
        label: 'Saddle bag',
      },
      {
        key: 'bell',
        label: 'Bell',
      },
    ],
  },
];

/* Default rent accessories listing fields */
const defaultRentBikeBoxListingFields = [
  brandFieldBox,
  skuField,
  {
    key: 'length',
    labelMsgId: 'MarketplaceConfig.lengthLabel',
    placeholderMsgId: 'MarketplaceConfig.lengthPlaceholder',
    type: 'number',
  },
  {
    key: 'height',
    labelMsgId: 'MarketplaceConfig.heightLabel',
    placeholderMsgId: 'MarketplaceConfig.heightPlaceholder',
    type: 'number',
  },
  {
    key: 'width',
    labelMsgId: 'MarketplaceConfig.widthLabel',
    placeholderMsgId: 'MarketplaceConfig.widthPlaceholder',
    type: 'number',
  },
  {
    key: 'weight',
    labelMsgId: 'MarketplaceConfig.weightLabel',
    placeholderMsgId: 'MarketplaceConfig.weightPlaceholder',
    type: 'number',
  },
  {
    key: 'features',
    labelMsgId: 'MarketplaceConfig.featuresLabel',
    type: 'checkbox-group',
    required: true,
    values: [
      {
        key: 'foam',
        label: 'Foam',
      },
      {
        key: 'lock',
        label: 'Lock',
      },
    ],
  },
];

export {
  defaultClothesListingFields,
  defaultShoesListingFields,
  defaultAccessoriesListingFields,
  defaultBikesListingFields,
  defaultFramesListingFields,
  defaultComponentsListingFields,
  defaultRentBikesListingFields,
  defaultRentBikeBoxListingFields,
};
