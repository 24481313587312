import React from 'react';
import { object } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { LINE_ITEM_UNITS, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemCartUnitsMaybe = props => {
  const { cartPurchase, unitType } = props;
  const { otherListings } = cartPurchase || {};

  if (unitType !== LINE_ITEM_UNITS) {
    return null;
  }

  // Sum up the other listings length and original checkout listing
  const otherListingsLength = otherListings?.length + 1;

  return (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.cartQuantityUnit" />
      </span>
      <span className={css.itemValue}>
        <FormattedMessage
          id="OrderBreakdown.cartQuantity"
          values={{ quantity: otherListingsLength }}
        />
      </span>
    </div>
  );
};

LineItemCartUnitsMaybe.propTypes = {
  unitType: propTypes.bookingUnitType.isRequired,
  cartPurchase: object.isRequired,
};

export default LineItemCartUnitsMaybe;
