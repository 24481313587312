/**
 * This file is used to declare the listing wizard categories which are used in
 * WizardPreference, WizardCategory, WizardSubCategory and EditListingWizard pages/forms.
 *
 * We use the ./listing-fields/default-listing-fields file to import the default fields
 * for each category.
 */

import {
  defaultClothesListingFields,
  defaultShoesListingFields,
  defaultAccessoriesListingFields,
  defaultBikesListingFields,
  defaultFramesListingFields,
  defaultComponentsListingFields,
  defaultRentBikesListingFields,
  defaultRentBikeBoxListingFields,
} from './listing-fields/default-listing-fields';
import { brandField, colorField } from './listing-fields/single-fields';

export const editListingWizardCategories = {
  options: [
    {
      key: 'sell',
      label: 'Sell',
      labelMsgId: 'MarketplaceConfig.sellLabel',
      options: [
        {
          key: 'accessories',
          label: 'Accessories',
          labelMsgId: 'MarketplaceConfig.accessoriesLabel',
          options: [
            {
              key: 'other',
              label: 'Other',
              labelMsgId: 'MarketplaceConfig.otherLabel',
              fields: defaultAccessoriesListingFields,
            },
            {
              key: 'bottles',
              label: 'Bottles',
              labelMsgId: 'MarketplaceConfig.bottlesLabel',
              fields: defaultAccessoriesListingFields,
            },
            {
              key: 'bottle-cages',
              label: 'Bottle cages',
              labelMsgId: 'MarketplaceConfig.bottleCagesLabel',
              fields: defaultAccessoriesListingFields,
            },
            {
              key: 'cameras',
              label: 'Cameras',
              labelMsgId: 'MarketplaceConfig.camerasLabel',
              fields: defaultAccessoriesListingFields,
            },
            {
              key: 'cleaners-degreasers',
              label: 'Cleaners & Degreasers',
              labelMsgId: 'MarketplaceConfig.cleanersDegreasersLabel',
              fields: defaultAccessoriesListingFields,
            },
            {
              key: 'co2-inflators-cartridges',
              label: 'CO2 Inflators & Cartridges',
              labelMsgId: 'MarketplaceConfig.co2InflatorsCartridgesLabel',
              fields: defaultAccessoriesListingFields,
            },
            {
              key: 'gps-bike-computers',
              label: 'GPS & Bike computers',
              labelMsgId: 'MarketplaceConfig.gpsBikeComputersLabel',
              fields: defaultAccessoriesListingFields,
            },
            {
              key: 'heart-rate-monitors',
              label: 'Heart rate monitors',
              labelMsgId: 'MarketplaceConfig.heartRateLabel',
              fields: defaultAccessoriesListingFields,
            },
            {
              key: 'ligths',
              label: 'Lights',
              labelMsgId: 'MarketplaceConfig.lightsLabel',
              fields: defaultAccessoriesListingFields,
            },
            {
              key: 'locks',
              label: 'Locks',
              labelMsgId: 'MarketplaceConfig.locksLabel',
              fields: defaultAccessoriesListingFields,
            },
            {
              key: 'lubricants',
              label: 'Lubricants',
              labelMsgId: 'MarketplaceConfig.lubricantsLabel',
              fields: defaultAccessoriesListingFields,
            },
            {
              key: 'mudguards',
              label: 'Mudguards',
              labelMsgId: 'MarketplaceConfig.mudguardsLabel',
              fields: defaultAccessoriesListingFields,
            },
            {
              key: 'phone-accessories',
              label: 'Phone accessories',
              labelMsgId: 'MarketplaceConfig.phoneAccessoriesLabel',
              fields: defaultAccessoriesListingFields,
            },
            {
              key: 'pumps',
              label: 'Pumps',
              labelMsgId: 'MarketplaceConfig.pumpsLabel',
              fields: defaultAccessoriesListingFields,
            },
            {
              key: 'puncture-repair',
              label: 'Puncture repair',
              labelMsgId: 'MarketplaceConfig.punctureRepairLabel',
              fields: defaultAccessoriesListingFields,
            },
            {
              key: 'turbo-trainers',
              label: 'Turbo trainers',
              labelMsgId: 'MarketplaceConfig.turboTrainersLabel',
              fields: defaultAccessoriesListingFields,
            },
            {
              key: 'workstands',
              label: 'Workstands',
              labelMsgId: 'MarketplaceConfig.workstandsLabel',
              fields: defaultAccessoriesListingFields,
            },
          ],
        },
        {
          key: 'bikes',
          label: 'Bikes',
          labelMsgId: 'MarketplaceConfig.bikesLabel',
          disabled: true,
          fields: defaultBikesListingFields,
        },
        {
          key: 'clothes',
          label: 'Clothes',
          labelMsgId: 'MarketplaceConfig.clothesLabel',
          options: [
            {
              key: 'arm-leg-warmer',
              label: 'Arm & Leg warmers',
              labelMsgId: 'MarketplaceConfig.armLegWarmerLabel',
              fields: [
                ...defaultClothesListingFields,
                // Custom fields
                {
                  key: 'style',
                  labelMsgId: 'MarketplaceConfig.styleLabel',
                  placeholderMsgId: 'MarketplaceConfig.stylePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'arm', label: 'Arm' },
                    { key: 'leg', label: 'Leg' },
                  ],
                  required: true,
                },
                {
                  key: 'type',
                  labelMsgId: 'MarketplaceConfig.typeLabel',
                  placeholderMsgId: 'MarketplaceConfig.typePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'road', label: 'Road' },
                    { key: 'mountain', label: 'Mountain' },
                    { key: 'commuter', label: 'Commuter' },
                    { key: 'other', label: 'Other' },
                  ],
                  required: true,
                },
              ],
            },
            {
              key: 'base-layers',
              label: 'Base layers',
              labelMsgId: 'MarketplaceConfig.baseLayersLabel',
              fields: [
                ...defaultClothesListingFields,
                // Custom fields
                {
                  key: 'style',
                  labelMsgId: 'MarketplaceConfig.styleLabel',
                  placeholderMsgId: 'MarketplaceConfig.stylePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'short-sleeves', label: 'Short sleeves' },
                    { key: 'long-sleeves', label: 'Long sleeves' },
                    { key: 'sleeveless', label: 'Sleeveless' },
                  ],
                  required: true,
                },
                {
                  key: 'type',
                  labelMsgId: 'MarketplaceConfig.typeLabel',
                  placeholderMsgId: 'MarketplaceConfig.typePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'road', label: 'Road' },
                    { key: 'mountain', label: 'Mountain' },
                    { key: 'commuter', label: 'Commuter' },
                    { key: 'other', label: 'Other' },
                  ],
                  required: true,
                },
              ],
            },
            {
              key: 'body-armour',
              label: 'Body armour',
              labelMsgId: 'MarketplaceConfig.bodyArmourLabel',
              fields: [
                ...defaultClothesListingFields,
                // Custom fields
                {
                  key: 'style',
                  labelMsgId: 'MarketplaceConfig.styleLabel',
                  placeholderMsgId: 'MarketplaceConfig.stylePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'short-sleeves', label: 'Short sleeves' },
                    { key: 'long-sleeves', label: 'Long sleeves' },
                    { key: 'sleeveless', label: 'Sleeveless' },
                  ],
                  required: true,
                },
                {
                  key: 'type',
                  labelMsgId: 'MarketplaceConfig.typeLabel',
                  placeholderMsgId: 'MarketplaceConfig.typePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'road', label: 'Road' },
                    { key: 'mountain', label: 'Mountain' },
                    { key: 'commuter', label: 'Commuter' },
                    { key: 'other', label: 'Other' },
                  ],
                  required: true,
                },
              ],
            },
            {
              key: 'bib-shorts',
              label: 'Bib & Shorts',
              labelMsgId: 'MarketplaceConfig.bibShortsLabel',
              fields: [
                ...defaultClothesListingFields,
                // Custom fields
                {
                  key: 'style',
                  labelMsgId: 'MarketplaceConfig.styleLabel',
                  placeholderMsgId: 'MarketplaceConfig.stylePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'bib-shorts', label: 'Bib shorts' },
                    { key: 'short', label: 'Short' },
                  ],
                  required: true,
                },
              ],
            },
            {
              key: 'gilets-vests',
              label: 'Gilets & Vests',
              labelMsgId: 'MarketplaceConfig.giletsVestsLabel',
              fields: defaultClothesListingFields,
            },
            {
              key: 'gloves',
              label: 'Globes',
              labelMsgId: 'MarketplaceConfig.glovesLabel',
              fields: defaultClothesListingFields,
            },
            {
              key: 'headwear',
              label: 'Headwear',
              labelMsgId: 'MarketplaceConfig.headwearLabel',
              fields: [
                ...defaultClothesListingFields,
                // Custom fields
                {
                  key: 'style',
                  labelMsgId: 'MarketplaceConfig.styleLabel',
                  placeholderMsgId: 'MarketplaceConfig.stylePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'beanies', label: 'Beanies' },
                    { key: 'caps', label: 'Caps' },
                    { key: 'headband', label: 'Headband' },
                    { key: 'warmers', label: 'Warmers' },
                    { key: 'masks', label: 'Masks' },
                  ],
                  required: true,
                },
              ],
            },
            {
              key: 'helmets',
              label: 'Helmets',
              labelMsgId: 'MarketplaceConfig.helmetsLabel',
              fields: [
                ...defaultClothesListingFields,
                // Custom fields
                {
                  key: 'type',
                  labelMsgId: 'MarketplaceConfig.typeLabel',
                  placeholderMsgId: 'MarketplaceConfig.typePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'road', label: 'Road' },
                    { key: 'mountain', label: 'Mountain' },
                    { key: 'commuter', label: 'Commuter' },
                    { key: 'other', label: 'Other' },
                  ],
                },
              ],
            },
            { key: 'insoles', label: 'Insoles', fields: defaultShoesListingFields },
            {
              key: 'jackets',
              label: 'Jackets',
              labelMsgId: 'MarketplaceConfig.jacketsLabel',
              fields: [
                ...defaultClothesListingFields,
                // Custom fields
                {
                  key: 'type',
                  labelMsgId: 'MarketplaceConfig.typeLabel',
                  placeholderMsgId: 'MarketplaceConfig.typePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'road', label: 'Road' },
                    { key: 'mountain', label: 'Mountain' },
                    { key: 'commuter', label: 'Commuter' },
                    { key: 'other', label: 'Other' },
                  ],
                },
              ],
            },
            {
              key: 'jerseys',
              label: 'Jerseys',
              labelMsgId: 'MarketplaceConfig.jerseysLabel',
              fields: [
                ...defaultClothesListingFields,
                // Custom fields
                {
                  key: 'style',
                  labelMsgId: 'MarketplaceConfig.styleLabel',
                  placeholderMsgId: 'MarketplaceConfig.stylePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'short-sleeves', label: 'Short sleeves' },
                    { key: 'long-sleeves', label: 'Long sleeves' },
                    { key: 'sleeveless', label: 'Sleeveless' },
                  ],
                  required: true,
                },
                {
                  key: 'type',
                  labelMsgId: 'MarketplaceConfig.typeLabel',
                  placeholderMsgId: 'MarketplaceConfig.typePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'road', label: 'Road' },
                    { key: 'mountain', label: 'Mountain' },
                    { key: 'commuter', label: 'Commuter' },
                    { key: 'other', label: 'Other' },
                  ],
                  required: true,
                },
              ],
            },
            {
              key: 'overshoes',
              label: 'Overshoes',
              labelMsgId: 'MarketplaceConfig.overshoesLabel',
              fields: [
                ...defaultShoesListingFields,
                // Custom fields
                {
                  key: 'type',
                  labelMsgId: 'MarketplaceConfig.typeLabel',
                  placeholderMsgId: 'MarketplaceConfig.typePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'road', label: 'Road' },
                    { key: 'mountain', label: 'Mountain' },
                    { key: 'commuter', label: 'Commuter' },
                    { key: 'other', label: 'Other' },
                  ],
                  required: true,
                },
              ],
            },
            {
              key: 'shoes',
              label: 'Shoes',
              labelMsgId: 'MarketplaceConfig.shoesLabel',
              fields: [
                ...defaultShoesListingFields,
                // Custom fields
                {
                  key: 'type',
                  labelMsgId: 'MarketplaceConfig.typeLabel',
                  placeholderMsgId: 'MarketplaceConfig.typePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'road', label: 'Road' },
                    { key: 'mountain', label: 'Mountain' },
                    { key: 'commuter', label: 'Commuter' },
                    { key: 'other', label: 'Other' },
                  ],
                  required: true,
                },
              ],
            },
            {
              key: 'skin-suits',
              label: 'Skin suits',
              labelMsgId: 'MarketplaceConfig.skinSuitsLabel',
              fields: [
                ...defaultClothesListingFields,
                // Custom fields
                {
                  key: 'style',
                  labelMsgId: 'MarketplaceConfig.styleLabel',
                  placeholderMsgId: 'MarketplaceConfig.stylePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'short-sleeves', label: 'Short sleeves' },
                    { key: 'long-sleeves', label: 'Long sleeves' },
                    { key: 'sleeveless', label: 'Sleeveless' },
                  ],
                  required: true,
                },
              ],
            },
            { key: 'socks', label: 'Socks', fields: defaultShoesListingFields },
            {
              key: 'sunglasses',
              label: 'Sunglasses',
              labelMsgId: 'MarketplaceConfig.sunglassesLabel',
              fields: [
                ...defaultClothesListingFields,
                // Custom fields
                {
                  key: 'type',
                  labelMsgId: 'MarketplaceConfig.typeLabel',
                  placeholderMsgId: 'MarketplaceConfig.typePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'road', label: 'Road' },
                    { key: 'mountain', label: 'Mountain' },
                    { key: 'commuter', label: 'Commuter' },
                    { key: 'other', label: 'Other' },
                  ],
                  required: true,
                },
              ],
            },
            {
              key: 'tigths-trousers',
              label: 'Tigths & Trousers',
              labelMsgId: 'MarketplaceConfig.tightsTrousersLabel',
              fields: [
                ...defaultClothesListingFields,
                // Custom fields
                {
                  key: 'style',
                  labelMsgId: 'MarketplaceConfig.styleLabel',
                  placeholderMsgId: 'MarketplaceConfig.stylePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'bib-tights', label: 'Bib tights' },
                    { key: 'tights', label: 'Tights' },
                  ],
                  required: true,
                },
                {
                  key: 'type',
                  labelMsgId: 'MarketplaceConfig.typeLabel',
                  placeholderMsgId: 'MarketplaceConfig.typePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'road', label: 'Road' },
                    { key: 'mountain', label: 'Mountain' },
                    { key: 'commuter', label: 'Commuter' },
                    { key: 'other', label: 'Other' },
                  ],
                  required: true,
                },
              ],
            },
          ],
        },
        {
          key: 'components',
          label: 'Components',
          labelMsgId: 'MarketplaceConfig.componentsLabel',
          options: [
            {
              key: 'other',
              label: 'Other',
              labelMsgId: 'MarketplaceConfig.otherLabel',
              fields: defaultComponentsListingFields,
            },
            {
              key: 'battery',
              label: 'Battery',
              labelMsgId: 'MarketplaceConfig.batteryLabel',
              fields: defaultComponentsListingFields,
            },
            {
              key: 'bottom-bracket',
              label: 'Bottom Bracket',
              labelMsgId: 'MarketplaceConfig.bottomBracketLabel',
              fields: defaultComponentsListingFields,
            },
            {
              key: 'brakes-caliper',
              label: 'Brake Caliper',
              labelMsgId: 'MarketplaceConfig.brakesCaliperLabel',
              fields: [
                ...defaultComponentsListingFields,
                // Custom fields
                {
                  key: 'break-type',
                  labelMsgId: 'MarketplaceConfig.breakTypeLabel',
                  placeholderMsgId: 'MarketplaceConfig.breakTypePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'rim', label: 'Rim' },
                    { key: 'disc', label: 'Disc' },
                  ],
                  required: true,
                },
                {
                  key: 'style',
                  labelMsgId: 'MarketplaceConfig.styleLabel',
                  placeholderMsgId: 'MarketplaceConfig.stylePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'front', label: 'Front' },
                    { key: 'rear', label: 'Rear' },
                    { key: 'pair', label: 'Pair' },
                  ],
                  required: true,
                },
              ],
            },
            {
              key: 'brake-lever',
              label: 'Brake Lever',
              labelMsgId: 'MarketplaceConfig.brakeLeverLabel',
              fields: [
                ...defaultComponentsListingFields,
                // Custom fields
                {
                  key: 'break-type',
                  labelMsgId: 'MarketplaceConfig.breakTypeLabel',
                  placeholderMsgId: 'MarketplaceConfig.breakTypePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'hydraulic', label: 'Hydraulic' },
                    { key: 'mechanic', label: 'Mechanic' },
                  ],
                  required: true,
                },
                {
                  key: 'style',
                  labelMsgId: 'MarketplaceConfig.styleLabel',
                  placeholderMsgId: 'MarketplaceConfig.stylePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'left', label: 'Left' },
                    { key: 'right', label: 'Right' },
                    { key: 'pair', label: 'Pair' },
                  ],
                  required: true,
                },
              ],
            },
            {
              key: 'brake-rotor',
              label: 'Brake Rotor',
              labelMsgId: 'MarketplaceConfig.rotorLabel',
              fields: defaultComponentsListingFields,
            },
            {
              key: 'cable',
              label: 'Cable',
              labelMsgId: 'MarketplaceConfig.cableLabel',
              fields: defaultComponentsListingFields,
            },
            {
              key: 'cassette',
              label: 'Cassette',
              labelMsgId: 'MarketplaceConfig.cassetteLabel',
              fields: defaultComponentsListingFields,
            },
            {
              key: 'chainrings',
              label: 'Chainrings',
              labelMsgId: 'MarketplaceConfig.chainringsLabel',
              fields: defaultComponentsListingFields,
            },
            {
              key: 'chain',
              label: 'Chain',
              labelMsgId: 'MarketplaceConfig.chainLabel',
              fields: defaultComponentsListingFields,
            },
            {
              key: 'charger',
              label: 'Charger',
              labelMsgId: 'MarketplaceConfig.chargerLabel',
              fields: defaultComponentsListingFields,
            },
            {
              key: 'crankset',
              label: 'Crankset',
              labelMsgId: 'MarketplaceConfig.cranksetLabel',
              fields: defaultComponentsListingFields,
            },
            {
              key: 'derailleur',
              label: 'Derailleur',
              labelMsgId: 'MarketplaceConfig.derailleurLabel',
              fields: [
                ...defaultComponentsListingFields,
                // Custom fields
                {
                  key: 'style',
                  labelMsgId: 'MarketplaceConfig.styleLabel',
                  placeholderMsgId: 'MarketplaceConfig.stylePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'front', label: 'Front' },
                    { key: 'rear', label: 'Rear' },
                  ],
                  required: true,
                },
              ],
            },
            {
              key: 'fork',
              label: 'Fork',
              labelMsgId: 'MarketplaceConfig.forkLabel',
              fields: defaultComponentsListingFields,
            },
            {
              key: 'freehub',
              label: 'Freehub',
              labelMsgId: 'MarketplaceConfig.freehubLabel',
              fields: defaultComponentsListingFields,
            },
            {
              key: 'groupset',
              label: 'groupset',
              labelMsgId: 'MarketplaceConfig.groupsetLabel',
              fields: defaultComponentsListingFields,
            },
            {
              key: 'handlebar',
              label: 'Handlebar',
              labelMsgId: 'MarketplaceConfig.handlebarLabel',
              fields: defaultComponentsListingFields,
            },
            {
              key: 'headset',
              label: 'Headset',
              labelMsgId: 'MarketplaceConfig.headsetLabel',
              fields: defaultComponentsListingFields,
            },
            {
              key: 'hub',
              label: 'Hub',
              labelMsgId: 'MarketplaceConfig.hubLabel',
              fields: defaultComponentsListingFields,
            },
            {
              key: 'inner-tubes',
              label: 'Inner Tubes',
              labelMsgId: 'MarketplaceConfig.innerTubesLabel',
              fields: defaultComponentsListingFields,
            },
            {
              key: 'pedals',
              label: 'Pedals',
              labelMsgId: 'MarketplaceConfig.pedalsLabel',
              fields: defaultComponentsListingFields,
            },
            {
              key: 'power-meter',
              label: 'Power Meter',
              labelMsgId: 'MarketplaceConfig.powerMeterLabel',
              fields: defaultComponentsListingFields,
            },
            {
              key: 'remote',
              label: 'Remote',
              labelMsgId: 'MarketplaceConfig.remoteLabel',
              fields: defaultComponentsListingFields,
            },
            {
              key: 'saddle',
              label: 'Saddle',
              labelMsgId: 'MarketplaceConfig.saddleLabel',
              fields: defaultComponentsListingFields,
            },
            {
              key: 'seatpost',
              label: 'Seatpost',
              labelMsgId: 'MarketplaceConfig.seatpostLabel',
              fields: defaultComponentsListingFields,
            },            
            {
              key: 'shift-levers',
              label: 'Shifters',
              labelMsgId: 'MarketplaceConfig.shiftLeversLabel',
              fields: [
                ...defaultComponentsListingFields,
                // Custom fields
                {
                  key: 'lever-type',
                  labelMsgId: 'MarketplaceConfig.leverTypeLabel',
                  placeholderMsgId: 'MarketplaceConfig.leverTypePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'electronic', label: 'Electronic' },
                    { key: 'mechanic', label: 'Mechanic' },
                  ],
                  required: true,
                },
                {
                  key: 'style',
                  labelMsgId: 'MarketplaceConfig.styleLabel',
                  placeholderMsgId: 'MarketplaceConfig.stylePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'left', label: 'Left' },
                    { key: 'right', label: 'Right' },
                  ],
                  required: true,
                },
              ],
            },  
            {
              key: 'skewers-& axles',
              label: 'Skewers & Axles',
              labelMsgId: 'MarketplaceConfig.skewersAxlesLabel',
              fields: [
                ...defaultComponentsListingFields,
                // Custom fields
                {
                  key: 'style',
                  labelMsgId: 'MarketplaceConfig.styleLabel',
                  placeholderMsgId: 'MarketplaceConfig.stylePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'front', label: 'Front' },
                    { key: 'rear', label: 'Rear' },
                  ],
                  required: true,
                },
              ],
            },  
            {
              key: 'stem',
              label: 'Stem',
              labelMsgId: 'MarketplaceConfig.stemLabel',
              fields: defaultComponentsListingFields,
            },  
            {
              key: 'tyre',
              label: 'Tyre',
              labelMsgId: 'MarketplaceConfig.tyreLabel',
              fields: defaultComponentsListingFields,
            },      
            {
              key: 'wheel',
              label: 'Wheel',
              labelMsgId: 'MarketplaceConfig.wheelLabel',
              fields: [
                ...defaultComponentsListingFields,
                // Custom fields
                {
                  key: 'style',
                  labelMsgId: 'MarketplaceConfig.styleLabel',
                  placeholderMsgId: 'MarketplaceConfig.stylePlaceholder',
                  type: 'select',
                  values: [
                    { key: 'front', label: 'Front' },
                    { key: 'rear', label: 'Rear' },
                    { key: 'pair', label: 'Pair' },
                  ],
                  required: true,
                },
                {
                  key: 'front-axle',
                  labelMsgId: 'MarketplaceConfig.frontAxleLabel',
                  placeholderMsgId: 'MarketplaceConfig.frontAxlePlaceholder',
                  type: 'text',
                },
                {
                  key: 'rear-axle',
                  labelMsgId: 'MarketplaceConfig.rearAxleLabel',
                  placeholderMsgId: 'MarketplaceConfig.rearAxlePlaceholder',
                  type: 'text',
                },
                {
                  key: 'front-hub',
                  labelMsgId: 'MarketplaceConfig.frontHubLabel',
                  placeholderMsgId: 'MarketplaceConfig.frontHubPlaceholder',
                  type: 'text',
                },
                {
                  key: 'rear-hub',
                  labelMsgId: 'MarketplaceConfig.rearHubLabel',
                  placeholderMsgId: 'MarketplaceConfig.rearHubPlaceholder',
                  type: 'text',
                },
                {
                  key: 'freehub',
                  labelMsgId: 'MarketplaceConfig.freehubLabel',
                  placeholderMsgId: 'MarketplaceConfig.freehubPlaceholder',
                  type: 'text',
                },
                {
                  key: 'front-tyre',
                  labelMsgId: 'MarketplaceConfig.frontTyreLabel',
                  placeholderMsgId: 'MarketplaceConfig.frontTyrePlaceholder',
                  type: 'text',
                },
                {
                  key: 'rear-tyre',
                  labelMsgId: 'MarketplaceConfig.rearTyreLabel',
                  placeholderMsgId: 'MarketplaceConfig.rearTyrePlaceholder',
                  type: 'text',
                },
                {
                  key: 'tubeless',
                  labelMsgId: 'MarketplaceConfig.tubelessLabel',
                  type: 'checkbox',
                  values: [
                    {
                      key: 'tubeless',
                      label: 'Tubeless',
                    },
                  ],
                },
                colorField,
              ],
            },
          ],
        },
        {
          key: 'frames',
          label: 'Frames',
          labelMsgId: 'MarketplaceConfig.framesLabel',
          fields: defaultFramesListingFields,
        },
      ],
    },
    {
      key: 'rent',
      label: 'Rent',
      labelMsgId: 'MarketplaceConfig.rentLabel',
      options: [
        {
          key: 'bikes',
          label: 'Bikes',
          labelMsgId: 'MarketplaceConfig.bikesLabel',
          disabled: true,
          options: [
            {
              key: 'road-bike',
              label: 'Road bike',
              labelMsgId: 'MarketplaceConfig.roadBikeLabel',
              fields: defaultRentBikesListingFields,
            },
            {
              key: 'gravel-bike',
              label: 'Gravel bike',
              labelMsgId: 'MarketplaceConfig.gravelBikeLabel',
              fields: defaultRentBikesListingFields,
            },
            {
              key: 'cyclocross-bike',
              label: 'Cyclocross bike',
              labelMsgId: 'MarketplaceConfig.cyclocrossBikeLabel',
              fields: defaultRentBikesListingFields,
            },
            {
              key: 'mountain-bike',
              label: 'Mountain bike',
              labelMsgId: 'MarketplaceConfig.mountainBikeLabel',
              fields: defaultRentBikesListingFields,
            },
            {
              key: 'electric-bike',
              label: 'Electric bike',
              labelMsgId: 'MarketplaceConfig.electricBikeLabel',
              fields: defaultRentBikesListingFields,
            },
            {
              key: 'city-bike',
              label: 'City bike',
              labelMsgId: 'MarketplaceConfig.cityBikeLabel',
              fields: defaultRentBikesListingFields,
            },
            {
              key: 'MarketplaceConfig.gpsBikeComputersLabeler-bike',
              label: 'Commuter bike',
              labelMsgId: 'MarketplaceConfig.commuterBikeLabel',
              fields: defaultRentBikesListingFields,
            },
            {
              key: 'time-trial-bike',
              label: 'Time trial bike',
              labelMsgId: 'MarketplaceConfig.timeTrialBikeLabel',
              fields: defaultRentBikesListingFields,
            },
            {
              key: 'single-speed-bike',
              label: 'Single speed bike',
              labelMsgId: 'MarketplaceConfig.singleSpeedBikeLabel',
              fields: [
                // Custom fields
                brandField,
              ],
            },
            {
              key: 'track-bike',
              label: 'Track bike',
              labelMsgId: 'MarketplaceConfig.trackBikeLabel',
              fields: [
                // Custom fields
                brandField,
              ],
            },
            {
              key: 'folding-bike',
              label: 'Folding bike',
              labelMsgId: 'MarketplaceConfig.foldingBikeLabel',
              fields: [
                // Custom fields
                brandField,
              ],
            },
            {
              key: 'kids-bike',
              label: 'Kids bike',
              labelMsgId: 'MarketplaceConfig.kidsBikeLabel',
              fields: [
                // Custom fields
                brandField,
              ],
            },
            {
              key: 'bmx',
              label: 'BMX',
              labelMsgId: 'MarketplaceConfig.bmxBikeLabel',
              fields: [
                // Custom fields
                brandField,
              ],
            },
            {
              key: 'Other',
              label: 'Other',
              labelMsgId: 'MarketplaceConfig.otherBikeLabel',
              fields: defaultRentBikesListingFields,
            },
          ],
        },
        {
          key: 'bike-box',
          label: 'Bike box',
          labelMsgId: 'MarketplaceConfig.bikeBoxLabel',
          fields: defaultRentBikeBoxListingFields,
        },
      ],
    },
  ],
};
