import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getCurrentLanguage } from '../../util/data';
import { IconFrance, IconEngland } from '../../components';
import { customStyles } from './SelectStyles';

import css from './LanguagePicker.module.css';

// We need to require instead import because it breaks SSR (Server-Side Rendering)
const { default: Select, components } = require('react-select'); // eslint-disable-line global-require

const CLASSNAME_PREFIX = 'language';
const languages = [
  { value: 'en', label: 'English', icon: <IconEngland /> },
  { value: 'fr', label: 'French', icon: <IconFrance /> },
];

// Render the IconOption separately as we are
// adding icon to each option
const IconOption = props => {
  const { Option } = components;
  return (
    <Option {...props}>
      {props.data.icon}
      {props.data.label}
    </Option>
  );
};

// Render the ValueOption separately as we are
// adding icon to selected option
const ValueOption = props => {
  const { SingleValue } = components;
  return (
    <SingleValue {...props}>
      {props.data.icon}
      {props.data.label}
    </SingleValue>
  );
};

const LanguagePicker = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (typeof window === 'undefined') {
    return null;
  }

  // Get the current language
  const currentLanguage = getCurrentLanguage();

  // Set the default language
  const defaultLanguage = currentLanguage
    ? languages.find(language => language.value === currentLanguage)
    : languages[0];

  // Function that will set the selected language
  // and reload the page
  const setMarketplaceLanguage = ({ value }) => {
    if (window) {
      localStorage.setItem('locale', value);
      window.location.reload();
    }
  };
  return (
    <div className={classes}>
      <Select
        className={css.picker}
        classNamePrefix={CLASSNAME_PREFIX}
        options={languages}
        defaultValue={defaultLanguage}
        components={{ Option: IconOption, SingleValue: ValueOption }}
        styles={customStyles}
        isSearchable={false}
        onChange={setMarketplaceLanguage}
      />
    </div>
  );
};

LanguagePicker.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

LanguagePicker.propTypes = {
  rootClassName: string,
  className: string,
};

export default LanguagePicker;
