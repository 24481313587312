import React from 'react';
import { string } from 'prop-types';
import { compose } from 'redux';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { Form, FieldTextInput, IconSearch, PrimaryButton } from '../../components';

import css from './TopbarPreferenceMobileSearchForm.module.css';

const TopbarPreferenceMobileSearchFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const { rootClassName, className, handleSubmit, formId, intl, values } = fieldRenderProps;

      const keywordPlaceholder = intl.formatMessage({
        id: 'TopbarPreferenceMobileSearchForm.keywordPlaceholder',
      });

      const classes = classNames(rootClassName || css.root, className);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.mobileInputRoot}>
            <div className={css.mobileIcon}>
              <IconSearch />
            </div>
            <FieldTextInput
              inputRootClass={css.mobileInput}
              type="text"
              name="keyword"
              id={formId ? `${formId}.keyword` : 'keyword'}
              placeholder={keywordPlaceholder}
            />
          </div>
          <div className={css.submitButtonWrapper}>
            <PrimaryButton className={css.submitButton} type="submit" disabled={!values.keyword}>
              <FormattedMessage id="TopbarPreferenceMobileSearchForm.submitButton" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

TopbarPreferenceMobileSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

TopbarPreferenceMobileSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarPreferenceMobileSearchForm = compose(injectIntl)(
  TopbarPreferenceMobileSearchFormComponent
);

TopbarPreferenceMobileSearchForm.displayName = 'TopbarPreferenceMobileSearchForm';

export default TopbarPreferenceMobileSearchForm;
