/**
 * NOTE: This component is only used in LandingPage
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { FormattedMessage } from '../../util/reactIntl';
import { Carousel, MobileCarousel, ReviewCard } from '../../components';

import css from './SectionReviews.module.css';

const SectionReviews = props => {
  const {
    rootClassName,
    className,
    reviews,
    reviewsLoaded,
    fetchReviewsInProgress,
    fetchReviewsError,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  // Declare when the carousel should be shown
  const showCarousel = fetchReviewsInProgress || !fetchReviewsError;

  return reviewsLoaded ? (
    <div className={classes}>
      <div className={css.titleWrapper}>
        <h1 className={css.title}>
          <FormattedMessage id="SectionReviews.title" />
        </h1>
        <p className={css.subTitle}>
          <FormattedMessage id="SectionReviews.subTitle" />
        </p>
      </div>
      <div className={css.desktopSlider}>
        <Carousel show={showCarousel} cardsOnMobile={1} fullHeightChevron>
          {reviews.map(review => {
            const reviewId = review?.id?.uuid;
            return review ? <ReviewCard key={reviewId} review={review} /> : null;
          })}
        </Carousel>
      </div>
      <div className={css.mobileSlider}>
        <MobileCarousel>
          {reviews.map(review => {
            const reviewId = review?.id?.uuid;
            return review ? <ReviewCard key={reviewId} review={review} /> : null;
          })}
        </MobileCarousel>
      </div>
    </div>
  ) : null;
};

SectionReviews.defaultProps = { rootClassName: null, className: null, reviews: null };

const { string, array, bool } = PropTypes;

SectionReviews.propTypes = {
  rootClassName: string,
  className: string,

  // reviews
  reviews: array.isRequired,
  reviewsLoaded: bool.isRequired,
  fetchReviewsInProgress: bool.isRequired,
  fetchReviewsError: propTypes.error,
};

export default SectionReviews;
